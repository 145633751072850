import React from 'react'
import styles from './RoomsPageJoinPasswordPopup.module.css'
import Popup from '../UI/Popup/Popup'
import { useTranslation } from 'react-i18next'
import TextButton from '../UI/Buttons/TextButton'
import RoomPasswordInputField from './Fields/RoomFields/RoomPasswordInputField'

export interface IRoomsPageJoinPasswordPopup {
    password: string
    setPassword: React.Dispatch<React.SetStateAction<string>>
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    onConfirm: () => any
}

const RoomsPageJoinPasswordPopup: React.FunctionComponent<IRoomsPageJoinPasswordPopup> = (props) => {
    const { t } = useTranslation()

    const confirmButtonOnClickHandler = () => {
        props.onConfirm()
    }

    const cancelButtonOnClickHandler = () => {
        props.setActive(false)
    }

    return (
        <Popup title={t('Titles.password')} active={props.active} setActive={props.setActive}>
            <div className={styles.container}>
                <div className={styles.form}>
                    <RoomPasswordInputField focus={true} password={props.password} setPassword={props.setPassword} showLabel={false} />
                </div>
                <div className={styles.buttons}>
                    <TextButton id='password-popup-confirm' text={t('Buttons.confirm')} onClick={confirmButtonOnClickHandler} />
                    <TextButton id='password-popup-cancel' text={t('Buttons.cancel')} onClick={cancelButtonOnClickHandler} />
                </div>
            </div>
        </Popup>
    )
}

export default RoomsPageJoinPasswordPopup