import React from 'react'
import styles from './DatePickerField.module.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export interface IDatePickerField {
    label?: string
    id: string
    date: Date | null
    setDate: React.Dispatch<React.SetStateAction<Date | null>>
    inline?: boolean
    inline28?: boolean
}

const DatePickerField: React.FunctionComponent<IDatePickerField> = (props) => {
    return (
        <div className={`${styles.field} ${props.inline === true ? styles.inline : ''} ${props.inline28 === true ? styles.inline28 : ''}`}>
            {
                props.label !== undefined &&
                <label className={styles.label} htmlFor={props.id}>{props.label}</label>
            }
            <div className={styles.container}>
                <DatePicker
                    className={styles.input}
                    selected={props.date}
                    onChange={(date) => props.setDate(date)}
                    dateFormat={'dd/MM/yyyy'}
                />
            </div>
        </div>
    )
}

export default DatePickerField