import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './Toolbar.module.css'
import IconButton from '../../UI/Buttons/IconButton'
import UserContext from '../../../contexts/User/UserContext'
import SharingPopup from '../../UI/Popup/SharingPopup'
import LeaveRoomPopup from '../../UI/Popup/LeaveRoomPopup'

export interface IToolbar { }

const Toolbar: React.FunctionComponent<IToolbar> = () => {
    const userContext = useContext(UserContext)
    const { user, room, volume } = userContext.UserState
    const userDispatch = userContext.UserDispatch

    const [sharingPopup, setSharingPopup] = useState<boolean>(false)
    const [leaveRoomPopup, setLeaveRoomPopup] = useState<boolean>(false)

    const [showVolumeSetting, setShowVolumeSetting] = useState<boolean>(false)
    const volumeSettingRef = useRef<HTMLDivElement>(null)
    const [volumeType, setVolumeType] = useState<'volume-low' | 'volume-high' | 'volume-mute'>('volume-high')

    // const [isCreator, setIsCreator] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        if (!loaded && room.users !== undefined && user.uid !== undefined) {
            const adminId = Object.keys(room.users)[0]
            const uid = user.uid
            if (uid === adminId) {
                setSharingPopup(true)
            }

            setLoaded(true)
        }
    }, [room.users, user.uid, loaded])

    // close volume setting when click outside
    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (volumeSettingRef.current && !volumeSettingRef.current.contains(e.target as HTMLElement)) {
                setShowVolumeSetting(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [volumeSettingRef])

    useEffect(() => {
        if (volume > 0.5) {
            setVolumeType('volume-high')
        } else if (volume > 0 && volume <= 0.5) {
            setVolumeType('volume-low')
        } else {
            setVolumeType('volume-mute')
        }
    }, [volume])


    const volumeButtonOnClickHandler = () => {
        setShowVolumeSetting(() => !showVolumeSetting)
    }

    const volumeUnitOnClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        const value = e.currentTarget.getAttribute('data-value')
        if (value) {
            userDispatch({ type: 'update_volume', payload: parseFloat(value) })
        }
    }

    const shareButtonOnClickHandler = () => {
        setSharingPopup(true)
    }

    const leaveRoomButtonOnClickHandler = () => {
        setLeaveRoomPopup(true)
    }

    return (
        <div className={styles.tools}>
            <div className={styles.left}>
                <div className={styles.roomName}>
                    <h4 className={styles.name}>{room.roomName}</h4>
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.volumeSettingTool} ref={volumeSettingRef}>
                    <IconButton classes={styles.icon} type={volumeType} onClick={volumeButtonOnClickHandler} />
                    {
                        showVolumeSetting &&
                        <div className={styles.volumeSetting}>
                            <div className={styles.volumeBar}>
                                <div className={`${styles.volumeUnit} ${volume > 0.8 ? styles.active : ''}`} onClick={volumeUnitOnClickHandler} data-value='1'></div>
                                <div className={`${styles.volumeUnit} ${volume > 0.6 ? styles.active : ''}`} onClick={volumeUnitOnClickHandler} data-value='0.8'></div>
                                <div className={`${styles.volumeUnit} ${volume > 0.4 ? styles.active : ''}`} onClick={volumeUnitOnClickHandler} data-value='0.6'></div>
                                <div className={`${styles.volumeUnit} ${volume > 0.2 ? styles.active : ''}`} onClick={volumeUnitOnClickHandler} data-value='0.4'></div>
                                <div className={`${styles.volumeUnit} ${volume > 0 ? styles.active : ''}`} onClick={volumeUnitOnClickHandler} data-value='0.2'></div>
                            </div>
                        </div>
                    }
                </div>
                <IconButton classes={styles.icon} type='share' onClick={shareButtonOnClickHandler} />
                <IconButton classes={styles.icon} type='close' onClick={leaveRoomButtonOnClickHandler} />
            </div>
            <SharingPopup active={sharingPopup} setActive={setSharingPopup} />
            <LeaveRoomPopup active={leaveRoomPopup} setActive={setLeaveRoomPopup} />
        </div>
    )
}

export default Toolbar