import React from 'react'
import styles from './LogoutAllButton.module.css'
import TextButton from '../../../UI/Buttons/TextButton'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

export interface ILogoutAllButton {
    setLoggingOut: React.Dispatch<React.SetStateAction<boolean>>
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
}

const LogoutAllButton: React.FunctionComponent<ILogoutAllButton> = (props) => {
    const { t } = useTranslation()

    const logoutButtonOnClick = async () => {
        props.setLoggingOut(true)

        const token = Cookies.get('login_token')

        if (token) {
            try {
                const res = await axios({
                    method: 'post',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/users/logout-all`
                })

                Cookies.remove('login_token')
                props.setIsLoggedIn(false)
            } catch (e) {
                console.log(e)
            }
        }

        props.setLoggingOut(false)
    }

    return (
        <TextButton classes={styles.button} text={t('Buttons.logout_all')} onClick={logoutButtonOnClick} />
    )
}

export default LogoutAllButton