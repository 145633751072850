import React from 'react'
import styles from './Loading.module.css'

export interface ILoading {
    loading: boolean
    size?: 'sm' | 'md' | 'lg' | 'full'
    classes?: string
}

const Loading: React.FunctionComponent<ILoading> = (props) => {
    if (props.loading) {
        let sizeClass = ''
        switch (props.size) {
            case 'full':
                sizeClass = styles.full
                break

            default:
                break
        }

        return (
            <div className={`${styles.loading} ${props.classes ?? ''} ${sizeClass}`}>
                <div className={styles.inner}>
                    <i className={styles.loadingIcon}></i>
                </div>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}

export default Loading