import React, { useContext, useEffect, useState } from 'react'
import styles from './ThemeForm.module.css'
import ThemeNameInputField from './Fields/ThemeFields/ThemeNameInputField'
import ThemeTypeSelectField from './Fields/ThemeFields/ThemeTypeSelectField'
import { TThemeType } from '../../utils/library'
import { TGameType, TQuizQuestion, TThemeVisibility, defaultThemeTypes, defaultThemeVisibility, themeNameMaxLength, themeNameMinLength } from '../../utils/library'
import { useTranslation } from 'react-i18next'
import LanguageSelectField from './Fields/LanguageSelectField'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import TextButton from '../UI/Buttons/TextButton'
import ErrorPopup from '../UI/Popup/ErrorPopup'
import QuestionList from './Fields/ThemeFields/QuestionList'
import SuccessPopup from '../UI/Popup/SuccessPopup'
import DeleteConfirmPopup from '../UI/Popup/DeleteConfirmPopup'
import ThemeVisibilitySelectField from './Fields/ThemeFields/ThemeVisibilitySelectField'
import LayoutContext from '../../contexts/Layout/LayoutContext'

export interface IThemeForm {
    themeId: string | undefined
}

const themeMaxUploadSize = 80 * 1000 * 1000

const ThemeForm: React.FunctionComponent<IThemeForm> = (props) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const layoutDispatch = useContext(LayoutContext).LayoutDispatch

    const [userRoles, setUserRoles] = useState<string[]>()

    /** Settings */
    const gameType: TGameType = 'quiz'
    const [themeName, setThemeName] = useState<string>()
    const [themeType, setThemeType] = useState<TThemeType>(defaultThemeTypes[gameType])
    const [themeVisibility, setThemeVisibility] = useState<TThemeVisibility>(defaultThemeVisibility)
    const [questions, setQuestions] = useState<TQuizQuestion[]>()
    const [optionsLibOptions, setOptionsLibOptions] = useState<{ label: string, value: string }[]>([{ label: '----', value: '' }])

    const [createdOnce, setCreatedOnce] = useState<boolean>(false)
    const [newThemeId, setNewThemeId] = useState<string>()

    /** Popup */
    const [error, setError] = useState<string>('')
    const [popupError, setPopupError] = useState<boolean>(false)
    const [success, setSuccess] = useState<string>('')
    const [popupSuccessUpdate, setPopupSuccessUpdate] = useState<boolean>(false)
    const [popupSuccessCreate, setPopupSuccessCreate] = useState<boolean>(false)
    const [popupDelete, setPopupDelete] = useState<boolean>(false)

    const [sending, setSending] = useState<boolean>(false)

    /** Loading */
    const [loadingQs, setLoadingQs] = useState<boolean>(false)

    useEffect(() => {
        if (Cookies) {
            const token = Cookies.get('login_token')
            if (token) {
                axios({
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/users/roles`
                }).then((res) => {
                    setUserRoles(res.data.roles)
                }).catch((e) => {
                    navigate('/error')
                })
            }
        }
    }, [Cookies])

    useEffect(() => {
        if (Cookies && props.themeId !== undefined) {
            const token = Cookies.get('login_token')
            if (token) {
                setLoadingQs(true)
                axios({
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/themes/${props.themeId}`
                }).then((res) => {
                    setThemeName(res.data.name)
                    setThemeType(res.data.themeType as TThemeType)
                    setQuestions(res.data.questions)
                    if (res.data.visibility) {
                        setThemeVisibility(res.data.visibility as TThemeVisibility)
                    }
                    // console.log(res.data)
                }).catch((e) => {
                    navigate('/error')
                }).finally(() => {
                    setLoadingQs(false)
                })
            } else {
                navigate('/error')
            }
        }
    }, [props.themeId, Cookies])

    useEffect(() => {
        layoutDispatch({ type: 'update_loading', payload: sending })
    }, [sending])

    useEffect(() => {
        if (Cookies) {
            const token = Cookies.get('login_token')
            if (token) {
                axios({
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/quizlibs`
                }).then((res) => {
                    if (res.data) {
                        let arr = [{ label: '----', value: '' }]
                        res.data.forEach((v: any) => {
                            arr.push({ label: v.name, value: v._id })
                        })
                        setOptionsLibOptions(arr)
                    }
                }).catch((e) => {

                })
            }
        }
    }, [Cookies])

    const saveButtonOnClickHandler = async () => {
        if (themeName === undefined || themeName.length < themeNameMinLength || themeName.length > themeNameMaxLength) {
            setError(t('Warnings.require_themename', { min: themeNameMinLength, max: themeNameMaxLength }))
            setPopupError(true)
            return
        }

        if (questions === undefined || questions.length < 1) {
            setError(t('Warnings.require_questions'))
            setPopupError(true)
            return
        }

        setSending(true)
        if (Cookies) {
            if (props.themeId !== undefined) {
                /** Update theme */
                const token = Cookies.get('login_token')
                if (Cookies && props.themeId !== undefined) {
                    try {
                        const res = await axios({
                            method: 'patch',
                            headers: {
                                Authorization: `Bearer ${token}`
                            },
                            maxContentLength: themeMaxUploadSize,
                            maxBodyLength: themeMaxUploadSize,
                            url: `${process.env.REACT_APP_SERVER}/api/themes/${props.themeId}`,
                            data: {
                                name: themeName,
                                visibility: themeVisibility,
                                themeType,
                                languages: [i18n.resolvedLanguage],
                                questions
                            }
                        })

                        if (res.status === 200) {
                            setQuestions(res.data.questions as TQuizQuestion[])
                            setSuccess(t('Messages.theme_update_success'))
                            setPopupSuccessUpdate(true)
                        }
                    } catch (e) {
                        setError(t('Warnings.theme_update_failed'))
                        setPopupError(true)
                    }
                    setSending(false)
                }
            } else {
                /** New theme */
                const token = Cookies.get('login_token')
                if (!createdOnce) {
                    try {
                        const res = await axios({
                            method: 'post',
                            headers: {
                                Authorization: `Bearer ${token}`
                            },
                            maxContentLength: themeMaxUploadSize,
                            maxBodyLength: themeMaxUploadSize,
                            url: `${process.env.REACT_APP_SERVER}/api/themes/new`,
                            data: {
                                name: themeName,
                                visibility: themeVisibility,
                                gameType,
                                themeType,
                                languages: [i18n.resolvedLanguage],
                                questions
                            }
                        })

                        if (res.status === 201) {
                            setQuestions(res.data.questions as TQuizQuestion[])
                            setSuccess(t('Messages.theme_create_success'))
                            setPopupSuccessCreate(true)
                            setCreatedOnce(true)
                            setNewThemeId(res.data._id as string)
                        }
                    } catch (e) {
                        setError(t('Warnings.theme_create_failed'))
                        setPopupError(true)
                    }
                    setSending(false)
                }
            }
        }
    }

    const continueEditOnClickHandler = () => {
        navigate('/theme/' + newThemeId)
    }

    const deleteButtonOnClickHandler = () => {
        setPopupDelete(true)
    }

    const deleteThemeOnConfirmHandler = async () => {
        const token = Cookies.get('login_token')
        if (token && props.themeId !== undefined) {
            try {
                await axios({
                    method: 'delete',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/themes/${props.themeId}`
                })
                navigate('/create')
            } catch (e) {

            }
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <div className={styles.left}>
                    <div className={styles.basicSettings}>
                        <div className={styles.title}>
                            <span>{t('Titles.basic_settings')}</span>
                        </div>
                        <ThemeNameInputField themeName={themeName} setThemeName={setThemeName} inline={true} />
                        <ThemeTypeSelectField themeType={themeType} setThemeType={setThemeType} gameType={gameType} showLabel={true} inline={true} />
                        <LanguageSelectField inline={true} showLabel={true} />
                        <ThemeVisibilitySelectField inline={true} showLabel={true} themeVisibility={themeVisibility} setThemeVisibility={setThemeVisibility} />
                    </div>
                </div>
                <div className={styles.right}>
                    <QuestionList loadingQs={loadingQs} questions={questions} setQuestions={setQuestions} setError={setError} setPopupError={setPopupError} userRoles={userRoles} optionsLibOptions={optionsLibOptions} setOptionsLibOptions={setOptionsLibOptions} />
                </div>
            </div>
            <div className={styles.buttons}>
                {
                    props.themeId !== undefined &&
                    <TextButton id='delete' classes={styles.deleteButton} text={t('Buttons.delete')} onClick={deleteButtonOnClickHandler} />
                }
                <TextButton id='save' text={t('Buttons.save')} onClick={saveButtonOnClickHandler} disabled={sending || loadingQs} />
            </div>
            {
                popupDelete &&
                <DeleteConfirmPopup active={popupDelete} setActive={setPopupDelete} message={t('Messages.confirm_delete_theme')} onConfirm={deleteThemeOnConfirmHandler} />
            }
            {
                popupSuccessUpdate &&
                <SuccessPopup active={popupSuccessUpdate} setActive={setPopupSuccessUpdate} message={success} redirect={`/create?selected=${props.themeId}`} redirectButtonText={t('Buttons.create')} />
            }
            {
                popupSuccessCreate &&
                <SuccessPopup active={popupSuccessCreate} setActive={setPopupSuccessCreate} message={success} redirect={`/create?selected=${newThemeId}`} redirectButtonText={t('Buttons.create')} confirmButtonText={t('Buttons.continue_edit')} onConfirm={continueEditOnClickHandler} />
            }
            {
                popupError &&
                <ErrorPopup key={'errorpopup'} active={popupError} setActive={setPopupError} message={error} />
            }
        </div>
    )
}

export default ThemeForm