import React, { useContext, useEffect, useState } from 'react'
import styles from './GuestWaitingScreen.module.css'
import GameDetails from './GameDetails'
import TextButton from '../../UI/Buttons/TextButton'
import SocketContext from '../../../contexts/Socket/SocketContext'
import { useTranslation } from 'react-i18next'
import UserContext from '../../../contexts/User/UserContext'

export interface IGuestWaitingScreen { }

const GuestWaitingScreen: React.FunctionComponent<IGuestWaitingScreen> = () => {
    const { t } = useTranslation()

    const { room } = useContext(UserContext).UserState
    const { socket } = useContext(SocketContext).SocketState

    const [ready, setReady] = useState(false)
    const [readyText, setReadyText] = useState(t('Buttons.ready'))

    const [message, setMessage] = useState<string>('')

    useEffect(() => {
        if (socket && room.users !== undefined && room.ready !== undefined) {
            if (room.ready.length >= Object.keys(room.users).length - 1) {
                setMessage(t('Messages.waiting_host_start'))
            } else {
                const readyNum = room.ready.length
                const total = Object.keys(room.users).length - 1
                setMessage(t('Messages.waiting_players_ready', { ready: readyNum, total }))
            }
        }
    }, [socket, room.users, room.ready])

    const readyButtonOnClickHandler = () => {
        socket?.emit('ready_play', { ready: !ready })
        if (!ready) {
            setReadyText(t('Buttons.cancel'))
        } else {
            setReadyText(t('Buttons.ready'))
        }

        setReady(() => !ready)
    }

    return (
        <div className={styles.center}>
            <div className={styles.container}>
                <GameDetails />
                <div className={styles.buttons}>
                    <TextButton classes={`${styles.button} ${!ready ? styles.notReady : ''}`} text={readyText} onClick={readyButtonOnClickHandler} />
                </div>
                <div className={styles.message}>
                    <p>{message}</p>
                </div>
            </div>
        </div>
    )
}

export default GuestWaitingScreen