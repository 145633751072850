import React, { useEffect, useRef, useState } from 'react'
import styles from './QuestionListDropdownMenu.module.css'
import IconButton from '../../../UI/Buttons/IconButton'
import { useTranslation } from 'react-i18next'
import ImportPopup from './ImportPopup'
import { TQuizQuestion } from '../../../../utils/library'
import BulkEditPopup from './BulkEditPopup'

export interface IQuestionListDropdownMenu {
    questions: TQuizQuestion[] | undefined
    setQuestions: React.Dispatch<React.SetStateAction<TQuizQuestion[] | undefined>>
    optionsLibOptions: { label: string, value: string }[]
    setOptionsLibOptions: React.Dispatch<React.SetStateAction<{ label: string, value: string }[]>>
    setError: React.Dispatch<React.SetStateAction<string>>
    setPopupError: React.Dispatch<React.SetStateAction<boolean>>
}

const QuestionListDropdownMenu: React.FunctionComponent<IQuestionListDropdownMenu> = (props) => {
    const { t } = useTranslation()

    const dropdownRef = useRef<HTMLDivElement>(null)

    const [show, setShow] = useState<boolean>(false)

    const [popupImport, setPopupImport] = useState<boolean>(false)
    const [popupBulkEdit, setPopupBulkEdit] = useState<boolean>(false)

    // close dropdown when click outside
    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target as HTMLElement)) {
                setShow(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [dropdownRef])

    const iconOnClickHandler = () => {
        setShow(() => !show)
    }

    const importThemeQuestionsOnClickHandler = () => {
        setPopupImport(true)
        setShow(false)
    }

    const bulkEditOnClickHandler = () => {
        if (props.questions === undefined || props.questions.length === 0) {
            props.setError(t('Warnings.require_questions'))
            props.setPopupError(true)
            return
        }

        setPopupBulkEdit(true)
        setShow(false)
    }

    return (
        <div className={styles.container} ref={dropdownRef}>
            <IconButton classes={styles.icon} type='menu-dots-vertical' onClick={iconOnClickHandler} />
            {
                show &&
                <div className={styles.menu}>
                    <div className={styles.menuItem} onClick={importThemeQuestionsOnClickHandler}>{t('QuestionListActions.import_theme_questions')}</div>
                    <div className={styles.menuItem} onClick={bulkEditOnClickHandler}>{t('QuestionListActions.bulk_edit')}</div>
                </div>
            }
            <ImportPopup setQuestions={props.setQuestions} active={popupImport} setActive={setPopupImport} />
            <BulkEditPopup questions={props.questions} setQuestions={props.setQuestions} active={popupBulkEdit} setActive={setPopupBulkEdit} optionsLibOptions={props.optionsLibOptions} setOptionsLibOptions={props.setOptionsLibOptions} setError={props.setError} setPopupError={props.setPopupError} />
        </div>
    )
}

export default QuestionListDropdownMenu