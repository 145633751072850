import React, { useEffect, useState } from 'react'
import styles from './AdminForm.module.css'
import Cookies from 'js-cookie'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import AdminFormInputField from './Fields/AdminFields/AdminFormInputField'
import TextButton from '../UI/Buttons/TextButton'
import { useTranslation } from 'react-i18next'
import ErrorPopup from '../UI/Popup/ErrorPopup'
import moment from 'moment'
import SuccessPopup from '../UI/Popup/SuccessPopup'

export interface IAdminForm { }

const AdminForm: React.FunctionComponent<IAdminForm> = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [userRoles, setUserRoles] = useState<string[]>()

    const fields = [
        { name: 'maintenance_time', label: 'Labels.maintenance_time', tips: t('Messages.datetime_format_tips', { format: 'YYYY-MM-DD HH' }) }
    ]
    const defaultSettings = [
        {
            name: 'maintenance_time',
            value: '',
            type: 'site'
        }
    ]
    const [settings, setSettings] = useState<{ name: string, value: string, type: string }[]>(defaultSettings)

    const [sending, setSending] = useState<boolean>(false)

    /** Popup */
    const [error, setError] = useState<string>('')
    const [popupError, setPopupError] = useState<boolean>(false)
    const [success, setSuccess] = useState<string>('')
    const [popupSuccessUpdate, setPopupSuccessUpdate] = useState<boolean>(false)

    useEffect(() => {
        if (Cookies) {
            const token = Cookies.get('login_token')
            if (token) {
                axios({
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/users/roles`
                }).then((res) => {
                    setUserRoles(res.data.roles)
                    if (!res.data.roles || !res.data.roles?.includes('administrator')) {
                        navigate('/error')
                    }
                }).catch((e) => {
                    navigate('/error')
                })
            }
        }
    }, [Cookies])

    useEffect(() => {
        if (userRoles !== undefined) {
            const token = Cookies.get('login_token')
            if (token) {
                axios({
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/settings`
                }).then((res) => {
                    if (res.data.settings) {
                        const rsettings = res.data.settings as { name: string, value: string, type: string }[]
                        setSettings((settings) => {
                            return settings.map((s) => {
                                const i = rsettings.findIndex((r) => r.name === s.name)
                                if (i === -1) return { ...s }
                                return { ...s, value: rsettings[i].value }
                            })
                        })
                    }
                }).catch((e) => {

                })
            }
        }
    }, [userRoles, Cookies])

    const adminInputOnChangeHandler = (name: string, value: string) => {
        setSettings((settings) => {
            const i = settings.findIndex((s) => s.name === name)
            if (i !== -1) {
                return [
                    ...settings.slice(0, i),
                    {
                        ...settings[i],
                        value
                    },
                    ...settings.slice(i + 1)
                ]
            } else {
                return settings
            }
        })
    }

    const confirmButtonOnClickHandler = async () => {
        const maintenanceTime = settings.find(s => s.name === 'maintenance_time')?.value
        if (maintenanceTime !== undefined && maintenanceTime !== '' && !moment(maintenanceTime, 'YYYY-MM-DD HH', true).isValid()) {
            const field = fields.find((f) => f.name === 'maintenance_time')
            const label = field?.label || 'Maintenance time'
            setError(t('Warnings.wrong_format', { label: t(label) }))
            setPopupError(true)
            return
        }

        setSending(true)
        try {
            const token = Cookies.get('login_token')
            if (token) {
                await axios({
                    method: 'post',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/settings/batch-update`,
                    data: { settings }
                }).then((res) => {
                    if (res.status === 201) {
                        setSuccess(t('Messages.settings_update_success'))
                        setPopupSuccessUpdate(true)
                    }
                })
            }
        } catch (e) {
            setError(t('Warnings.request_failed'))
            setPopupError(true)
        }
        setSending(false)
    }

    return (
        <div className={styles.container}>
            {
                userRoles?.includes('administrator') &&
                <>
                    <div className={styles.form}>
                        {
                            settings.map((s) => {
                                const f = fields.find((f) => s.name === f.name)
                                const label = f?.label || 'label'
                                return <AdminFormInputField key={'admin_key_' + s.name} name={s.name} label={label} value={s.value} inline={true} onChange={adminInputOnChangeHandler} tips={f?.tips} />
                            })
                        }
                    </div>
                    <div className={styles.buttons}>
                        <TextButton id='confirm' text={t('Buttons.confirm')} onClick={confirmButtonOnClickHandler} disabled={sending} />
                    </div>
                    <ErrorPopup key={'errorpopup'} active={popupError} setActive={setPopupError} message={error} />
                    <SuccessPopup active={popupSuccessUpdate} setActive={setPopupSuccessUpdate} message={success} redirect='/' redirectButtonText={t('Buttons.back')} />
                </>
            }
        </div>
    )
}

export default AdminForm