import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './en/translation.json'
import tw from './zh-TW/translation.json'
import LanguageDetector from 'i18next-browser-languagedetector'

export const resources = {
    en: {
        translation: en
    },
    'zh-TW': {
        translation: tw,
    }
}

const detection = {
    order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupLocalStorage: 'lng'
}

const supportedLngs = ['en', 'zh', 'zh-TW', 'zh-HK']

/**
 * fallbackLng = { key : value } where
 * key: detected/selected language
 *     (e.g. for key = 'zh', if detected language is 'zh-**' and 'zh-**' is in supportedLngs, the value of 'zh' will be used),
 * value: array of language resources order
 *     (e.g. if value = ['en', 'zh-TW'] and 'en' is in resources, current language will be set to 'en', else if 'zh-TW' is in resources, 'zh-TW' will be set).
 */
const fallbackLng = {
    'en': ['en'],
    'zh': ['zh-TW'],
    'default': ['en']
}

i18next.use(LanguageDetector).use(initReactI18next).init({
    fallbackLng,
    supportedLngs,
    resources,
    detection,
    debug: process.env.REACT_APP_ENV === 'production' ? false : true
    // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
    // set returnNull to false (and also in the i18next.d.ts options)
    // returnNull: false,
});