import React, { useContext, useEffect } from 'react'
import styles from './MaintenancePage.module.css'
import { useTranslation } from 'react-i18next'
import PageTitle from '../components/Widgets/Title/PageTitle'
import SocketContext from '../contexts/Socket/SocketContext'
import { useNavigate } from 'react-router-dom'

const MaintenancePage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { connectStatus } = useContext(SocketContext).SocketState

    useEffect(() => {
        if (connectStatus === 'connect') {
            navigate('/')
        }
    }, [connectStatus])

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <PageTitle title={t('Titles.disconnected')} />
                <div className={styles.messageContainer}>
                    <p className={styles.message}>{t('Messages.disconnected')}</p>
                </div>
            </div>
        </div>
    )
}

export default MaintenancePage