import React, { useEffect, useState } from 'react'
import MultiInputField from '../../../../UI/Fields/MultiInputField'
import { useTranslation } from 'react-i18next'
import { quizOptionMaxLength, quizOptionMaxLengthEN, quizOptionsMax, quizOptionsMin } from '../../../../../utils/library'

export interface IQuestionOptionsMultiInputField {
    answer: string[]
    options: string[]
    setOptions: React.Dispatch<React.SetStateAction<string[]>>
    inline?: boolean
    setError: React.Dispatch<React.SetStateAction<string>>
    setPopupError: React.Dispatch<React.SetStateAction<boolean>>
}

const QuestionOptionsMultiInputField: React.FunctionComponent<IQuestionOptionsMultiInputField> = (props) => {
    const { t, i18n } = useTranslation()

    const label = t('Labels.options') + ` (${quizOptionsMin === quizOptionsMax ? `${quizOptionsMax}` : `${quizOptionsMin} - ${quizOptionsMax}`})`
    const [maxLength, setMaxLength] = useState<number>(quizOptionMaxLength)

    useEffect(() => {
        if (i18n.resolvedLanguage === 'en') {
            setMaxLength(quizOptionMaxLengthEN)
        } else {
            setMaxLength(quizOptionMaxLength)
        }
    }, [i18n.resolvedLanguage])


    const inputInvalidAddHandler = () => {
        props.setError(t('Warnings.multiinput_invalid_add', { label: t('Labels.options'), max: quizOptionsMax }))
        props.setPopupError(true)
    }

    return (
        <MultiInputField id='question-options' label={label} values={props.options} setValues={props.setOptions} fixedValues={props.answer} inline={props.inline} onInvalidAdd={inputInvalidAddHandler} max={quizOptionsMax} maxLength={maxLength} />
    )
}

export default QuestionOptionsMultiInputField