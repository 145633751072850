import React from 'react'
import InputField from '../../../UI/Fields/InputField'
import { useTranslation } from 'react-i18next'
import { themeNameMaxLength } from '../../../../utils/library'

export interface IThemeNameInputFieldProps {
    themeName: string | undefined
    setThemeName: React.Dispatch<React.SetStateAction<string | undefined>>
    inline?: boolean
}

const ThemeNameInputField: React.FunctionComponent<IThemeNameInputFieldProps> = (props) => {
    const { t } = useTranslation()

    /** Update theme name */
    const themeNameInputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setThemeName(() => e.target.value)
    }

    return (
        <InputField inline={props.inline} id='theme-name' label={t('Labels.theme_name')} value={props.themeName} onChange={themeNameInputOnChangeHandler} maxLength={themeNameMaxLength} />
    )
}

export default ThemeNameInputField