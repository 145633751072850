import React from 'react'
import SelectField from '../../../UI/Fields/SelectField'
import { useTranslation } from 'react-i18next'
import { TArticleStatus, articleStatusOptions } from '../../../../utils/library'

export interface IArticleStatusSelectField {
    articleStatus: TArticleStatus,
    setArticleStatus: React.Dispatch<React.SetStateAction<TArticleStatus>>
    inline?: boolean
    showLabel: boolean
}

const ArticleStatusSelectField: React.FunctionComponent<IArticleStatusSelectField> = (props) => {
    const { t } = useTranslation()

    const options = articleStatusOptions

    const articleStatusOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        props.setArticleStatus(() => e.target.value as TArticleStatus)
    }

    return (
        <SelectField inline28={props.inline} id='article-status-select' options={options} onChange={articleStatusOnChangeHandler} value={props.articleStatus} label={props.showLabel ? t('Labels.article_status') : undefined} translate={true} />
    )
}

export default ArticleStatusSelectField