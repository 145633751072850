import React, { Fragment } from 'react'
import styles from './QuestionListItem.module.css'
import { TQuizQuestion } from '../../../../utils/library'

export interface IQuestionListItem {
    index: number
    question: TQuizQuestion
    setSelectedQuestionIndex: React.Dispatch<React.SetStateAction<number | undefined>>
    setPopupEdit: React.Dispatch<React.SetStateAction<boolean>>
    onDelete: (index: number) => any
}

const QuestionListItem: React.FunctionComponent<IQuestionListItem> = (props) => {
    const editIconOnClickHandler = () => {
        props.setSelectedQuestionIndex(props.index)
        props.setPopupEdit(true)
    }

    const deleteIconOnClickHandler = () => {
        props.onDelete(props.index)
    }

    return (
        <div className={styles.container}>
            <span className={styles.index}>{props.index + 1}</span>
            <div className={styles.content}>
                <div className={styles.question}>
                    <span className={styles.text}>{props.question.question}</span>
                </div>
                <div className={styles.answer}>
                    <span className={styles.text}>{props.question.answer.map((a, i) => {
                        const answerLength = props.question.answer.length
                        return (
                            <Fragment key={'ans_' + i}>
                                {a}
                                {i < answerLength - 1 && <i className={styles.sep}> / </i>}
                            </Fragment>
                        )
                    })}</span>
                </div>
            </div>
            <div className={styles.actions}>
                <i className={styles.editIcon} onClick={editIconOnClickHandler}></i>
                <i className={styles.deleteIcon} onClick={deleteIconOnClickHandler}></i>
            </div>
        </div>
    )
}

export default QuestionListItem