import React from 'react'
import styles from './PopupTitle.module.css'
import Title from './Title'

export interface IPopupTitle {
    title: string
}

const PopupTitle: React.FunctionComponent<IPopupTitle> = (props) => {

    return (
        <div className={styles.container}>
            <Title title={props.title} />
        </div>
    )
}

export default PopupTitle