import React, { useEffect } from 'react'
import styles from './ThemePage.module.css'
import PageTitle from '../components/Widgets/Title/PageTitle'
import { useTranslation } from 'react-i18next'
import Header from '../components/Widgets/Header/Header'
import ThemeForm from '../components/Forms/ThemeForm'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'

const ThemePage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const params = useParams()
    const themeId = params.themeId

    useEffect(() => {
        if (Cookies) {
            const token = Cookies.get('login_token')

            if (!token) {
                navigate('/error')
            }
        }
    }, [Cookies])

    return (
        <>
            <Header size='medium' />
            <div className={styles.container}>
                <PageTitle title={t('Titles.theme')} backPath='/create' />
                <ThemeForm themeId={themeId} />
            </div>
        </>
    )
}

export default ThemePage