import React, { useEffect, useState } from 'react'
import SelectField from '../../../../UI/Fields/SelectField'
import { useTranslation } from 'react-i18next'
import { TThemeData } from '../../../../../utils/library'

export interface IQuizThemeSelectField {
    themesData: TThemeData[] | undefined
    id: string
    setId: React.Dispatch<React.SetStateAction<string>>
    inline?: boolean
    showLabel: boolean
}

const QuizThemeSelectField: React.FunctionComponent<IQuizThemeSelectField> = (props) => {
    const { t } = useTranslation()

    const [options, setOptions] = useState<{ label: string, value: string }[]>([{ label: '----', value: '' }])

    useEffect(() => {
        if (props.themesData !== undefined) {
            const opts = [{ label: '----', value: '' }]
            props.themesData.forEach((d) => {
                opts.push({ label: d.name, value: d._id })
            })
            setOptions(() => opts)
        }
    }, [props.themesData])

    const quizThemeOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value
        props.setId(val)
    }

    return (
        <SelectField inline={props.inline} id='import-theme-select' options={options} onChange={quizThemeOnChangeHandler} value={props.id} label={props.showLabel ? t('Labels.theme_name') : undefined} />
    )
}

export default QuizThemeSelectField