import React, { createContext } from 'react'

export interface ILayoutContextState {
    loading: boolean
}

export const defaultLayoutContextState: ILayoutContextState = {
    loading: false
}

export type TLayoutContextActions = 'update_loading'

export type TLayoutContextPayload = boolean

export interface ILayoutContextActions {
    type: TLayoutContextActions,
    payload: TLayoutContextPayload
}

export const LayoutReducer = (state: ILayoutContextState, action: ILayoutContextActions) => {
    switch (action.type) {
        case 'update_loading':
            return { ...state, loading: action.payload as boolean }

        default:
            return { ...state }
    }
}

export interface ILayoutContextProps {
    LayoutState: ILayoutContextState,
    LayoutDispatch: React.Dispatch<ILayoutContextActions>
}

const LayoutContext = createContext<ILayoutContextProps>({
    LayoutState: defaultLayoutContextState,
    LayoutDispatch: () => { }
})

export default LayoutContext