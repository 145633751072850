/** Types */

export type TRoomVisibility = 'public' | 'hidden' | 'password'

export type TRoomStatus = 'waiting' | 'loading' | 'playing' | 'ended'

export type TGameType = 'quiz'

export type TQuizGameMode = 'quiz_normal' | 'quiz_race' | 'quiz_first'

export type TGameMode = TQuizGameMode

export type TQuizThemeType = 'math' | 'science' | 'language' | 'acg' | 'literature' | 'history' | 'trivia' | 'entertainment'

export type TThemeType = TQuizThemeType

export type TAnswerType = 'select' | 'text' | 'tf'

export type TUseOptionsLib = 'yes' | 'no'

export type TTFAnswer = 'true' | 'false'

export type TQuizTimeLimit = '10s' | '20s' | '30s' | '1m'

export type TThemeVisibility = 'public' | 'private'

export type TThemeWho = 'all' | 'my' | 'other_users' | 'official'

export type TArticleStatus = 'public' | 'draft'

/** Interfaces */

export type TRoomData = {
    roomId: string
    usersCount: number
    roomName: string
    visibility: TRoomVisibility
    gameType: TGameType
    gameMode: TGameMode
    themeName: string
    themeType: string
}

export type TThemeData = {
    _id: string
    name: string
    themeType: TThemeType
    count: number
    questionTypes: string[]
    official: boolean
    visibility: TThemeVisibility
    editable?: boolean
}

export interface IRoomVisibilityOption {
    label: string
    value: TRoomVisibility
}

export interface IGameModeOption {
    label: string
    value: TGameMode
}

export interface IThemeTypeOption {
    label: string
    value: TThemeType
}

export interface IAnswerTypeOption {
    label: string
    value: TAnswerType
}

export interface IUseOptionsLibOption {
    label: string
    value: TUseOptionsLib
}

export interface ITFAnswerOption {
    label: string
    value: TTFAnswer
}

export interface IQuizTimeLimit {
    label: string
    value: TQuizTimeLimit
}

export interface IThemeVisibilityOption {
    label: string
    value: TThemeVisibility
}

export interface IArticleStatusOption {
    label: string
    value: TArticleStatus
}

export interface IThemeWhoOption {
    label: string
    value: TThemeWho
}

export interface IOneDialogRecord {
    roomUId: string
    username: string
    createdAt: number
    message: string
}

export type TAnswerRes = {
    correct: boolean
    newScore: number
    answer?: string[]
    timeSpent: number // 1000 = 1s
}

export type TAnswerResData = {
    res: TAnswerRes
    timeout: boolean
    tooLate?: boolean
}

export type TUsersInRoom = {
    [uid: string]: {
        socketId: string
        username: string
        avatar: string
        roomId: string
        roomUId: string
        score: number
        records: {
            questionCount?: number
            correctCount?: number
            totalTimeSpent?: number
        }
    }
}

export type TUserObject = {
    uid: string
    socketId: string
    username: string
    avatar: string
    roomId: string
    roomUId: string
    score: number
    records: {
        questionCount?: number
        correctCount?: number
        totalTimeSpent?: number
    }
}

export type TQues = {
    question: string
    type: TAnswerType
    options: string[] | undefined
    image: string | undefined
    audio: string | undefined
    afterQuestion: string | undefined
    afterImage: string | undefined
}

export type TQuizQuestion = {
    _id?: string            // When patch to server, _id is not needed
    question: string
    image?: string
    audio?: string
    answer: string[]
    type: TAnswerType
    options?: string[]
    useQuizlib?: boolean
    quizlibId?: string,
    afterQuestion?: string,
    afterImage?: string
}

export interface IArticleData {
    _id?: string
    featuredImg: string
    title: string
    content: string
    publishDate: Date | string
    gameType?: TGameType
    themeType?: TThemeType
}

export interface IArticleListItemData {
    _id?: string
    excerpt?: string
    featuredImg: string
    title: string
    publishDate: Date | string
    themeId: string
    gameType: TGameType
    slug: string
    status: TArticleStatus
}

/** Settings */

export const roomVisibilitiesOptions: IRoomVisibilityOption[] = [
    { label: 'RoomVisibilities.public', value: 'public' },
    { label: 'RoomVisibilities.hidden', value: 'hidden' },
    { label: 'RoomVisibilities.password', value: 'password' }
]

export const defaultRoomVisibility = 'public'

export const gameTypes: TGameType[] = ['quiz']

export const gameModes: TGameMode[] = ['quiz_race', 'quiz_normal', 'quiz_first']
export const gameModesOptions: { [cat: string]: IGameModeOption[] } = {
    quiz: [
        { label: 'GameModes.quiz_normal', value: 'quiz_normal' },
        { label: 'GameModes.quiz_race', value: 'quiz_race' },
        // { label: 'GameModes.quiz_first', value: 'quiz_first' }
    ]
}
export const defaultGameModes: { [type: string]: TGameMode } = {
    quiz: 'quiz_race'
}

export const themeTypes: TThemeType[] = ['math', 'science', 'language', 'acg', 'literature', 'history', 'trivia', 'entertainment']
export const themeTypesOptions: { [cat: string]: IThemeTypeOption[] } = {
    quiz: [
        { label: 'ThemeTypes.math', value: 'math' },
        { label: 'ThemeTypes.science', value: 'science' },
        { label: 'ThemeTypes.language', value: 'language' },
        { label: 'ThemeTypes.acg', value: 'acg' },
        { label: 'ThemeTypes.literature', value: 'literature' },
        { label: 'ThemeTypes.history', value: 'history' },
        { label: 'ThemeTypes.trivia', value: 'trivia' },
        { label: 'ThemeTypes.entertainment', value: 'entertainment' }
    ]
}
export const defaultThemeTypes: { [type: string]: TThemeType } = {
    quiz: 'math'
}

export const answerTypes: TAnswerType[] = ['select', 'text', 'tf']
export const answerTypeOptions: IAnswerTypeOption[] = [
    { label: 'AnswerTypes.select', value: 'select' },
    { label: 'AnswerTypes.text', value: 'text' },
    // { label: 'AnswerTypes.tf', value: 'tf' }
]
export const defaultAnswerType: TAnswerType = 'select'

export const useOptionsLibs: TUseOptionsLib[] = ['yes', 'no']
export const useOptionsLibOptions: IUseOptionsLibOption[] = [
    { label: 'UseOptionsLibs.yes', value: 'yes' },
    { label: 'UseOptionsLibs.no', value: 'no' }
]
export const defaultUseOptionsLib: TUseOptionsLib = 'no'

export const tfAnswers: TTFAnswer[] = ['true', 'false']
export const tfAnswerOptions: ITFAnswerOption[] = [
    { label: 'TFAnswers.true', value: 'true' },
    { label: 'TFAnswers.false', value: 'false' }
]
export const defaultTFAnswer: TTFAnswer = 'true'

export const quizTimeLimits: TQuizTimeLimit[] = ['10s', '20s', '30s', '1m']
export const quizTimeLimitOptions: IQuizTimeLimit[] = [
    { label: 'TimeLimits.10s', value: '10s' },
    { label: 'TimeLimits.20s', value: '20s' },
    { label: 'TimeLimits.30s', value: '30s' },
    { label: 'TimeLimits.1m', value: '1m' }
]
export const defaultQuizTimeLimit: TQuizTimeLimit = '20s'
export const timeLimitValues: { [t: string]: number } = {
    '10s': 10,
    '20s': 20,
    '30s': 30,
    '1m': 60
}

export const themeVisibilities: TThemeVisibility[] = ['public', 'private']
export const themeVisibilityOptions: IThemeVisibilityOption[] = [
    { label: 'ThemeVisibilities.public', value: 'public' },
    { label: 'ThemeVisibilities.private', value: 'private' }
]
export const defaultThemeVisibility: TThemeVisibility = 'private'

export const themeWhos: TThemeWho[] = ['all', 'my', 'other_users', 'official']
export const themeWhoOptions: IThemeWhoOption[] = [
    { label: 'ThemeWhos.all', value: 'all' },
    { label: 'ThemeWhos.my', value: 'my' },
    { label: 'ThemeWhos.other_users', value: 'other_users' },
    { label: 'ThemeWhos.official', value: 'official' }
]
export const defaultThemeWho: TThemeWho = 'all'

export const articleStatuses: TArticleStatus[] = ['public', 'draft']
export const articleStatusOptions: IArticleStatusOption[] = [
    { label: 'ArticleStatuses.draft', value: 'draft' },
    { label: 'ArticleStatuses.public', value: 'public' }
]
export const defaultArticleStatus: TArticleStatus = 'draft'

export const usernameMaxLength: number = 20

export const roomNameMaxLength: number = 20
export const passwordMaxLength: number = 8

export const defaultThemeSettings: { [setting: string]: any } = {
    questionNumber: 30
}

export const themeNameMinLength: number = 2
export const themeNameMaxLength: number = 30

export const quizQuestionMaxLength: number = 120
export const quizQuestionMaxLengthEN: number = 180
export const quizAnswerMin: number = 1
export const quizAnswerMax: number = 1
export const quizOptionsMin: number = 4
export const quizOptionsMax: number = 8
export const quizOptionMaxLength: number = 40
export const quizOptionMaxLengthEN: number = 60

export const quizlibNameMinLength: number = 2
export const quizlibNameMaxLength: number = 30
export const quizlibOptionsMin: number = 10

export const articleTitleMinLength: number = 2

export const themeListLimit: number = 24
export const articleListLimit: number = 12