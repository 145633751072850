import React from 'react'
import styles from './QuizQuestionsMultiSelectField.module.css'
import { TQuizQuestion } from '../../../../../../utils/library'
import QuizQuestionsMultiSelectItem from './QuizQuestionsMultiSelectItem'
import Scrollbars from 'react-custom-scrollbars-2'

export interface IQuizQuestionsMultiSelectField {
    questions: TQuizQuestion[]
    selectedQs: number[]
    setSelectedQs: React.Dispatch<React.SetStateAction<number[]>>
}

const QuizQuestionsMultiSelectField: React.FunctionComponent<IQuizQuestionsMultiSelectField> = (props) => {
    const bulkSelectOnClickHandler = () => {
        if (props.selectedQs.length !== props.questions.length) {
            props.setSelectedQs(() => props.questions.map((v, i) => i))
        } else {
            props.setSelectedQs([])
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.actions}>
                <div className={styles.action}>
                    <i className={`${styles.checkbox} ${props.selectedQs.length === props.questions.length ? styles.checked : ''}`} onClick={bulkSelectOnClickHandler}></i>
                </div>
            </div>
            <div className={styles.scrollbarContainer}>
                <Scrollbars
                    style={{ height: '100%', maxHeight: '300px', minHeight: '300px' }}
                >
                    <div className={styles.list}>
                        {
                            props.questions.map((q, i) => {
                                return (
                                    <QuizQuestionsMultiSelectItem key={'quiz_ms_' + i} index={i} selected={props.selectedQs.includes(i)} selectedQs={props.selectedQs} setSelectedQs={props.setSelectedQs} question={q} />
                                )
                            })
                        }
                    </div>
                </Scrollbars>
            </div>
        </div>
    )
}

export default QuizQuestionsMultiSelectField