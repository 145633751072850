import React from 'react'
import SelectField from '../../../../UI/Fields/SelectField'
import { useTranslation } from 'react-i18next'
import { TAnswerType, answerTypeOptions, answerTypes } from '../../../../../utils/library'

export interface IAnswerTypeSelectField {
    answerType: TAnswerType
    setAnswerType: React.Dispatch<React.SetStateAction<TAnswerType>>
    inline?: boolean
    showLabel: boolean
}

const AnswerTypeSelectField: React.FunctionComponent<IAnswerTypeSelectField> = (props) => {
    const { t } = useTranslation()

    const options = answerTypeOptions

    const answerTypeOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value as TAnswerType
        // console.log(`selected answer type: ${val}`)
        if (answerTypes.includes(val)) {
            props.setAnswerType(val)
        }
    }

    return (
        <SelectField inline={props.inline} id='answertype-select' options={options} onChange={answerTypeOnChangeHandler} value={props.answerType} label={props.showLabel ? t('Labels.answer_type') : undefined} translate={true} />
    )
}

export default AnswerTypeSelectField