import React, { useEffect, useRef } from 'react'
import styles from './TextareaField.module.css'

export interface ITextareaField {
    id: string
    label?: string
    value?: string
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
    placeholder?: string
    invalid?: boolean
    inline?: boolean
    inline28?: boolean
    leftAlign?: boolean
    maxLength?: number
    rows?: number
    classes?: string
    disabled?: boolean
    tips?: string
}

const TextareaField: React.FunctionComponent<ITextareaField> = (props) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        if (props.value !== undefined && textAreaRef.current) {
            textAreaRef.current.style.height = 'auto'
            textAreaRef.current.style.height = (textAreaRef.current.scrollHeight + 5) + 'px'
        }
    }, [props.value, textAreaRef])

    return (
        <div className={`${styles.field} ${props.inline === true ? styles.inline : ''} ${props.inline28 === true ? styles.inline28 : ''} ${props.classes !== undefined ? props.classes : ''} ${props.disabled === true ? styles.disabled : ''}`}>
            {
                props.label !== undefined &&
                <label className={`${styles.label} ${props.leftAlign ? styles.leftAlign : ''}`} htmlFor={props.id}>{props.label}</label>
            }
            <div className={styles.container}>
                <textarea ref={textAreaRef} id={props.id} className={`${styles.textarea} ${props.invalid ? styles.invalid : ''}`} value={props.value || ''} placeholder={props.placeholder} onChange={props.onChange} maxLength={props.maxLength} disabled={props.disabled === true ? true : false} rows={props.rows} />
                {
                    props.tips !== undefined &&
                    <p className={styles.tips}>{props.tips}</p>
                }
            </div>
        </div>
    )
}

export default TextareaField