import React, { useEffect, useState } from 'react'
import styles from './UserAnswerStatus.module.css'

export interface IUserAnswerStatus {
    name: string
    correct: boolean
}

const UserAnswerStatus: React.FunctionComponent<IUserAnswerStatus> = (props) => {
    const [visible, setVisible] = useState<boolean>(true)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setVisible(false)
        }, 2000)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    return visible ? (
        <div className={`${styles.container} ${props.correct ? styles.correct : styles.incorrect}`}>
            <span className={styles.userName}>{props.name}</span><i className={styles.icon}></i>
        </div>
    ) : null
}

export default UserAnswerStatus