import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TextButton from '../UI/Buttons/TextButton'
import styles from './JoiningForm.module.css'
import UserContext from '../../contexts/User/UserContext'
import AvatarSelector from './Fields/UserFIelds/AvatarSelector'
import UsernameInputField from './Fields/UserFIelds/UsernameInputField'
import ErrorPopup from '../UI/Popup/ErrorPopup'
import LanguageSelectField from './Fields/LanguageSelectField'
import { useTranslation } from 'react-i18next'
import { usernameMaxLength } from '../../utils/library'
import LoginWithGoogleButton from './Fields/UserFIelds/LoginWithGoogleButton'
import LogoutButton from './Fields/UserFIelds/LogoutButton'
import LogoutAllButton from './Fields/UserFIelds/LogoutAllButton'
import Cookies from 'js-cookie'
import SocketContext from '../../contexts/Socket/SocketContext'
import CreateRandomRoomPopup from './CreateRandomRoomPopup'

export interface IJoiningFormProps { }

const JoiningForm: React.FunctionComponent<IJoiningFormProps> = () => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    /** Get user context */
    const userContext = useContext(UserContext)
    const { user } = userContext.UserState
    const userDispatch = userContext.UserDispatch
    const { socket } = useContext(SocketContext).SocketState

    /** Input */
    const [usernameInput, setUsernameInput] = useState<string>()

    /** Error Popup */
    const [error, setError] = useState<string>('')
    const [popupError, setPopupError] = useState<boolean>(false)
    const [popupCreateRandomRoom, setPopupCreateRandomRoom] = useState<boolean>(false)

    /** Login & Logout */
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
    const [loggingIn, setLoggingIn] = useState<boolean>(false)
    const [loggingOut, setLoggingOut] = useState<boolean>(false)

    useEffect(() => {
        const token = Cookies.get('login_token')
        if (token) {
            setIsLoggedIn(true)
        } else {
            setIsLoggedIn(false)
        }
    }, [Cookies])

    /** Quick join */
    const quickJoinButtonOnClickHandler = () => {
        const success = updateUser()

        if (success) {
            socket?.emit('quick_join', { user, language: i18n.resolvedLanguage }, (res: any) => {
                if (res?.error === undefined) {
                    userDispatch({ type: 'enter_room', payload: res })
                    userDispatch({ type: 'update_room_status', payload: 'waiting' })

                    navigate(`/rooms/${res.roomId}`)
                } else {
                    setError(t(res.error))
                    if (res.error === 'Warnings.no_room') {
                        setPopupCreateRandomRoom(true)
                    } else {
                        setPopupError(true)
                    }
                }
            })
        }
    }

    /** Navigate to rooms */
    const roomButtonOnClickHandler = () => {
        const success = updateUser()

        if (success) {
            navigate('/rooms')
        }
    }

    /** Navigate to create room */
    const createButtonOnClickHandler = () => {
        const success = updateUser()

        if (success) {
            navigate('/create')
        }
    }

    const updateUser = () => {
        if (usernameInput === undefined || usernameInput.trim().length <= 0) {
            setError(t('Warnings.require_username'))
            setPopupError(true)
            return false
        }

        if (usernameInput.length > usernameMaxLength) {
            setError(t('Warnings.username_too_long', { maxLength: usernameMaxLength }))
            setPopupError(true)
            return false
        }

        userDispatch({ type: 'update_username', payload: usernameInput.trim() })
        return true
    }

    return (
        <div className={styles.container}>
            <AvatarSelector />
            <UsernameInputField username={usernameInput} setUsername={setUsernameInput} />
            <LanguageSelectField showLabel={false} />
            <div className={styles.buttons}>
                <TextButton id='quick-join' classes={styles.button} text={t('Buttons.quick_join')} onClick={quickJoinButtonOnClickHandler} />
                {/* <TextButton id='create-room' classes={styles.button} text={t('Buttons.create')} onClick={createButtonOnClickHandler} /> */}
                <TextButton id='rooms' classes={styles.button} text={t('Buttons.rooms')} onClick={roomButtonOnClickHandler} />
            </div>
            {
                !isLoggedIn &&
                <div className={styles.login}>
                    <LoginWithGoogleButton setLoggingIn={setLoggingIn} />
                </div>
            }
            {
                isLoggedIn &&
                <div className={styles.logout}>
                    <LogoutButton setLoggingOut={setLoggingOut} setIsLoggedIn={setIsLoggedIn} />
                    <LogoutAllButton setLoggingOut={setLoggingOut} setIsLoggedIn={setIsLoggedIn} />
                </div>
            }
            <CreateRandomRoomPopup active={popupCreateRandomRoom} setActive={setPopupCreateRandomRoom} setError={setError} setPopupError={setPopupError} />
            {
                popupError &&
                <ErrorPopup active={popupError} setActive={setPopupError} message={error} />
            }
        </div>
    )
}

export default JoiningForm