import React, { useContext, useEffect, useState } from 'react'
import AvatarIcon from '../../UI/Avatar/AvatarIcon'
import styles from './UserCard.module.css'
import UserContext from '../../../contexts/User/UserContext'
import SocketContext from '../../../contexts/Socket/SocketContext'

export interface IUserCard {
    uid: string
    username: string
    avatar: string
    roomAdminId: string
    score: number
}

const UserCard: React.FunctionComponent<IUserCard> = (props) => {
    const userContext = useContext(UserContext)
    const { user, room } = userContext.UserState
    const [ready, setReady] = useState<boolean>(false)

    const { socket } = useContext(SocketContext).SocketState

    const [refreshAnime, setRefreshAnime] = useState<boolean>(false)

    useEffect(() => {
        if (room.ready !== undefined && room.users !== undefined && room.users[props.uid] !== undefined) {
            const roomReady = room.ready
            const roomUId = room.users[props.uid].roomUId
            setReady(() => roomReady.includes(roomUId))
        }
        // console.log('ready')
    }, [room.ready, room.users, props.uid])

    useEffect(() => {
        setRefreshAnime(() => !refreshAnime)
    }, [room.users, room.ready])

    const kickoutOnClickHandler = () => {
        socket?.emit('remove_user', { uid: props.uid })
    }

    return (
        <div className={`${styles.card} ${user.uid === props.uid ? styles.myCard : ''} ${ready ? styles.readyCard : ''}`}>
            <div className={styles.user}>
                <div className={`${styles.avatar}`}>
                    <AvatarIcon src={props.avatar} size='sm' myAvatar={user.uid === props.uid} />
                    {
                        props.uid === props.roomAdminId &&
                        <i className={styles.admin}></i>
                    }
                    {
                        room.roomStatus === 'waiting' && props.uid !== props.roomAdminId && user.uid === props.roomAdminId && !props.uid.startsWith('default_user') &&
                        <i className={styles.kickout} onClick={kickoutOnClickHandler}></i>
                    }
                </div>
                <div className={styles.infos}>
                    <span className={styles.username}>{props.username}</span>
                    {
                        props.score !== undefined &&
                        <span className={styles.score}>{props.score}</span>
                    }
                </div>
            </div>
            <div className={styles.icon}>
                {
                    ready &&
                    <span className={styles.ready}></span>
                }
                {
                    room.roomStatus === 'waiting' && !props.uid.startsWith('default_user') && props.uid !== props.roomAdminId && !ready &&
                    <span className={`${styles.waiting} ${refreshAnime ? styles.anime1 : styles.anime2}`}></span>
                }
            </div>
        </div>
    )
}

export default UserCard