import React, { useContext, useEffect, useState } from 'react'
import styles from './Game.module.css'
import UsersSidebar from './UsersSidebar/UserSidebar'
import GameScreenFrame from './GameScreenFrame'
import SocketContext from '../../contexts/Socket/SocketContext'
import UserContext from '../../contexts/User/UserContext'
import { TUsersInRoom } from '../../utils/library'
import DialogBox from './DialogBox/DialogBox'
import { useNavigate } from 'react-router-dom'
import ErrorPopup from '../UI/Popup/ErrorPopup'
import { useTranslation } from 'react-i18next'
import NotIdleConfirmPopup from '../UI/Popup/NotIdleConfirmPopup'

export interface IGame { }

const Game: React.FunctionComponent<IGame> = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const userContext = useContext(UserContext)
    const { user, room } = userContext.UserState
    const userDispatch = userContext.UserDispatch
    const { socket } = useContext(SocketContext).SocketState
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ])

    const [showSidebar, setShowSidebar] = useState<boolean>(false)

    const [isSidebarHided, setIsSidebarHided] = useState<boolean>(false)
    const [unreadMsg, setUnreadMsg] = useState<number>(0)

    const [popupCheck, setPopupCheck] = useState<boolean>(false)

    /**
     * Warning when change url
     * problem found: timer will stop when the warning popup
     */
    // useEffect(() => {
    //     const beforeUnloadEventHandler = (e: BeforeUnloadEvent) => {
    //         e.preventDefault()
    //         return e.returnValue = ''
    //     }

    //     window.addEventListener("beforeunload", beforeUnloadEventHandler)

    //     return () => {
    //         window.removeEventListener("beforeunload", beforeUnloadEventHandler)
    //     }
    // }, [])

    /** Handle browswer back button */
    // useEffect(() => {
    //     const popStateEventHandler = (e: PopStateEvent) => {
    //         e.preventDefault()
    //         // window.history.pushState(null, '', location.href)
    //         // window.history.go(1)
    //         // navigate(window.location.href)
    //         console.log('popstate')
    //     }

    //     window.addEventListener("popstate", popStateEventHandler)

    //     return () => {
    //         window.removeEventListener("popstate", popStateEventHandler)
    //     }
    // }, [])

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight])
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, [])

    useEffect(() => {
        socket?.on('update_ready_users', (res: { ready: string[] }) => {
            // console.log('update ready : ')
            // console.log(res.ready)
            userDispatch({ type: 'update_ready', payload: res.ready })
        })

        return () => {
            socket?.off('update_ready_users')
        }
    }, [socket])

    useEffect(() => {
        socket?.emit('users_in_room', (res: { usersInRoom: TUsersInRoom, ready: string[] }) => {
            // console.log('first update users in room : ')
            // console.log(res.usersInRoom)
            userDispatch({ type: 'update_users_in_room', payload: res.usersInRoom })
            userDispatch({ type: 'update_ready', payload: res.ready })
        })
    }, [socket])


    useEffect(() => {
        socket?.on('update_users_in_room', (res: { usersInRoom: TUsersInRoom, ready: string[] }) => {
            // console.log('update users in room : ')
            // console.log(res.usersInRoom)
            userDispatch({ type: 'update_users_in_room', payload: res.usersInRoom })
            userDispatch({ type: 'update_ready', payload: res.ready })
        })

        return () => {
            socket?.off('update_users_in_room')
        }
    }, [socket])

    useEffect(() => {
        if (socket !== undefined && user.uid !== undefined) {
            socket.on('remove_user_call', (res: { uid: string }) => {
                if (res.uid === user.uid) {
                    userDispatch({ type: 'update_kickedout', payload: 'host' })
                    navigate('/rooms')
                }
            })
        }

        return () => {
            socket?.off('remove_user_call')
        }
    }, [socket, user.uid])

    useEffect(() => {
        if (socket !== undefined) {
            socket.on('delete_room', () => {
                userDispatch({ type: 'update_kickedout', payload: 'idle' })
                navigate('/rooms')
            })
        }

        return () => {
            socket?.off('delete_room')
        }
    }, [socket])

    useEffect(() => {
        if (socket !== undefined) {
            socket.on('check_idle', () => {
                if (room.users !== undefined && user.uid !== undefined) {
                    const adminId = Object.keys(room.users)[0]
                    if (adminId === user.uid) {
                        setPopupCheck(true)
                    }
                }
            })
        }

        return () => {
            socket?.off('check_idle')
        }
    }, [socket, room.users, user])

    useEffect(() => {
        if (socket !== undefined) {
            socket.on('update_theme_settings', (res) => {
                userDispatch({ type: 'update_theme_settings', payload: res.settings })
            })
        }

        return () => {
            socket?.off('update_theme_settings')
        }
    }, [socket])

    useEffect(() => {
        if (!showSidebar && windowSize[0] <= 870) {
            setIsSidebarHided(true)
        } else {
            setIsSidebarHided(false)
            setUnreadMsg(0)
        }
    }, [showSidebar, windowSize])

    const sidebarButtonOnClickHandler = () => {
        setShowSidebar(() => !showSidebar)
    }

    const notIdleOnConfirmHandler = () => {
        socket?.emit('not_idle')
    }

    return (
        <>
            <div className={`${styles.sidebar} ${showSidebar ? '' : styles.hide}`}>
                <div className={styles.users}>
                    <UsersSidebar />
                </div>
                <div className={styles.dialog}>
                    <DialogBox isHidden={isSidebarHided} setUnreadMsg={setUnreadMsg} />
                </div>
                <div className={styles.sidebarButton} onClick={sidebarButtonOnClickHandler}>
                    <i className={styles.sidebarIcon}></i>
                    {unreadMsg > 0 && <span className={styles.unread}></span>}
                </div>
            </div>
            <div className={styles.content}>
                <GameScreenFrame />
            </div>
            <NotIdleConfirmPopup active={popupCheck} setActive={setPopupCheck} onConfirm={notIdleOnConfirmHandler} />
        </>

    )
}

export default Game