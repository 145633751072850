import React, { useEffect, useState } from 'react'
import styles from './ThemeSlider.module.css'
import Loading from '../Loading/Loading'
import { useTranslation } from 'react-i18next'
import Slider, { Settings } from 'react-slick'
import PrevArrow from './PrevArrow'
import NextArrow from './NextArrow'
import ThemeSlide from './ThemeSlide'
import { TGameType, TThemeData, TThemeWho } from '../../../utils/library'
import axios from 'axios'

export interface IThemeSlider {
    type: TGameType
    who: TThemeWho
    setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>
}

const ThemeSlider: React.FunctionComponent<IThemeSlider> = (props) => {
    const { i18n } = useTranslation()

    const [loading, setLoading] = useState<boolean>(false)
    const [list, setList] = useState<TThemeData[]>()

    var settings: Settings = {
        swipeToSlide: true,
        slidesToShow: 4,
        infinite: false,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    }

    useEffect(() => {
        if (i18n.resolvedLanguage) {
            setLoading(true)
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/themes?type=${props.type}&who=${props.who}&limit=${12}&paged=${1}&lang=${i18n.resolvedLanguage}`
            }).then((res) => {
                console.log(res)
                if (res.data) {
                    setList(res.data)
                }
                if (!res.data || res.data.length === 0) {
                    props.setIsEmpty(true)
                } else {
                    props.setIsEmpty(false)
                }
            }).catch(() => {
                props.setIsEmpty(true)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [i18n.resolvedLanguage])

    return (
        <div className={styles.container}>
            <Loading loading={loading} classes={styles.loading} />
            {
                !loading && list !== undefined &&
                <Slider {...settings} >
                    {
                        list.map((d, i) => {
                            return (
                                <ThemeSlide key={props.type + '-theme-slide-' + i} data={d} />
                            )
                        })
                    }
                </Slider>
            }
        </div>
    )
}

export default ThemeSlider