import React, { useEffect, useRef, useState } from 'react'
import styles from './ArticlesList.module.css'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { IArticleListItemData, articleListLimit } from '../../utils/library'
import ArticleCard from './ArticleCard'
import ArticlesListPagination from './ArticlesListPagination'
import Loading from '../UI/Loading/Loading'
import Cookies from 'js-cookie'
import TextButton from '../UI/Buttons/TextButton'
import { useNavigate } from 'react-router-dom'

export interface IArticlesList { }

const ArticlesList: React.FunctionComponent<IArticlesList> = (props) => {
    const [t, i18n] = useTranslation()
    const navigate = useNavigate()

    const listRef = useRef<HTMLDivElement>(null)

    // roles
    const [preloaded, setPreloaded] = useState<boolean>(false)
    const [userRoles, setUserRoles] = useState<string[]>()

    const [pagesList, setPagesList] = useState<number[]>([])
    const limit = articleListLimit
    const [paged, setPaged] = useState<number>(1)

    const [list, setList] = useState<IArticleListItemData[]>()

    useEffect(() => {
        if (Cookies) {
            const token = Cookies.get('login_token')
            if (token) {
                axios({
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/users/roles`
                }).then((res) => {
                    setUserRoles(res.data.roles)
                }).catch((e) => {
                    // console.log(e)
                }).finally(() => {
                    setPreloaded(true)
                })
            } else {
                setPreloaded(true)
            }
        }
    }, [Cookies])

    useEffect(() => {
        if (preloaded && limit && paged && i18n.resolvedLanguage) {
            // console.log(userRoles?.includes('administrator'))
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/articles/list?limit=${limit}&paged=${paged}&lang=${i18n.resolvedLanguage}&admin=${userRoles?.includes('administrator')}`
            }).then((res) => {
                if (res.data) {
                    setList(res.data.articles)
                    setPagesList(() => {
                        const max = Math.ceil(res.data.count / limit)
                        const ps: number[] = []
                        for (let i = 0; i < max; i++) {
                            ps.push(i + 1)
                        }
                        return ps
                    })
                    listRef.current?.scrollIntoView({ behavior: 'auto' })
                }
            }).catch((e) => {
                // console.log(e)
            })
        }
    }, [limit, paged, i18n.resolvedLanguage, listRef, preloaded])

    const createButtonOnClickHandler = () => {
        navigate('/article-edit')
    }

    return (
        <div className={styles.container} ref={listRef}>
            {
                userRoles?.includes('administrator') === true &&
                <div className={styles.actions}>
                    <TextButton text={t('Buttons.create_article')} onClick={createButtonOnClickHandler} />
                </div>
            }
            {
                <Loading loading={list === undefined} />
            }
            {
                list !== undefined && list.length > 0 &&
                <div className={styles.list}>
                    {
                        list.map((a, i) => {
                            return (
                                <ArticleCard key={'article_' + i} data={a} isAdmin={userRoles?.includes('administrator') === true} />
                            )
                        })
                    }
                </div>
            }
            {
                list !== undefined && list.length === 0 &&
                <div className={styles.empty}>{t('Warnings.no_articles')}</div>
            }
            {
                pagesList.length > 1 &&
                <ArticlesListPagination paged={paged} setPaged={setPaged} pagesList={pagesList} />
            }
        </div>
    )
}

export default ArticlesList