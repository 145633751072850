import React, { useContext, useEffect, useState } from 'react'
import styles from './AdminWaitingScreen.module.css'
import TextButton from '../../UI/Buttons/TextButton'
import SocketContext from '../../../contexts/Socket/SocketContext'
import GameDetails from './GameDetails'
import UserContext from '../../../contexts/User/UserContext'
import { useTranslation } from 'react-i18next'
import GameSettingsPopup from './GameSettingsPopup'

export interface IAdminWaitingScreen { }

const AdminWaitingScreen: React.FunctionComponent<IAdminWaitingScreen> = () => {
    const { t } = useTranslation()

    const { room } = useContext(UserContext).UserState
    const { socket } = useContext(SocketContext).SocketState
    const [disabled, setDisabled] = useState(true)

    const [buttonText, setButtonText] = useState<string>('')
    const [message, setMessage] = useState<string>('')

    const [popupSettings, setPopupSettings] = useState<boolean>(false)

    useEffect(() => {
        if (socket && room.users !== undefined && room.ready !== undefined) {
            const total = Object.keys(room.users).length - 1
            if (room.ready.length >= total) {
                setDisabled(false)
                setButtonText(t('Buttons.start'))
                if (total === 0) {
                    setMessage('')
                } else {
                    setMessage(t('Messages.waiting_i_start'))
                }
            } else {
                setDisabled(true)
                setButtonText(t('Buttons.waiting'))
                const readyNum = room.ready.length
                setMessage(t('Messages.waiting_players_ready', { ready: readyNum, total }))
            }
        }
    }, [socket, room.users, room.ready])

    const startButtonOnClickHandler = () => {
        socket?.emit('start_game')
    }

    const settingsButtonOnClickHandler = () => {
        setPopupSettings(true)
    }

    return (
        <div className={styles.center}>
            <div className={styles.container}>
                <GameDetails />
                <div className={styles.buttons}>
                    <TextButton classes={styles.startButton} text={buttonText} disabled={disabled} onClick={startButtonOnClickHandler} />
                    {
                        room.themeSettings !== undefined &&
                        <TextButton text={t('Buttons.settings')} onClick={settingsButtonOnClickHandler} />
                    }
                </div>
                <div className={styles.message}>
                    <p>{message}</p>
                </div>
            </div>
            <GameSettingsPopup active={popupSettings} setActive={setPopupSettings} />
        </div>
    )
}

export default AdminWaitingScreen