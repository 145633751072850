import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../../../../contexts/User/UserContext'
import AvatarIcon from '../../../UI/Avatar/AvatarIcon'
import styles from './AvatarSelector.module.css'
import AvatarSelectorPopup from '../../../UI/Popup/AvatarSelectorPopup';

export interface IAvatarSelector { }

const AvatarSelector: React.FunctionComponent<IAvatarSelector> = () => {
    const { user } = useContext(UserContext).UserState

    const [avatar, setAvatar] = useState(user.avatar)
    const [active, setActive] = useState(false)

    useEffect(() => {
        /** Init avatar */
        setAvatar(() => user.avatar)

        // console.log('Init avatar')
    }, [user.avatar])

    /** Open the avatar selector popup */
    const selectedAvatarOnClickHandler = () => {
        setActive(true)
    }

    return (
        <div className={styles.avatar}>
            <div className={styles.avatarInUse} onClick={selectedAvatarOnClickHandler}>
                <AvatarIcon src={avatar} />
                <i className={styles.editIcon}></i>
            </div>
            <AvatarSelectorPopup active={active} setActive={setActive} avatar={avatar} setAvatar={setAvatar} />
        </div>
    )
}

export default AvatarSelector