import React, { useEffect, useState } from 'react'
import MultiInputField from '../../../../UI/Fields/MultiInputField'
import { useTranslation } from 'react-i18next'
import { quizAnswerMax, quizAnswerMin, quizOptionMaxLength, quizOptionMaxLengthEN } from '../../../../../utils/library'

export interface IQuestionAnswerMultiInputField {
    answer: string[]
    setAnswer: React.Dispatch<React.SetStateAction<string[]>>
    inline?: boolean
    setError: React.Dispatch<React.SetStateAction<string>>
    setPopupError: React.Dispatch<React.SetStateAction<boolean>>
}

const QuestionAnswerMultiInputField: React.FunctionComponent<IQuestionAnswerMultiInputField> = (props) => {
    const { t, i18n } = useTranslation()

    const label = t('Labels.answer') + ` (${quizAnswerMin === quizAnswerMax ? `${quizAnswerMax}` : `${quizAnswerMin} - ${quizAnswerMax}`})`
    const [maxLength, setMaxLength] = useState<number>(quizOptionMaxLength)

    useEffect(() => {
        if (i18n.resolvedLanguage === 'en') {
            setMaxLength(quizOptionMaxLengthEN)
        } else {
            setMaxLength(quizOptionMaxLength)
        }
    }, [i18n.resolvedLanguage])

    const inputInvalidAddHandler = () => {
        props.setError(t('Warnings.multiinput_invalid_add', { label: t('Labels.answer'), max: quizAnswerMax }))
        props.setPopupError(true)
    }

    return (
        <MultiInputField id='question-answer' label={label} values={props.answer} setValues={props.setAnswer} inline={props.inline} onInvalidAdd={inputInvalidAddHandler} max={quizAnswerMax} maxLength={maxLength} />
    )
}

export default QuestionAnswerMultiInputField