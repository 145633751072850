import avatarList from './avatar.json'

export type TAvatarList = { id: string, src: string }[]

export default class Avatar {
    public static instance: Avatar
    private avatarPath: string

    constructor() {
        Avatar.instance = this

        this.avatarPath = process.env.PUBLIC_URL + '/images/avatars'
    }

    public GetAvatarList = () => {
        return avatarList
    }

    public GetAvatarPath = (id: string) => {
        let avatarSrc = avatarList.find((avatar) => avatar.id === id)?.src
        return avatarSrc !== undefined ? this.avatarPath + '/' + avatarSrc : undefined
    }

    public GetDefaultAvatarPath = () => {
        return this.avatarPath + '/pawn.png'
    }
}