import React, { useEffect, useState } from 'react'
import styles from './CorrectMessage.module.css'
import { TAnswerResData, TQues } from '../../../../utils/library'
import { useTranslation } from 'react-i18next'

export interface ICorrectMessage {
    ques: TQues
    answerResData: TAnswerResData
    scoreChange: { old: number, new: number }
}

const CorrectMessage: React.FunctionComponent<ICorrectMessage> = (props) => {
    const { t } = useTranslation()

    const [message, setMessage] = useState<string>('')
    const [scoreChangeText, setScoreChangeText] = useState<string>('')

    useEffect(() => {
        if (props.answerResData.tooLate === true) {
            setMessage(t('Messages.answer_toolate'))
        } else {
            if (props.ques.type === 'select') {
                if (props.answerResData.timeout) {
                    setMessage(t('Messages.answer_timeout'))
                } else if (!props.answerResData.timeout && props.answerResData.res.correct) {
                    setMessage(t('Messages.answer_correct'))
                } else {
                    setMessage(t('Messages.answer_incorrect'))
                }
            } else {
                if (props.answerResData.timeout) {
                    setMessage(t('Messages.answer_timeout'))
                } else if (!props.answerResData.timeout && props.answerResData.res.correct) {
                    setMessage(t('Messages.answer_correct'))
                }
            }
        }
    }, [props.ques, props.answerResData])

    useEffect(() => {
        // console.log(props.scoreChange)
        if (props.scoreChange.old <= props.scoreChange.new) {
            setScoreChangeText('+' + (props.scoreChange.new - props.scoreChange.old).toFixed(2))
        } else {
            setScoreChangeText((props.scoreChange.new - props.scoreChange.old).toFixed(2))
        }
    }, [props.scoreChange])

    if (props.ques.type === 'text' && !props.answerResData.timeout && !props.answerResData.res.correct && props.answerResData.tooLate !== true) {
        return <></>
    }

    return (
        <div className={`${styles.container} ${!props.answerResData.timeout && props.answerResData.res.correct ? styles.correct : styles.incorrect}`}>
            <div className={styles.inner}>
                <div className={styles.message}>
                    <span className={styles.text}>{message}</span>
                </div>
                <span className={`${styles.scoreChange}`}>{scoreChangeText}</span>
            </div>
        </div>
    )
}

export default CorrectMessage