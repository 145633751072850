import React, { Fragment, useEffect, useRef, useState } from 'react'
import styles from './AnswerInput.module.css'
import { TAnswerResData } from '../../../../../utils/library'
import { useTranslation } from 'react-i18next'

export interface IAnswerInputProps {
    disabled: boolean
    answerResData: TAnswerResData | undefined
    answer: string
    setAnswer: React.Dispatch<React.SetStateAction<string>>
    submitAnswer: (ans: string) => any
}

const AnswerInput: React.FunctionComponent<IAnswerInputProps> = (props) => {
    const { t } = useTranslation()

    const inputRef = useRef<HTMLInputElement>(null)
    const [correctClass, setCorrectClass] = useState<string>('')
    const [placeholder, setPlaceholder] = useState<string>('')
    const [showAnswer, setShowAnswer] = useState<boolean>(false)

    useEffect(() => {
        props.setAnswer('')
    }, [])

    useEffect(() => {
        if (props.answerResData !== undefined) {
            if (!props.answerResData.timeout) {
                if (props.answerResData.res.correct) {
                    setCorrectClass(styles.correct)
                } else {
                    setCorrectClass(styles.incorrect)
                    setPlaceholder(t('Messages.answer_retry'))
                }
            } else {
                setCorrectClass(styles.timeout)
                setShowAnswer(true)
            }
        } else {
            setCorrectClass('')
            setPlaceholder(t('Messages.answer_try'))
            setShowAnswer(false)
        }
    }, [props.answerResData])


    useEffect(() => {
        if (inputRef.current) {
            if ((props.answerResData === undefined || !props.answerResData.res.correct) && !props.disabled) {
                inputRef.current.focus()
                props.setAnswer('')
            }
        }
    }, [inputRef, props.answerResData, props.disabled])

    const answerInputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setAnswer(() => e.target.value)
        if (correctClass === styles.incorrect) {
            setCorrectClass('')
            setPlaceholder(t('Messages.answer_try'))
        }
    }

    const answerInputOnKeyDownHandler = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            props.submitAnswer(props.answer)
        }
    }

    const buttonOnClickHandler = () => {
        props.submitAnswer(props.answer)
    }

    return (
        <>
            <div className={styles.field}>
                <div className={styles.inputField}>
                    <i className={`${styles.icon} ${correctClass}`}></i>
                    <input id='answer-input' className={`${styles.input} ${correctClass}`} placeholder={placeholder} ref={inputRef} type={'text'} value={props.answer} disabled={props.disabled} onChange={answerInputOnChangeHandler} onKeyDown={answerInputOnKeyDownHandler} autoComplete='off' />
                </div>
                <button onClick={buttonOnClickHandler} disabled={props.disabled}>{t('Buttons.submit_answer')}</button>
            </div>
            {
                showAnswer && props.answerResData !== undefined &&
                <div className={styles.answerField}>
                    <span className={styles.answerLabel}>{t('Labels.answer')}</span>
                    <span className={styles.answers}>
                        {
                            // props.answerResData.res.answer?.map((a, i) => {
                            //     return (<span key={'ans_' + i} className={styles.answer}>{a}</span>)
                            // })
                            props.answerResData.res.answer?.map((a, i) => {
                                const answerLength = props.answerResData !== undefined && props.answerResData.res.answer !== undefined ? props.answerResData.res.answer.length : 0
                                return (
                                    <Fragment key={'ans_' + i}>
                                        {a}
                                        {i < answerLength - 1 && <i className={styles.sep}> / </i>}
                                    </Fragment>
                                )
                            })
                        }
                    </span>
                </div>
            }
        </>
    )
}

export default AnswerInput