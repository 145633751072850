import React, { useState } from 'react'
import Popup from './Popup'
import styles from './SharingPopup.module.css'
import TextButton from '../Buttons/TextButton'
import { useTranslation } from 'react-i18next'

export interface ISharingPopupProps {
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
}

const SharingPopup: React.FunctionComponent<ISharingPopupProps> = (props) => {
    const { t } = useTranslation()

    const [active, setActive] = [props.active, props.setActive]

    const [copied, setCopied] = useState<boolean>(false)

    const copyButtonOnClickHandler = () => {
        navigator.clipboard.writeText(window.location.href)
        setCopied(true)
    }

    return (
        <Popup title={t('Titles.sharing')} active={active} setActive={setActive} closeButton={true}>
            <div className={styles.container}>
                <div className={styles.messageContainer}>
                    <p className={styles.message}>{t('Messages.sharing_link')}</p>
                </div>
                <div className={styles.urlContainer}>
                    <input className={styles.input} readOnly value={window.location.href} />
                </div>
                <div className={styles.buttons}>
                    {!copied && <TextButton text={t('Buttons.copy_link')} onClick={copyButtonOnClickHandler} />}
                    {copied && <TextButton text={t('Buttons.copied_link')} disabled={true} />}
                </div>
            </div>
        </Popup>
    )
}

export default SharingPopup