import React, { useEffect, useRef } from 'react'
import styles from './MobileMenu.module.css'
import { Link } from 'react-router-dom'
import IconButton from '../../UI/Buttons/IconButton'

export interface IMobileMenu {
    menu: { text: string, link: string }[]
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileMenu: React.FunctionComponent<IMobileMenu> = (props) => {
    const menuRef = useRef<HTMLDivElement>(null)

    // close popup menu when click outside
    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(e.target as HTMLElement)) {
                props.setShow(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [menuRef])

    return (
        <div className={`${styles.container} ${props.show ? styles.show : ''}`}>
            <div className={styles.bg}></div>
            <div ref={menuRef} className={styles.menuWrap}>
                <div className={styles.menu}>
                    <div className={styles.close}>
                        <IconButton type='close' onClick={() => props.setShow(false)} />
                    </div>
                    {
                        props.menu.map((m, i) => {
                            return (
                                <Link key={'m-menu-item-' + i} className={styles.menuItem} to={m.link} onClick={() => props.setShow(false)} >{m.text}</Link>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default MobileMenu