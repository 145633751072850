import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SocketContext from '../contexts/Socket/SocketContext'
import styles from './SingleRoomPage.module.css'
import UserContext from '../contexts/User/UserContext'
import EnterRoomForm from '../components/Forms/EnterRoomForm'
import Game from '../components/GameScreen/Game'
import PageTitle from '../components/Widgets/Title/PageTitle'
import { useTranslation } from 'react-i18next'

export type TUserInRoomValidation = {
    userJoining: boolean
    isRoomExist: boolean
    isPasswordProtected: boolean
}

export interface ISingleRoomPageProps { }

const SingleRoomPage: React.FunctionComponent = () => {
    const { t } = useTranslation()

    const userContext = useContext(UserContext)
    const socketContext = useContext(SocketContext)

    const { user, room } = userContext.UserState
    const { socket } = socketContext.SocketState

    const [loading, setLoading] = useState(true)
    const [joined, setJoined] = useState(false)
    const [isPasswordProtected, setIsPasswordProtected] = useState<boolean>(false)

    const navigate = useNavigate()

    const params = useParams()
    const roomId = params.roomId || ''

    const [validated, setValidated] = useState(false)
    useEffect(() => {
        if (!validated && socket !== undefined && room.roomId !== undefined && roomId && user.uid !== undefined) {
            socket?.emit('validate_user_in_room', { uid: user.uid, roomId }, (validate: TUserInRoomValidation) => {
                if (!validate.isRoomExist) {
                    // Cannot join room
                    navigate('/error', { replace: true })
                } else {
                    if (validate.userJoining) {
                        if (room.roomId === '') {
                            // Already in room
                            navigate('/error', { replace: true })
                        } else {
                            // joining now
                            setJoined(true)
                        }
                    } else {
                        // Show a joining form
                        setJoined(false)
                        setIsPasswordProtected(validate.isPasswordProtected)
                    }
                }

                setLoading(false)
            })

            setValidated(true)
        }
    }, [socket, navigate, room.roomId, roomId, user.uid])

    if (loading) return (
        <div>loading...</div>
    )

    if (!joined) return (
        <div className={styles.form}>
            <PageTitle title={t('Titles.join_room')} backPath='/rooms' />
            <EnterRoomForm roomId={roomId} setJoined={setJoined} isPasswordProtected={isPasswordProtected} />
        </div>
    )

    return (
        <div className={styles.container}>
            <div className={styles.game}>
                <Game />
            </div>
        </div>
    )
}

export default SingleRoomPage