import React, { useEffect, useRef, useState } from 'react'
import styles from './DialogBox.module.css'
import DialogForm from './DialogForm'
import MessageCard from './MessageCard'
import Scrollbars from 'react-custom-scrollbars-2'
import { IOneDialogRecord } from '../../../utils/library'

export interface IDialogBox {
    isHidden: boolean
    setUnreadMsg: React.Dispatch<React.SetStateAction<number>>
}

const DialogBox: React.FunctionComponent<IDialogBox> = (props) => {
    const [dialog, setDialog] = useState<IOneDialogRecord[]>([])
    const [dialogLength, setDialogLength] = useState<number>(0)

    const [scrollViewHeight, setScrollViewHeight] = useState<number>(0)
    useEffect(() => {

        if (scrollbarRef.current) {
            // console.log('saved h: ' + scrollViewHeight)
            // console.log('scroll h: ' + scrollbarRef.current.getScrollHeight())
            // console.log('top: ' + scrollbarRef.current.getScrollTop())
            // console.log('client h: ' + scrollbarRef.current.getClientHeight())
            // console.log(`diff: ${scrollViewHeight - (scrollbarRef.current.getScrollTop() + scrollbarRef.current.getClientHeight())}`)

            if (scrollViewHeight - (scrollbarRef.current.getScrollTop() + scrollbarRef.current.getClientHeight()) <= 16) {
                scrollbarRef.current?.scrollToBottom()
            }

            setScrollViewHeight(scrollbarRef.current.getScrollHeight())
        }
    }, [dialog])

    useEffect(() => {
        if (dialog.length > dialogLength) {
            setDialogLength(dialog.length)
            if (props.isHidden) {
                props.setUnreadMsg(unreadMsg => unreadMsg + 1)
            }
        }
    }, [dialog, dialogLength, props.isHidden])


    const scrollbarRef = useRef<Scrollbars>(null)
    const shadowTop = useRef<HTMLDivElement>(null)
    const shadowBottom = useRef<HTMLDivElement>(null)
    const scrollView = useRef<HTMLDivElement>(null)

    const scrollbarHandleUpdate = (values: any) => {
        const { scrollTop, scrollHeight, clientHeight } = values
        const shadowTopOpacity = 1 / 20 * Math.min(scrollTop, 20)
        const bottomScrollTop = scrollHeight - clientHeight
        const shadowBottomOpacity = 1 / 20 * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20))

        shadowTop.current?.style.setProperty('opacity', shadowTopOpacity.toString())
        shadowBottom.current?.style.setProperty('opacity', shadowBottomOpacity.toString())

        // console.log(`diff: ${scrollHeight - scrollTop + clientHeight}`)
    }

    return (
        <div className={styles.box}>
            <div className={styles.dialogView}>
                <div className={styles.scrollbarContainer}>
                    <Scrollbars
                        ref={scrollbarRef}
                        style={{ height: '100%' }}
                        onUpdate={scrollbarHandleUpdate}
                    >
                        <div ref={scrollView} className={styles.viewContainer}>
                            {dialog.map((record) => {
                                const key = record.roomUId + '_' + record.createdAt
                                return <MessageCard key={key} roomUId={record.roomUId} username={record.username} message={record.message} createdAt={record.createdAt} />
                            })}
                        </div>
                    </Scrollbars>
                    <div className={styles.scrollbarShadowTop} ref={shadowTop} />
                    <div className={styles.scrollbarShadowBottom} ref={shadowBottom} />
                </div>
            </div>
            <div className={styles.dialogForm}>
                <DialogForm setDialog={setDialog} />
            </div>
        </div>
    )
}

export default DialogBox