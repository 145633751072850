import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import SocketContextComponent from './contexts/Socket/SocketContextComponent'
import './assets/css/styles.css'
import UserContextComponent from './contexts/User/UserContextComponent'
import { Helmet } from 'react-helmet'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <UserContextComponent>
    <SocketContextComponent>
      {
        process.env.REACT_APP_ENV !== 'development' &&
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-C05MTB2M7L"></script>
          <script>
            {
              `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-C05MTB2M7L');`
            }
          </script>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3585956076799146" crossOrigin="anonymous"></script>
        </Helmet>
      }
      <App />
    </SocketContextComponent>
  </UserContextComponent>
)

reportWebVitals();
