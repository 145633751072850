import React, { useEffect, useState } from 'react'
import styles from './ThemeCard.module.css'
import Theme from '../../../utils/theme'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TThemeData } from '../../../utils/library'

export interface IThemeCard {
    themeData: TThemeData
    selectedThemeData?: TThemeData
    setSelectedThemeData?: React.Dispatch<React.SetStateAction<TThemeData | undefined>>
    isListItem?: boolean
    setThemeIdToDelete?: React.Dispatch<React.SetStateAction<string | undefined>>
    setPopupDelete?: React.Dispatch<React.SetStateAction<boolean>>
}

const ThemeCard: React.FunctionComponent<IThemeCard> = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [data, setData] = useState<{ label: string, value: string }[]>([])

    useEffect(() => {
        if (props.themeData !== undefined) {
            const d: { label: string, value: string }[] = []

            if (props.themeData.count) {
                d.push({ label: t('ThemeDataLabels.count'), value: props.themeData.count.toString() })
            }

            if (props.themeData.questionTypes.length > 0) {
                const translated = props.themeData.questionTypes.map((v) => t('QuestionTypes.' + v))
                d.push({ label: t('ThemeDataLabels.questionTypes'), value: translated.join(', ') })
            }

            setData(d)
        }
    }, [props.themeData])

    const themeHelper = new Theme()

    const imageSrc = themeHelper.GetImage('quiz', props.themeData.themeType)

    const cardOnClickHandler = () => {
        if (props.setSelectedThemeData !== undefined) {
            props.setSelectedThemeData(props.themeData)
        }
    }

    const editIconOnClickHandler = () => {
        navigate(`/theme/${props.themeData._id}`)
    }

    const deleteIconOnClickHandler = async () => {
        if (props.setPopupDelete !== undefined && props.setThemeIdToDelete !== undefined) {
            props.setThemeIdToDelete(props.themeData._id)
            props.setPopupDelete(true)
        }

    }

    return (
        <div className={styles.cardContainer}>
            <div className={`${styles.card} ${props.isListItem === true ? styles.listItem : ''} ${(props.themeData._id === props.selectedThemeData?._id) ? styles.selectedListItem : ''}`} onClick={cardOnClickHandler}>
                <div className={styles.content}>
                    <div className={styles.image}>
                        <img src={imageSrc} alt='theme' />
                    </div>
                    <div className={styles.infos}>
                        <div className={styles.name}>
                            <h5>{props.themeData.name}</h5>
                        </div>
                        {
                            data.length > 0 &&
                            <div className={styles.metas}>{
                                data.map((v, k) => {
                                    return (
                                        <span key={`meta_${k}`} className={styles.meta}>{v.label + ' : ' + v.value}</span>
                                    )
                                })
                            }
                            </div>
                        }
                    </div>
                    <div className={styles.footer}>
                        {
                            props.themeData.official === true &&
                            <span className={styles.official}>{t('Labels.official')}</span>
                        }
                        {
                            props.themeData.editable === true && props.themeData.visibility === 'public' &&
                            <span className={styles.public}>{t('Labels.public')}</span>
                        }
                    </div>
                </div>
            </div>
            {
                props.isListItem === true && props.themeData.editable === true &&
                <div className={styles.icons}>
                    <i className={styles.editIcon} onClick={editIconOnClickHandler}></i>
                    <i className={styles.deleteIcon} onClick={deleteIconOnClickHandler}></i>
                </div>
            }
        </div>
    )
}

export default ThemeCard