import React from 'react'
import styles from './ArticleCard.module.css'
import { IArticleListItemData } from '../../utils/library'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export interface IArticleCard {
    data: IArticleListItemData
    isAdmin: boolean
}

const ArticleCard: React.FunctionComponent<IArticleCard> = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const dateToText = (date: Date | string) => {
        let d = new Date(date)
        return moment(d).format('DD/MM/YYYY')
    }

    const editIconOnClickHandler = () => {
        navigate('/article-edit/' + props.data._id)
    }

    return (
        <div className={styles.cardContainer}>
            <Link to={`/articles/${props.data.slug}`} className={styles.card}>
                <div className={styles.container}>
                    <div className={styles.img}>
                        <img src={props.data.featuredImg} alt='image' />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <h4>{props.data.title}</h4>
                        </div>
                        <div className={styles.excerpt}>
                            <p>{props.data.excerpt}</p>
                        </div>
                        <div className={styles.footer}>
                            <div className={`${styles.meta} ${styles.gameType}`}>
                                <i className={styles.playIcon}></i>
                                <span>{t('GameTypes.' + props.data.gameType)}</span>
                            </div>
                            <div className={`${styles.meta} ${styles.date}`}>
                                <span>{dateToText(props.data.publishDate)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
            {
                props.isAdmin &&
                <div className={styles.icons}>
                    <i className={styles.editIcon} onClick={editIconOnClickHandler}></i>
                </div>
            }
        </div>
    )
}

export default ArticleCard