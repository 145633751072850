import React from 'react'
import SelectField from '../../../UI/Fields/SelectField'
import { useTranslation } from 'react-i18next'
import { TThemeVisibility, themeVisibilities, themeVisibilityOptions } from '../../../../utils/library'

export interface IThemeVisibilitySelectField {
    themeVisibility: TThemeVisibility,
    setThemeVisibility: React.Dispatch<React.SetStateAction<TThemeVisibility>>
    inline?: boolean
    showLabel: boolean
    inFilter?: boolean
}

const ThemeVisibilitySelectField: React.FunctionComponent<IThemeVisibilitySelectField> = (props) => {
    const { t } = useTranslation()

    const options = themeVisibilityOptions

    const themeVisibilityOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value as TThemeVisibility
        if (themeVisibilities.includes(val)) {
            props.setThemeVisibility(val)
        }
    }

    return (
        <SelectField inline={props.inline} id='theme-visibility-select' options={options} onChange={themeVisibilityOnChangeHandler} value={props.themeVisibility} label={props.showLabel ? t('Labels.theme_visibility') : undefined} translate={true} />
    )
}

export default ThemeVisibilitySelectField