import React, { useEffect, useRef, useState } from 'react'
import styles from './QuestionList.module.css'
import { useTranslation } from 'react-i18next'
import Scrollbars from 'react-custom-scrollbars-2'
import { TAnswerType, TQuizQuestion, TUseOptionsLib, defaultAnswerType, defaultUseOptionsLib } from '../../../../utils/library'
import QuestionListItem from './QuestionListItem'
import QuestionEditPopup from './QuestionEditPopup'
import InputField from '../../../UI/Fields/InputField'
import QuestionListDropdownMenu from './QuestionListDropdownMenu'
import Loading from '../../../UI/Loading/Loading'

export interface IQuestionList {
    loadingQs: boolean
    questions: TQuizQuestion[] | undefined
    setQuestions: React.Dispatch<React.SetStateAction<TQuizQuestion[] | undefined>>
    setError: React.Dispatch<React.SetStateAction<string>>
    setPopupError: React.Dispatch<React.SetStateAction<boolean>>
    userRoles: string[] | undefined
    optionsLibOptions: { label: string, value: string }[]
    setOptionsLibOptions: React.Dispatch<React.SetStateAction<{ label: string, value: string }[]>>
}

const QuestionList: React.FunctionComponent<IQuestionList> = (props) => {
    const { t } = useTranslation()

    const [maxLength, setMaxLength] = useState<number | undefined>(30)

    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>()
    const [popupEdit, setPopupEdit] = useState<boolean>(false)
    const [lastAnswerType, setLastAnswerType] = useState<TAnswerType>(defaultAnswerType)
    const [lastUseOptionsLib, setLastUseOptionsLib] = useState<TUseOptionsLib>(defaultUseOptionsLib)
    const [lastOptionsLibId, setLastOptionsLibId] = useState<string>('')

    const scrollbarRef = useRef<Scrollbars>(null)
    const [adding, setAdding] = useState<boolean>(false)

    // Filter questions
    const [filterText, setFilterText] = useState<string>('')
    const [filteredQuestionIndexes, setFilteredQuestionIndexes] = useState<number[]>()

    useEffect(() => {
        if (props.userRoles !== undefined && props.userRoles.includes('administrator')) {
            setMaxLength(undefined)
        } else {
            setMaxLength(30)
        }
    }, [props.userRoles])

    useEffect(() => {
        if (!popupEdit && adding && scrollbarRef.current !== null) {
            setAdding(false)
            scrollbarRef.current.scrollToBottom()
        }
    }, [adding, popupEdit])

    useEffect(() => {
        setFilteredQuestionIndexes(() => {
            if (props.questions !== undefined && filterText) {
                return props.questions.map((q, i) => i).filter((i) =>
                    props.questions !== undefined &&
                    (props.questions[i].question.toLowerCase().includes(filterText.toLowerCase()) ||
                        props.questions[i].answer.find((a) => a.toLowerCase().includes(filterText.toLowerCase()))))
            }
            return undefined
        })
    }, [props.questions, filterText])

    const filterQuestionInputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(() => e.target.value)
    }

    const addButtonOnClickHandler = () => {
        setSelectedQuestionIndex(undefined)
        setPopupEdit(true)
        setAdding(true)
    }

    const onDeleteQuestion = (index: number) => {
        props.setQuestions(() => props.questions?.filter((q, i) => i !== index))
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.wrap}>
                    <span>{`${t('Labels.question_list')} (${props.questions !== undefined ? props.questions.length : 0}${maxLength !== undefined ? '/' + maxLength : ''})`}</span>
                    <InputField id='question_filter_input' classes={styles.questionFilterInput} value={filterText} onChange={filterQuestionInputOnChangeHandler} placeholder={t('Placeholders.search_questions')} />
                </div>
                {
                    props.userRoles !== undefined && props.userRoles.includes('administrator') &&
                    <QuestionListDropdownMenu questions={props.questions} setQuestions={props.setQuestions} optionsLibOptions={props.optionsLibOptions} setOptionsLibOptions={props.setOptionsLibOptions} setError={props.setError} setPopupError={props.setPopupError} />
                }
            </div>
            <div className={styles.scrollbarContainer}>
                <Loading loading={props.loadingQs} classes={styles.loading} />
                {
                    !props.loadingQs &&
                    <Scrollbars
                        ref={scrollbarRef}
                        style={{ height: '100%', maxHeight: '250px', minHeight: '250px' }}
                    >
                        <div className={styles.list}>
                            {
                                props.questions !== undefined && props.questions.map((q, i) => {
                                    if (filteredQuestionIndexes === undefined || (filteredQuestionIndexes.length !== 0 && filteredQuestionIndexes.includes(i))) {
                                        return (
                                            <QuestionListItem key={'qustionlist_item_' + i} index={i} question={q} setSelectedQuestionIndex={setSelectedQuestionIndex} setPopupEdit={setPopupEdit} onDelete={onDeleteQuestion} />
                                        )
                                    }
                                })
                            }
                            {
                                (maxLength === undefined || props.questions === undefined || props.questions.length < maxLength) &&
                                <button className={styles.addButton} onClick={addButtonOnClickHandler} >+</button>
                            }
                        </div>
                    </Scrollbars>
                }
            </div>
            {
                popupEdit &&
                <QuestionEditPopup
                    index={selectedQuestionIndex}
                    questions={props.questions}
                    setQuestions={props.setQuestions}
                    setError={props.setError}
                    setPopupError={props.setPopupError}
                    active={popupEdit}
                    setActive={setPopupEdit}
                    lastAnswerType={lastAnswerType}
                    setLastAnswerType={setLastAnswerType}
                    lastUseOptionsLib={lastUseOptionsLib}
                    setLastUseOptionsLib={setLastUseOptionsLib}
                    lastOptionsLibId={lastOptionsLibId}
                    setLastOptionsLibId={setLastOptionsLibId}
                    optionsLibOptions={props.optionsLibOptions}
                    setOptionsLibOptions={props.setOptionsLibOptions}
                />
            }
        </div>
    )
}

export default QuestionList