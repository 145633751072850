import React from 'react'
import styles from './PageTitle.module.css'
import Title from './Title'
import IconButton from '../../UI/Buttons/IconButton'
import { useNavigate } from 'react-router-dom'

export interface IPageTitle {
    title: string
    backPath?: string
}

const PageTitle: React.FunctionComponent<IPageTitle> = (props) => {
    const navigate = useNavigate()

    const backButtonOnClickHandler = () => {
        if (props.backPath !== undefined) {
            navigate(props.backPath)
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                {
                    props.backPath !== undefined &&
                    <IconButton classes={styles.back} type='back' onClick={backButtonOnClickHandler} />
                }
            </div>
            <Title title={props.title} />
            <div className={styles.right}></div>
        </div>
    )
}

export default PageTitle