import React from 'react'
import styles from './PrivacyPage.module.css'
import PageTitle from '../components/Widgets/Title/PageTitle'

const PrivacyPage: React.FunctionComponent = () => {
    return (
        <div className={styles.container}>
            <div className={styles.wrap}>
                <PageTitle title='Privacy Policy' backPath='/login' />
                <div className={styles.content}>
                    <p>1. What data you disclose to us</p>
                    <p>We collect data through a third-party account. If you do not authorize such data collection, please do not log in with a social network.</p>
                    <p>Data we may collect when you use our service anonymously or when you are linked to a third party log-in:</p>
                    <ul>
                        <li>Data about your location, device type, operating system and platform, page load time, network, unique browser id, social network unique id, demographics, a chosen nickname, room number you have played, the reference origin, and the IP address.</li>
                        <li>Text and words interactions in-game</li>
                        <li>Custom Themes</li>
                    </ul>
                    <p>2. Why we collect your data</p>
                    <ul>
                        <li>To improve our services.</li>
                        <li>To ensure you comply with our terms.</li>
                    </ul>
                    <p>3. legal bases</p>
                    <p>We consider the following legal bases for data processing:</p>
                    <ul>
                        <li>When the use of your data is required to execute our obligations under a contract with you. (e.g., to comply with our Service Terms).</li>
                        <li>When the use of your data is necessary for our legitimate interests or the legitimate interests of others.</li>
                        <li>When the use of your information is needed to comply with legal obligations or exercise rights.</li>
                    </ul>
                    <p>4. How we process data</p>
                    <p>The data we collect from you may be transferred and stored outside you country, such as the USA / the HK. This transfer is required to host the Services, provide other supporting services, and allow you to use our Services deliberately.</p>
                    <p>We use third parties to help us manage your information and services, customer service providers to host the game and database, and marketing companies to manage the advertising that appears to you.</p>
                    <p>5. Data for Advertising</p>
                    <p>We use third-party advertising companies to serve advertisements and provide collected data to help implement ads when you visit or use our Services. We also use analytical web tools such as Google Analytics to interpret your use of our Services.</p>
                    <p>6. Use of Cookies</p>
                    <p>We may use cookies to improve the user experience. Your web browser is responsible for controlling and sharing your information through cookies. These cookies enable us to hold session information as you navigate our Services, improve your experience, and track and analyze usage and other statistical information.</p>
                    <p>Here are some examples of ways we can use cookies when is strictly necessary:</p>
                    <ul>
                        <li>Keep you logged while you are visiting.</li>
                    </ul>
                    <p>Users can change their web browser settings to refuse or warn them when Cookies are being used or sent. If done so, you may observe some malfunction on some parts of the website.</p>
                    <p>Do Not Track ("DNT") is a privacy preference that users can set in specific web browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers. </p>
                    <p>7. Storage</p>
                    <p>We will keep the data as long as it is relevant and valuable for its originally collected purpose and, otherwise, when required by law.</p>
                    <p>8. Data protection</p>
                    <p>We strive to protect your data and privacy, but no security method or firewall can be guaranteed to protect information from hackers or human error. Consequently, we expressly disclaim responsibility or liability for the loss, theft, destruction, or inadvertent disclosure of your data.</p>
                    <p>9. Legal Disclosure</p>
                    <p>We may transfer and disclose information, including your Data, Usage Information, and Device Identifier, to third parties to comply with certain legal obligations when we believe in good faith that the law requires it. It may include: the request of governmental authorities conducting an investigation; to verify or enforce applicable terms or other applicable policies; to respond to an emergency; or, otherwise, to protect the rights, property, safety, or security of quzz.io and our customers and others.</p>
                    <p>10. Request data deletion</p>
                    <p>Please contact us at quzzio221@gmail.com when you have questions about these privacy policies or request a copy or deletion of your data. Your request and choices may be limited in some instances. For example, fulfilling your request would reveal information about another person or ask to delete information that we are permitted by law or have compelling legitimate interests to keep.</p>
                    <p>11. Other provisions</p>
                    <p>Our services are not explicitly aimed at children. If you are a user under 13 and using a third-party account, your parents must allow you to use your name and, if applicable, use our services.</p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPage