import React, { useMemo, useRef } from 'react'
import styles from './RichEditorField.module.css'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useTranslation } from 'react-i18next'

export interface IRichEditorField {
    label?: string
    classes?: string
    inline?: boolean
    value: string
    setValue: React.Dispatch<React.SetStateAction<string>>
    shortcodes?: string[]
}

// Quill.register('modules/blotFormatter', BlotFormatter)

const RichEditorField: React.FunctionComponent<IRichEditorField> = (props) => {
    const { t } = useTranslation()
    const editorRef = useRef<ReactQuill>(null)

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
                [{ 'align': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],
                ['link', 'image', 'video'],
                [{ 'color': [] }, { 'background': [] }],
                ['clean']
            ]
        },
        // blotFormatter: {
        //     overlay: {
        //         style: {
        //             border: '2px solid #f6baba',
        //         }
        //     }
        // }
    }), [])

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
        'align',
        'list', 'bullet', 'indent', 'script',
        'image', 'video', 'color', 'background',
        'link'
    ]

    return (
        <div className={`${styles.field} ${props.inline === true ? styles.inline : ''} ${props.classes !== undefined ? props.classes : ''}`}>
            {
                props.label !== undefined &&
                <label className={styles.label}>{props.label}</label>
            }
            <ReactQuill
                ref={editorRef}
                theme='snow'
                value={props.value}
                onChange={props.setValue}
                modules={modules}
                formats={formats}
            />
            {
                props.shortcodes !== undefined && props.shortcodes.length > 0 &&
                <div className={styles.shortcodes}>
                    <ol>
                        {
                            props.shortcodes.map((s, i) => {
                                return (
                                    <li key={'shortcode-' + i} className={styles.shortcode}>{`{{${s}}} : ${t('Shortcodes.' + s)}`}</li>
                                )
                            })
                        }
                    </ol>
                </div>
            }
        </div>
    )
}

export default RichEditorField