import React from 'react'
import { useTranslation } from 'react-i18next'
import InputField from '../../../UI/Fields/InputField'

export interface IArticleTitleInputField {
    articleTitle: string
    setArticleTitle: React.Dispatch<React.SetStateAction<string>>
    inline?: boolean
}

const ArticleTitleInputField: React.FunctionComponent<IArticleTitleInputField> = (props) => {
    const { t } = useTranslation()

    const articleNameInputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setArticleTitle(e.target.value)
    }

    return (
        <InputField id='article-title' label={t('Labels.title')} inline28={props.inline} value={props.articleTitle} onChange={articleNameInputOnChangeHandler} />
    )
}

export default ArticleTitleInputField