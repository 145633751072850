import React, { useContext } from 'react'
import styles from './Header.module.css'
import { useNavigate } from 'react-router-dom'
import AvatarIcon from '../../UI/Avatar/AvatarIcon'
import UserContext from '../../../contexts/User/UserContext'

export interface IHeader {
    size?: 'large' | 'medium'
}

const Header: React.FunctionComponent<IHeader> = (props) => {
    const navigate = useNavigate()

    const { user } = useContext(UserContext).UserState

    const LogoOnClickHandler = () => {
        navigate('/')
    }

    const AvatarOnClickHandler = () => {
        navigate('/login')
    }

    let headerStyle = ''

    switch (props.size) {
        case 'medium':
            headerStyle = styles.medium
            break

        default:
            break
    }

    return (
        <div className={`${styles.container} ${headerStyle}`}>
            <div className={styles.left}></div>
            <div className={styles.center}>
                <div className={styles.logo} onClick={LogoOnClickHandler}>
                    <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt='logo' />
                </div>
            </div>
            <div className={styles.right}>
                {
                    props.size === 'medium' &&
                    <>
                        <div className={styles.infos}>
                            <span className={styles.username}>{user.username}</span>
                        </div>
                        <div className={styles.avatar} onClick={AvatarOnClickHandler}>
                            <AvatarIcon src={user.avatar} size='sm' />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Header