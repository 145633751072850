import React from 'react'
import { useTranslation } from 'react-i18next'
import TextButtonField from '../../../../UI/Fields/TextButtonField'

export interface IQuizlibImportButtonField {
    onImport: () => any
}

const QuizlibImportButtonField: React.FunctionComponent<IQuizlibImportButtonField> = (props) => {
    const { t } = useTranslation()

    return (
        <TextButtonField id='quizlib-edit-import' inline={true} onClick={props.onImport} label={t('Labels.import_answers')} buttonText={t('Buttons.import')} />
    )
}

export default QuizlibImportButtonField