import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectAndEditField from '../../../../UI/Fields/SelectAndEditField'

export interface IOptionsLibSelectAndEditField {
    options: { label: string, value: string }[]
    optionsLibId: string
    setOptionsLibId: React.Dispatch<React.SetStateAction<string>>
    inline?: boolean
    showLabel: boolean
    setPopupQuizlib: React.Dispatch<React.SetStateAction<boolean>>
    tips?: string
}

const OptionsLibSelectAndEditField: React.FunctionComponent<IOptionsLibSelectAndEditField> = (props) => {
    const { t } = useTranslation()

    const [canEdit, setCanEdit] = useState<boolean>(true)

    useEffect(() => {
        if (props.optionsLibId === '') {
            setCanEdit(false)
        } else {
            setCanEdit(true)
        }
    }, [props.optionsLibId])


    const optionsLibOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value
        props.setOptionsLibId(val)
    }

    const optionsLibOnAddHandler = () => {
        props.setOptionsLibId('')
        props.setPopupQuizlib(true)
    }

    const optionsLibOnEditHandler = () => {
        props.optionsLibId !== '' && props.setPopupQuizlib(true)
    }

    return (
        <SelectAndEditField inline={props.inline} id='optionslib-select-edit' options={props.options} onChange={optionsLibOnChangeHandler} value={props.optionsLibId} label={props.showLabel ? t('Labels.options_lib') : undefined} translate={false} onAdd={optionsLibOnAddHandler} onEdit={optionsLibOnEditHandler} canEdit={canEdit} tips={props.tips} />
    )
}

export default OptionsLibSelectAndEditField