import React from 'react'
import Popup from './Popup'
import styles from './NotIdleConfirmPopup.module.css'
import TextButton from '../Buttons/TextButton'
import { useTranslation } from 'react-i18next'

export interface INotIdleConfirmPopupProps {
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    onConfirm: () => any
}

const NotIdleConfirmPopup: React.FunctionComponent<INotIdleConfirmPopupProps> = (props) => {
    const { t } = useTranslation()

    const [active, setActive] = [props.active, props.setActive]

    const confirmButtonOnClickHandler = () => {
        props.onConfirm()
        props.setActive(false)
    }

    return (
        <Popup title={t('Titles.not_idle')} active={active} setActive={setActive}>
            <div className={styles.container}>
                <div className={styles.messageContainer}>
                    <p className={styles.message}>{t('Messages.not_idle')}</p>
                </div>
                <div className={styles.buttons}>
                    <TextButton text={t('Buttons.confirm')} onClick={confirmButtonOnClickHandler} />
                </div>
            </div>
        </Popup>
    )
}

export default NotIdleConfirmPopup