import React from 'react'
import { useTranslation } from 'react-i18next'
import SelectField from '../../../UI/Fields/SelectField'

export interface IArticleThemeSelectField {
    options: { label: string, value: string }[]
    themeId: string
    setThemeId: React.Dispatch<React.SetStateAction<string>>
    inline?: boolean
    showLabel: boolean
    tips?: string
}

const ArticleThemeSelectField: React.FunctionComponent<IArticleThemeSelectField> = (props) => {
    const { t } = useTranslation()

    const themeOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        props.setThemeId(e.target.value)
    }

    return (
        <SelectField inline28={props.inline} id='article-theme-select' options={props.options} onChange={themeOnChangeHandler} value={props.themeId} label={props.showLabel ? t('Labels.theme_name') : undefined} translate={false} tips={props.tips} />
    )
}

export default ArticleThemeSelectField