import React from 'react'
import { useTranslation } from 'react-i18next'
import TextareaField from '../../../../UI/Fields/TextareaField'

export interface IAfterQuestionInputFieldProps {
    afterQuestion: string | undefined
    setAfterQuestion: React.Dispatch<React.SetStateAction<string | undefined>>
    max: number
    inline?: boolean
    tips?: string
}

const AfterQuestionInputField: React.FunctionComponent<IAfterQuestionInputFieldProps> = (props) => {
    const { t } = useTranslation()

    const questionInputOnChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.setAfterQuestion(() => e.target.value)
    }

    return (
        <TextareaField inline={props.inline} id='after-question-input' label={t('Labels.after_question')} value={props.afterQuestion} onChange={questionInputOnChangeHandler} maxLength={props.max} rows={3} tips={props.tips} />
    )
}

export default AfterQuestionInputField