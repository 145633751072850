import React from 'react'
import styles from './ThemeSelectorNameInputField.module.css'
import InputField from '../../../../UI/Fields/InputField'
import { useTranslation } from 'react-i18next'
import { themeNameMaxLength } from '../../../../../utils/library'
import IconButton from '../../../../UI/Buttons/IconButton'

export interface IThemeSelectorNameInputFieldProps {
    setName: React.Dispatch<React.SetStateAction<string>>
    searchThemeName: string
    setSearchThemeName: React.Dispatch<React.SetStateAction<string>>
    onSubmit?: (reset?: boolean) => void
    submitted?: boolean
}

const ThemeSelectorNameInputField: React.FunctionComponent<IThemeSelectorNameInputFieldProps> = (props) => {
    const { t } = useTranslation()

    const searchInputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setSearchThemeName(e.target.value)
    }

    const searchIconOnClickHandler = () => {
        if (props.onSubmit !== undefined) {
            props.onSubmit()
        }
    }

    const searchInputOnKeyDownHandler = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            if (props.onSubmit !== undefined) {
                props.onSubmit()
            }
        }
    }

    const closeIconOnClickHandler = () => {
        if (props.onSubmit !== undefined) {
            props.onSubmit(true)
        }
    }

    return (
        <div className={`${styles.field} ${props.submitted ? styles.submitted : ''}`}>
            <InputField id='search-theme-name' classes={styles.input} value={props.searchThemeName} placeholder={t('Placeholders.search_themename')} onChange={searchInputOnChangeHandler} onKeyDown={searchInputOnKeyDownHandler} maxLength={themeNameMaxLength} />
            {
                props.submitted && props.searchThemeName &&
                <IconButton type='close' classes={styles.closeButton} onClick={closeIconOnClickHandler} />
            }
            {
                !(props.submitted && props.searchThemeName) &&
                <IconButton type='search' classes={styles.searchButton} onClick={searchIconOnClickHandler} />
            }
        </div>
    )
}

export default ThemeSelectorNameInputField