import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './DialogForm.module.css'
import TextButton from '../../UI/Buttons/TextButton'
import SocketContext from '../../../contexts/Socket/SocketContext'
import UserContext from '../../../contexts/User/UserContext'
import { useTranslation } from 'react-i18next'
import { IOneDialogRecord } from '../../../utils/library'

export interface IDialogForm {
    setDialog: React.Dispatch<React.SetStateAction<IOneDialogRecord[]>>
}

const DialogForm: React.FunctionComponent<IDialogForm> = (props) => {
    const { t } = useTranslation()

    const { user, room } = useContext(UserContext).UserState
    const { socket } = useContext(SocketContext).SocketState
    const [message, setMessage] = useState<string>('')
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {

        /** Get message sent from others */
        socket?.on('new_dialog_message', (m: IOneDialogRecord) => {
            // console.log(m)
            props.setDialog(oldDialog => [...oldDialog, m])
        })

        return () => {
            socket?.off('new_dialog_message')
        }
    }, [socket])


    const MessageOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(() => e.target.value)
    }

    const MessageOnKeyDownHandler = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            UpdateMessageView()
        }
    }

    const MessageButtonOnClickHandler = () => {
        UpdateMessageView()
    }

    const UpdateMessageView = () => {
        if (message !== '') {
            /** Boardcast message */
            socket?.emit('send_dialog_message', message)

            /** Update my message view */
            const now = new Date()
            const roomUId = room.roomUId as string
            const username = user.username as string
            const timestamp = now.getTime()
            const messageObj: IOneDialogRecord = {
                roomUId,
                username,
                message,
                createdAt: timestamp
            }
            props.setDialog(oldDialog => [...oldDialog, messageObj])

            setMessage('')
        } else {
            inputRef.current?.focus()
        }
    }

    return (
        <div className={styles.form}>
            <div className={styles.inputField}>
                <input id='dialog-input' className={styles.messageInput} type='text' ref={inputRef} value={message} onChange={MessageOnChangeHandler} onKeyDown={MessageOnKeyDownHandler} />
            </div>
            <TextButton id='sendMessageButton' text={t('Buttons.send')} onClick={MessageButtonOnClickHandler} />
        </div>
    )
}

export default DialogForm