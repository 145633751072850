import React, { useContext, useEffect, useState } from 'react'
import styles from './CreateRoomSettingsFormPopup.module.css'
import { useTranslation } from 'react-i18next'
import NumberField from '../UI/Fields/NumberField'
import TextButton from '../UI/Buttons/TextButton'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../contexts/User/UserContext'
import SocketContext from '../../contexts/Socket/SocketContext'
import Popup from '../UI/Popup/Popup'
import { TGameMode, TQuizTimeLimit, TRoomVisibility, TThemeData, defaultQuizTimeLimit, defaultThemeSettings, quizTimeLimitOptions, quizTimeLimits } from '../../utils/library'
import SelectField from '../UI/Fields/SelectField'

export interface ICreateRoomSettingsFormPopup {
    roomName: string
    visibility: TRoomVisibility
    password: string
    gameMode: TGameMode
    selectedThemeData: TThemeData | undefined
    setError: React.Dispatch<React.SetStateAction<string>>
    setPopupError: React.Dispatch<React.SetStateAction<boolean>>
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateRoomSettingsFormPopup: React.FunctionComponent<ICreateRoomSettingsFormPopup> = (props) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const userContext = useContext(UserContext)
    const { user } = userContext.UserState
    const userDispatch = userContext.UserDispatch

    const socketContext = useContext(SocketContext)
    const { socket } = socketContext.SocketState

    const [totalQues, setTotalQues] = useState<number>()
    const [quesNum, setQuesNum] = useState<number>()
    const [quizTimeLimit, setQuizTimeLimit] = useState<TQuizTimeLimit>(defaultQuizTimeLimit)

    useEffect(() => {
        const defaultQuestionNumber = defaultThemeSettings.questionNumber

        if (props.selectedThemeData !== undefined && props.selectedThemeData?.count !== undefined) {
            const count = props.selectedThemeData.count
            setTotalQues(() => count)
            setQuesNum(() => count > defaultQuestionNumber ? defaultQuestionNumber : count)
        } else {
            setTotalQues(undefined)
            setQuesNum(undefined)
        }
    }, [props.selectedThemeData])

    const countOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuesNum(() => parseInt(e.target.value))
    }

    const quizTimeLimitOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value as TQuizTimeLimit
        if (quizTimeLimits.includes(val)) {
            setQuizTimeLimit(val)
        }
    }

    const createButtonOnClickHandler = () => {
        if (props.roomName.length <= 0) {
            props.setError(t('Warnings.require_roomname'))
            props.setPopupError(true)
            return
        }

        if (quesNum !== undefined && totalQues !== undefined && (quesNum < 1 || quesNum > totalQues)) {
            const msg = totalQues > 1 ? t('Warnings.question_number_range', { totalQues }) : t('Warnings.question_number_range_1')
            props.setError(msg)
            props.setPopupError(true)
            return
        }

        if (props.selectedThemeData === undefined) {
            props.setError(t('Warnings.require_theme'))
            props.setPopupError(true)
            return
        }

        const themeId = props.selectedThemeData._id
        const language = i18n.resolvedLanguage

        socket?.emit('create_room', { user, roomName: props.roomName, visibility: props.visibility, password: props.password, gameMode: props.gameMode, themeId, language, settings: { quesNum, quizTimeLimit } }, (res: any) => {
            if (res?.error === undefined) {
                userDispatch({ type: 'enter_room', payload: res })
                userDispatch({ type: 'update_room_status', payload: 'waiting' })

                navigate(`/rooms/${res.roomId}`)
            } else {
                props.setError(t(res.error))
                props.setPopupError(true)
            }
        })
    }

    const cancelButtonOnClickHandler = () => {
        props.setActive(false)
    }

    return (
        <Popup title={t('Titles.theme_settings')} active={props.active} setActive={props.setActive}>
            <div className={styles.container}>
                <div className={styles.form}>
                    {
                        totalQues !== undefined &&
                        <NumberField inline={true} id='roomsettings-count' label={t('Labels.questions_number') + ' ' + t('Labels.questions_number_limit', { totalQues })} value={quesNum} min={1} max={totalQues} step={1} onChange={countOnChangeHandler} />
                    }
                    <SelectField inline={true} id='roomsettings-timelimit' label={t('Labels.timelimit')} options={quizTimeLimitOptions} value={quizTimeLimit} onChange={quizTimeLimitOnChangeHandler} translate={true} />
                </div>
                <div className={styles.buttons}>
                    <TextButton id='create-room' text={t('Buttons.create')} onClick={createButtonOnClickHandler} />
                    <TextButton id='cancel' text={t('Buttons.cancel')} onClick={cancelButtonOnClickHandler} />
                </div>
            </div>
        </Popup>
    )
}

export default CreateRoomSettingsFormPopup