import React, { useEffect, useState } from 'react'
import styles from './ArticleSlider.module.css'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import ArticleSlide from './ArticleSlide'
import { IArticleListItemData } from '../../../utils/library'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import Loading from '../Loading/Loading'
import PrevArrow from './PrevArrow'
import NextArrow from './NextArrow'

export interface IArticleSlider {
    type?: 'latest'
    setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>
}

const ArticleSlider: React.FunctionComponent<IArticleSlider> = (props) => {
    const { i18n } = useTranslation()

    const [list, setList] = useState<IArticleListItemData[]>()
    const [loading, setLoading] = useState<boolean>(false)

    var settings: Settings = {
        swipeToSlide: true,
        slidesToShow: 4,
        infinite: false,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    }

    useEffect(() => {
        if (i18n.resolvedLanguage) {
            setLoading(true)
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/articles/list?limit=${12}&paged=${1}&lang=${i18n.resolvedLanguage}`
            }).then((res) => {
                if (res.data) {
                    setList(res.data.articles)
                }
                if (!res.data || res.data.articles.length === 0) {
                    props.setIsEmpty(true)
                } else {
                    props.setIsEmpty(false)
                }
            }).catch(() => {
                props.setIsEmpty(true)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [i18n.resolvedLanguage])

    return (
        <div className={styles.container}>
            <Loading loading={loading} classes={styles.loading} />
            {
                !loading && list !== undefined &&
                <Slider {...settings} >
                    {
                        list.map((d, i) => {
                            return (
                                <ArticleSlide key={'article-slide-' + i} data={d} />
                            )
                        })
                    }
                </Slider>
            }
        </div>
    )
}

export default ArticleSlider