import React, { useEffect, useRef } from 'react'
import styles from './InputField.module.css'

export interface IInputProps {
    label?: string
    value?: string
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
    id: string
    placeholder?: string
    tips?: string
    invalid?: boolean
    inline?: boolean
    inline28?: boolean
    maxLength?: number
    classes?: string
    disabled?: boolean
    focus?: boolean
}

const InputField: React.FunctionComponent<IInputProps> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (props.focus === true && inputRef.current) {
            inputRef.current.focus()
        }
    }, [props.focus])

    return (
        <div className={`${styles.field} ${props.inline === true ? styles.inline : ''} ${props.inline28 === true ? styles.inline28 : ''} ${props.classes !== undefined ? props.classes : ''} ${props.disabled === true ? styles.disabled : ''}`}>
            {
                props.label !== undefined &&
                <label className={styles.label} htmlFor={props.id}>{props.label}</label>
            }
            <div className={styles.container}>
                <input ref={inputRef} id={props.id} className={`${styles.input} ${props.invalid ? styles.invalid : ''}`} type='text' value={props.value || ''} placeholder={props.placeholder} onChange={props.onChange} maxLength={props.maxLength} onKeyDown={props.onKeyDown} disabled={props.disabled === true ? true : false} />
                {
                    props.tips !== undefined &&
                    <p className={styles.tips}>{props.tips}</p>
                }
            </div>

        </div>
    )
}

export default InputField