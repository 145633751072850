import React from 'react'
import styles from './LanguageSelectField.module.css'
import SelectField from '../../UI/Fields/SelectField'
import { langOptions, langOptionsShort } from '../../../i18n/settings'
import { useTranslation } from 'react-i18next'

export interface ILanguageSelectField {
    inline?: boolean
    inFilter?: boolean
    showLabel: boolean
    onChange?: () => void
    short?: boolean
    classes?: string
}

const LanguageSelectField: React.FunctionComponent<ILanguageSelectField> = (props) => {
    const { t, i18n } = useTranslation()

    const changeLang = (lng: string) => {
        i18n.changeLanguage(lng)
    }

    let options = []
    if (props.short) {
        options = Object.keys(langOptionsShort).map((k) => {
            const opt: { label: string, value: string } = {
                label: langOptionsShort[k],
                value: k
            }
            return opt
        })
    } else {
        options = Object.keys(langOptions).map((k) => {
            const opt: { label: string, value: string } = {
                label: langOptions[k],
                value: k
            }
            return opt
        })
    }

    const selectOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        changeLang(e.target.value)

        if (props.onChange !== undefined) {
            props.onChange()
        }
    }

    return (
        <SelectField inline={props.inline} options={options} onChange={selectOnChangeHandler} value={i18n.resolvedLanguage} label={props.showLabel ? t('Labels.language') : undefined} classes={`${props.inFilter ? styles.filter : ''} ${props.classes}`} />
    )
}

export default LanguageSelectField