import themeData from './theme.json'

export default class Theme {
    public static instance: Theme
    private imagesPath: string

    constructor() {
        Theme.instance = this

        this.imagesPath = process.env.PUBLIC_URL + '/images/themes'
    }

    public GetImage = (gameType: string, themeType: string) => {
        let src = themeData.find((d) => d.gameType === gameType && d.themeType === themeType)?.image
        return src !== undefined ? this.imagesPath + '/' + gameType + '/' + src : undefined
    }
}