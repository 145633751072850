import React, { useContext, useEffect, useState } from 'react'
import SocketContext from '../../contexts/Socket/SocketContext'
import RoomCard from './RoomCard'
import styles from './RoomsList.module.css'
import Scrollbars from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import LanguageSelectField from '../Forms/Fields/LanguageSelectField'
import SearchRoomInputField from '../Forms/Fields/RoomFields/SearchRoomInputField'
import { TRoomData, TRoomVisibility } from '../../utils/library'
import Loading from '../UI/Loading/Loading'

export interface IRoomsList {
    selectedRoom: { id: string, visibility: TRoomVisibility } | undefined
    setSelectedRoom: React.Dispatch<React.SetStateAction<{ id: string, visibility: TRoomVisibility } | undefined>>
}

const RoomsList: React.FunctionComponent<IRoomsList> = (props) => {
    const { t, i18n } = useTranslation()

    const socketContext = useContext(SocketContext)
    const { socket } = socketContext.SocketState

    const [rooms, setRooms] = useState<TRoomData[]>([])

    const [searchRoomName, setSearchRoomName] = useState<string>('')
    const [currentSearchingName, setCurrentSearchingName] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false)

    /** First update rooms */

    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        if (!loaded && socket !== undefined && i18n.resolvedLanguage) {
            setLoading(true)

            const options = { filter: { language: i18n.resolvedLanguage, roomStatus: 'waiting' } }

            socket.emit('join_lobby', options, (rooms: TRoomData[]) => {
                setRooms(rooms)
                setLoading(false)
                // console.log('join lobby')
            })

            setLoaded(true)
        }
    }, [socket, i18n.resolvedLanguage, loaded])

    /** Update rooms */

    useEffect(() => {
        const options = { filter: { language: i18n.resolvedLanguage, roomStatus: 'waiting', roomName: currentSearchingName } }

        // console.log('update_lobby_call')

        socket?.on('update_lobby_call_add', (res: { roomId: string }) => {
            socket.emit('update_lobby_add', { ...options, roomId: res.roomId }, (room: TRoomData | undefined) => {
                // console.log('update_lobby_add: ')
                // console.log(room)
                if (room !== undefined) {
                    setRooms((rooms) => [room, ...rooms])
                }
            })
        })

        socket?.on('update_lobby_call_users', (res: { roomId: string, usersCount: number }) => {
            setRooms((rooms) => {
                const index = rooms.findIndex((d) => d.roomId === res.roomId)
                if (index > -1) {
                    // console.log("usersCount: " + res.usersCount)
                    const room = rooms[index]
                    room.usersCount = res.usersCount
                    rooms[index] = room
                }
                return [...rooms]
            })
        })

        socket?.on('update_lobby_call_remove', (res: { roomId: string }) => {
            // console.log('update_lobby_call_remove: ' + res.roomId)
            setRooms((rooms) => rooms.filter((room) => room.roomId !== res.roomId))
        })

        return () => {
            socket?.off('update_lobby_call_add')
            socket?.off('update_lobby_call_users')
            socket?.off('update_lobby_call_remove')
        }
    }, [socket, currentSearchingName, i18n.resolvedLanguage])

    const roomCardOnClickHandler = (obj: { id: string, visibility: TRoomVisibility }) => {
        props.setSelectedRoom(obj)
    }

    const languageOnChangeHandler = () => {
        setLoading(true)

        setCurrentSearchingName(searchRoomName)
        const options = { filter: { language: i18n.resolvedLanguage, roomStatus: 'waiting', roomName: searchRoomName } }

        socket?.emit('update_lobby', options, (rooms: TRoomData[]) => {
            // console.log('update lobby')
            setRooms(rooms)
            setLoading(false)
        })
    }

    const searchInputOnSubmitHandler = () => {
        if (currentSearchingName !== searchRoomName) {
            // console.log('search name...')
            setLoading(true)

            const options = { filter: { language: i18n.resolvedLanguage, roomStatus: 'waiting', roomName: searchRoomName } }

            socket?.emit('update_lobby', options, (rooms: TRoomData[]) => {
                // console.log('update lobby')
                setRooms(rooms)
                setLoading(false)
            })
        }
        setCurrentSearchingName(searchRoomName)
    }

    return (
        <div className={styles.container}>
            <div className={styles.filter}>
                <div className={styles.field}>
                    <LanguageSelectField showLabel={false} onChange={languageOnChangeHandler} inFilter={true} />
                </div>
                <div className={styles.field}>
                    <SearchRoomInputField searchRoomName={searchRoomName} setSearchRoomName={setSearchRoomName} onSubmit={searchInputOnSubmitHandler} submitted={currentSearchingName === searchRoomName} />
                </div>
            </div>
            <div className={styles.scrollbarContainer}>
                {
                    !loading &&
                    <Scrollbars
                        style={{ height: '100%' }}
                    >
                        <div className={`${styles.list} ${rooms.length === 0 ? styles.empty : ''}`}>
                            {rooms.length === 0 && <div>{t('Warnings.no_room')}</div>}
                            {rooms.length !== 0 && rooms.map((data) => {
                                return <RoomCard key={data.roomId} roomId={data.roomId} data={data} parentCallback={roomCardOnClickHandler} selected={data.roomId === props.selectedRoom?.id} />
                            })}
                        </div>
                    </Scrollbars>
                }
                <Loading loading={loading} />
            </div>
        </div>
    )
}

export default RoomsList