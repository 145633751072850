import React, { useContext, useEffect, useState } from 'react'
import styles from './AnswerView.module.css'
import AnswerButton from './AnswerComponents/AnswerButton'
import AnswerInput from './AnswerComponents/AnswerInput'
import SocketContext from '../../../../contexts/Socket/SocketContext'
import UserContext from '../../../../contexts/User/UserContext'
import { TAnswerRes, TAnswerResData, TQues, TQuizTimeLimit, defaultQuizTimeLimit, timeLimitValues } from '../../../../utils/library'
import Scrollbars from 'react-custom-scrollbars-2'

export interface IAnswerView {
    ques: TQues
    timeLeave: number
    answer: string
    setAnswer: React.Dispatch<React.SetStateAction<string>>
    canAnswer: boolean
    setCanAnswer: React.Dispatch<React.SetStateAction<boolean>>
    canAnswerCallback: boolean
    answerResData: TAnswerResData | undefined
    setAnswerResData: React.Dispatch<React.SetStateAction<TAnswerResData | undefined>>
    setAnsweredUsers: React.Dispatch<React.SetStateAction<{ roomUId: string | undefined, correct: boolean }[]>>
    scoreChange: { old: number, new: number }
    setScoreChange: React.Dispatch<React.SetStateAction<{ old: number, new: number }>>
    setUsersAnswerStatus: React.Dispatch<React.SetStateAction<{ name: string, correct: boolean }[]>>
}

const AnswerView: React.FunctionComponent<IAnswerView> = (props) => {
    const [answer, setAnswer] = [props.answer, props.setAnswer]
    const { socket } = useContext(SocketContext).SocketState

    const userContext = useContext(UserContext)
    const { user, room } = userContext.UserState
    const userDispatch = userContext.UserDispatch

    const [submittingAns, setSubmittingAns] = useState<string>()

    useEffect(() => {
        socket?.on('user_answered', (res: { roomUId: string, correct: boolean, timeout: boolean, newScore: number, timeSpent: number }) => {
            // console.log(res)
            // console.log('-------AnsV--------')
            // console.log(props.ques)

            // console.log('answered')
            props.setAnsweredUsers((answeredUsers) => [...answeredUsers, { roomUId: res.roomUId, correct: res.correct }])
            if (room.roomUId === res.roomUId) {
                props.setScoreChange(() => {
                    return { old: props.scoreChange.new, new: res.newScore }
                })
            }

            if (room.users !== undefined) {
                const uid = Object.keys(room.users).find((id) => {
                    return room.users !== undefined && room.users[id].roomUId === res.roomUId
                })
                if (uid !== undefined && room.users[uid] !== undefined) {
                    const records = room.users[uid].records
                    const questionCount = records.questionCount !== undefined ? records.questionCount + 1 : 1
                    const correctCount = records.correctCount !== undefined ? (res.correct ? records.correctCount + 1 : records.correctCount) : (res.correct ? 1 : 0)
                    // console.log(res.roomUId + ' : ' + res.timeSpent)
                    let newTimeSpent = res.timeSpent
                    if (res.timeSpent === -1) {
                        const timeLimit = room.themeSettings?.quizTimeLimit as TQuizTimeLimit | undefined
                        newTimeSpent = timeLimit !== undefined ? timeLimitValues[timeLimit] * 1000 : timeLimitValues[defaultQuizTimeLimit] * 1000
                    }
                    const totalTimeSpent = records.totalTimeSpent !== undefined ? records.totalTimeSpent + newTimeSpent : newTimeSpent
                    userDispatch({ type: 'update_users_score', payload: { roomUId: res.roomUId, score: res.newScore, records: { questionCount, correctCount, totalTimeSpent } } })

                    const username = room.users[uid].username
                    props.setUsersAnswerStatus((usersAnswerStatus) => [...usersAnswerStatus, { name: username, correct: res.correct }])
                }
            }
        })

        return () => {
            socket?.off('user_answered')
        }
    }, [socket, room.users, room.themeSettings])

    useEffect(() => {
        socket?.on('someone_correct', (res: { answer: string[] }) => {
            if (props.answerResData === undefined) {
                props.setCanAnswer(false)
                props.setAnswerResData({ res: { correct: false, newScore: props.scoreChange.new, answer: res.answer, timeSpent: 0 }, timeout: false, tooLate: true })
                props.setScoreChange(() => {
                    return { old: props.scoreChange.new, new: props.scoreChange.new }
                })
            }
        })

        return () => {
            socket?.off('someone_correct')
        }
    }, [socket, props.answerResData, props.scoreChange])


    useEffect(() => {
        if (!props.canAnswerCallback && submittingAns !== undefined) {
            const timeLimit = room.themeSettings?.quizTimeLimit
            const quizOneTurnTimeLimit = timeLimit && timeLimitValues[timeLimit] ? timeLimitValues[timeLimit] : timeLimitValues[defaultQuizTimeLimit]
            const timeSpent = quizOneTurnTimeLimit * 1000 - Math.ceil(props.timeLeave * 100) * 10

            socket?.emit('submit_answer', { answer: submittingAns, timeSpent }, (res: TAnswerRes) => {
                // console.log('submit_answer, res:')
                // console.log(res)

                props.setAnswerResData({ res, timeout: false })

                if (!res.correct && props.ques.type === 'text') {
                    props.setCanAnswer(true)
                } else {
                    props.setAnsweredUsers((answeredUsers) => [...answeredUsers, { roomUId: room.roomUId, correct: res.correct }])
                    props.setScoreChange(() => {
                        return { old: props.scoreChange.new, new: res.newScore }
                    })

                    if (room.users !== undefined && user.uid !== undefined && room.users[user.uid] !== undefined) {
                        const records = room.users[user.uid].records
                        const questionCount = records.questionCount !== undefined ? records.questionCount + 1 : 1
                        const correctCount = records.correctCount !== undefined ? (res.correct ? records.correctCount + 1 : records.correctCount) : (res.correct ? 1 : 0)
                        // console.log(room.roomUId + ' : ' + res.timeSpent)
                        const totalTimeSpent = records.totalTimeSpent !== undefined ? records.totalTimeSpent + res.timeSpent : res.timeSpent
                        userDispatch({ type: 'update_users_score', payload: { roomUId: room.roomUId, score: res.newScore, records: { questionCount, correctCount, totalTimeSpent } } })
                    }
                }
            })

            setSubmittingAns(undefined)
        }
    }, [props.canAnswerCallback, submittingAns, room.users, user.uid, room.themeSettings])


    const submitAnswer = (ans: string) => {
        props.setCanAnswer(false)
        setSubmittingAns(ans)
    }

    switch (props.ques.type) {
        case 'select':
            if (props.ques.options) {
                const options = props.ques.options
                return (
                    <div className={styles.view}>
                        <Scrollbars
                            style={{ height: '100%' }}
                        >
                            <div className={styles.scrollbarInner}>
                                <div className={styles.buttons}>
                                    {options.map((option, index) => {
                                        return (
                                            <AnswerButton
                                                answerResData={props.answerResData}
                                                key={index}
                                                text={option}
                                                answer={answer}
                                                setAnswer={setAnswer}
                                                disabled={!props.canAnswer}
                                                submitAnswer={submitAnswer} />
                                        )
                                    })}
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                )
            }
            break

        default:
            break
    }

    return (
        <div className={styles.view}>
            <div className={styles.input}>
                <AnswerInput
                    answerResData={props.answerResData}
                    answer={answer}
                    setAnswer={setAnswer}
                    submitAnswer={submitAnswer}
                    disabled={!props.canAnswer} />
            </div>
        </div>
    )
}

export default AnswerView