import React, { useContext, useEffect, useState } from 'react'
import Popup from './Popup'
import UserContext from '../../../contexts/User/UserContext'
import { Scrollbars } from 'react-custom-scrollbars-2';
import styles from './AvatarSelectorPopup.module.css'
import Avatar from '../../../utils/avatar';
import AvatarIcon from '../Avatar/AvatarIcon';
import { useTranslation } from 'react-i18next';

export interface IAvatarSelectorPopup {
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    avatar: string | undefined
    setAvatar: React.Dispatch<React.SetStateAction<string | undefined>>
}

const AvatarSelectorPopup: React.FunctionComponent<IAvatarSelectorPopup> = (props) => {
    const { t } = useTranslation()

    const userDispatch = useContext(UserContext).UserDispatch

    const [selectingAvatar, setSelectingAvatar] = useState(props.avatar)
    const [active, setActive] = [props.active, props.setActive]

    const avatarHandler = new Avatar()
    const avatarList = avatarHandler.GetAvatarList()

    useEffect(() => {
        /** Update the selected avatar in popup */
        setSelectingAvatar(() => props.avatar)

        // console.log('Init popup avatar')
    }, [props.avatar])

    /** Select avatar */
    const avatarListItemOnClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        const src = e.currentTarget.getAttribute('data-src') || ''
        setSelectingAvatar(src)
    }

    /** Confirm using the selected avatar */
    const confirmButtonOnClickHandler = () => {
        if (selectingAvatar !== undefined) {
            userDispatch({ type: 'update_avatar', payload: selectingAvatar })
        }

        props.setAvatar(selectingAvatar)
        setActive(false)
    }

    /** Render scrollbar view */
    const renderView = ({ style, ...props }: any) => {
        const viewStyle = {
            paddingRight: '12px'
        }
        return (
            <div style={{ ...style, ...viewStyle }}
                {...props} />
        )
    }

    return (
        <Popup title={t('Titles.avatar')} active={active} setActive={setActive}>
            <div className={styles.listContainer}>
                <Scrollbars
                    style={{ height: '100%' }}
                    renderView={renderView}
                >
                    <div className={styles.list}>
                        {avatarList.map((avatar) => {
                            let avatarPath = avatarHandler.GetAvatarPath(avatar.id) || ''
                            return (
                                <div className={`${styles.listItem} ${(selectingAvatar === avatarPath) && styles.selectedListItem}`} key={avatar.id} onClick={avatarListItemOnClickHandler} data-src={avatarPath}>
                                    <AvatarIcon src={avatarPath} />
                                </div>
                            )
                        })}
                    </div>
                </Scrollbars>
            </div>
            <div className={styles.actions}>
                <button onClick={confirmButtonOnClickHandler}>{t('Buttons.confirm')}</button>
            </div>
        </Popup>
    )
}

export default AvatarSelectorPopup