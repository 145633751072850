import React from 'react'
import styles from './NumberField.module.css'

export interface INumberFieldProps {
    label?: string
    value?: number
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
    min?: number
    max?: number
    step?: number
    id: string
    tip?: string | undefined
    showTip?: boolean
    invalid?: boolean
    inline?: boolean
    classes?: string
}

const NumberField: React.FunctionComponent<INumberFieldProps> = (props) => {
    return (
        <div className={`${styles.field} ${props.inline === true ? styles.inline : ''} ${props.classes !== undefined ? props.classes : ''}`}>
            {
                props.label !== undefined &&
                <label className={styles.label} htmlFor={props.id}>{props.label}</label>
            }
            <input id={props.id} className={`${styles.input} ${props.invalid ? styles.invalid : ''}`} type='number' min={props.min} max={props.max} step={props.step} value={props.value || 0} onChange={props.onChange} onKeyDown={props.onKeyDown} />
            {
                props.tip &&
                <span className={`${styles.tip} ${props.showTip ? styles.showTip : ''}`}>{props.tip}</span>
            }
        </div>
    )
}

export default NumberField