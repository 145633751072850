import React from 'react'
import styles from './TermsOfServicePage.module.css'
import PageTitle from '../components/Widgets/Title/PageTitle'

const TermsOfServicePage: React.FunctionComponent = () => {
    return (
        <div className={styles.container}>
            <div className={styles.wrap}>
                <PageTitle title='Terms of Service' backPath='/login' />
                <div className={styles.content}>
                    <p>1. Terms and conditions</p>
                    <p>By accessing http://quzz.io, you agree to be bound by these terms of service, all applicable laws, and regulations and agree that you are responsible for compliance with it. If you disagree, you are prohibited from entering this website and playing quzz.io.</p>
                    <p>We reserve the right to change the Terms of Service and the Privacy Policy at any time.</p>
                    <p>Your continued use of the website after changes to this policy will be deemed your acceptance of such changes.</p>
                    <p>The materials on this site are protected by applicable copyright and trademark.</p>
                    <p>A minor (anyone under 13 years of age) must seek the consent of their legal guardian to use the website and the quzz.io game.</p>
                    <p>2. User Conduct</p>
                    <p>You must treat all users with respect and comply with the updated website's terms, conditions, and policies. Inappropriate language and inappropriate behavior are prohibited.</p>
                    <p>We are not responsible for user content, posts, chat, and other types of communication. Users are responsible for their actions and all the consequences that may arise from there.</p>
                    <p>The host can kick out other player from the room. We will not be liable for any inconvenience caused by player actions.</p>
                    <p>We reserve the right to edit or delete user's custom themes if inappropriate or illegal contents are found. We are not responsible for any loss, damage, or inconvenience you suffer from this edition or deletion.</p>
                    <p>3. Advertisements</p>
                    <p>We are not responsible for any loss, damage, or inconvenience you suffer from viewing or clicking on advertisements under any circumstances. You must complain directly against the external company responsible for the ad.</p>
                    <p>4. Others</p>
                    <p>We reserve the right to comply with any request or cooperate with law enforcement officials regarding identifying any user who assumes to use the website in violation of the law.</p>
                </div>
            </div>
        </div>
    )
}

export default TermsOfServicePage