import React, { useEffect, useState } from 'react'
import styles from './ThemeSelectorWhoSelectField.module.css'
import SelectField from '../../../../UI/Fields/SelectField'
import { IThemeWhoOption, TThemeWho, themeWhoOptions } from '../../../../../utils/library'
import Cookies from 'js-cookie'

export interface IThemeSelectorWhoSelectField {
    who: TThemeWho,
    setWho: React.Dispatch<React.SetStateAction<TThemeWho>>
    onChange?: () => any
}

const ThemeSelectorWhoSelectField: React.FunctionComponent<IThemeSelectorWhoSelectField> = (props) => {
    const [options, setOptions] = useState<IThemeWhoOption[]>(themeWhoOptions)

    useEffect(() => {
        if (Cookies) {
            if (!Cookies.get('login_token')) {
                setOptions(() => options.filter((v) => v.value !== 'my'))
            }
        }
    }, [Cookies])

    const themeWhoOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        props.setWho(() => e.target.value as TThemeWho)
        if (props.onChange !== undefined) {
            props.onChange()
        }
    }

    return (
        <SelectField id='theme-who-select' options={options} onChange={themeWhoOnChangeHandler} value={props.who} classes={styles.filter} translate={true} />
    )
}

export default ThemeSelectorWhoSelectField