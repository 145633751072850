import React from 'react'
import styles from "./AvatarIcon.module.css"

export interface IAvatarIcon {
    src: string | undefined
    size?: string
    myAvatar?: boolean
}

const AvatarIcon: React.FunctionComponent<IAvatarIcon> = (props) => {
    let styleSize = ''
    let styleMyAvatar = ''

    switch (props.size) {
        case 'sm':
            styleSize = styles.sm
            break

        default:
            break
    }

    if (props.myAvatar) {
        styleMyAvatar = styles.myAvatar
    }

    return (
        <div className={`${styles.container} ${styleSize} ${styleMyAvatar}`}>
            <img className={styles.avatar} src={props.src} alt='avatar' />
        </div>
    )
}

export default AvatarIcon