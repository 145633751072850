import React from 'react'
import styles from './GameModeSelectField.module.css'
import SelectField from '../../../UI/Fields/SelectField'
import { useTranslation } from 'react-i18next'
import { TGameMode, TGameType, gameModes, gameModesOptions } from '../../../../utils/library'

export interface IGameModeSelectField {
    gameType: TGameType
    gameMode: TGameMode,
    setGameMode: React.Dispatch<React.SetStateAction<TGameMode>>
    inline?: boolean
    inFilter?: boolean
    showLabel: boolean
}

const GameModeSelectField: React.FunctionComponent<IGameModeSelectField> = (props) => {
    const { t } = useTranslation()

    const options = gameModesOptions[props.gameType]

    const gameModeOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value as TGameMode
        // console.log(`selected room type: ${val}`)
        if (gameModes.includes(val)) {
            props.setGameMode(val)
        }
    }

    return (
        <SelectField inline={props.inline} id='room-gamemode-select' options={options} onChange={gameModeOnChangeHandler} value={props.gameMode} label={props.showLabel ? t('Labels.gamemode') : undefined} classes={props.inFilter ? styles.filter : ''} translate={true} />
    )
}

export default GameModeSelectField