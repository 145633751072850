import React, { useContext, useState } from 'react'
import styles from './RoomsPageJoinForm.module.css'
import TextButton from '../UI/Buttons/TextButton'
import UserContext from '../../contexts/User/UserContext'
import { useNavigate } from 'react-router-dom'
import RoomsList from '../Rooms/RoomsList'
import SocketContext from '../../contexts/Socket/SocketContext'
import ErrorPopup from '../UI/Popup/ErrorPopup'
import { useTranslation } from 'react-i18next'
import { TRoomVisibility } from '../../utils/library'
import RoomsPageJoinPasswordPopup from './RoomsPageJoinPasswordPopup'

export interface IRoomsPageJoinForm { }

const RoomsPageJoinForm: React.FunctionComponent<IRoomsPageJoinForm> = () => {
    const { t } = useTranslation()

    const userContext = useContext(UserContext)
    const { user } = userContext.UserState
    const userDispatch = userContext.UserDispatch

    const socketContext = useContext(SocketContext)
    const { socket } = socketContext.SocketState

    /** Error Popup */
    const [error, setError] = useState<string>('')
    const [popupError, setPopupError] = useState<boolean>(false)

    /** Password Popup */
    const [password, setPassword] = useState<string>('')
    const [passwordPopup, setPasswordPopup] = useState<boolean>(false)

    const navigate = useNavigate()

    const [selectedRoom, setSelectedRoom] = useState<{ id: string, visibility: TRoomVisibility }>()

    const joinButtonOnClickHandler = () => {
        if (selectedRoom) {
            if (selectedRoom.visibility === 'password') {
                setPasswordPopup(true)
            } else {
                const options = { user, roomId: selectedRoom.id }
                joinRoom(options)
            }
        }
    }

    /** Navigate to create room */
    const createButtonOnClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        navigate('/create')
    }

    const joinRoom = (options: any) => {
        socket?.emit('join_room', options, (res: any) => {
            if (res?.error === undefined) {
                userDispatch({ type: 'enter_room', payload: res })
                userDispatch({ type: 'update_room_status', payload: 'waiting' })

                navigate(`/rooms/${res.roomId}`)
            } else {
                setError(t(res.error))
                setPopupError(true)
            }
        })
    }

    const confirmPassword = () => {
        if (selectedRoom) {
            const options = { user, roomId: selectedRoom.id, password }
            joinRoom(options)
        }
    }

    return (
        <div className={styles.container}>
            <RoomsList selectedRoom={selectedRoom} setSelectedRoom={setSelectedRoom} />
            <div className={styles.buttons}>
                <TextButton id='join-room' text={t('Buttons.join')} onClick={joinButtonOnClickHandler} disabled={selectedRoom === undefined} />
                <TextButton id='create-room' text={t('Buttons.create')} onClick={createButtonOnClickHandler} />
            </div>
            <RoomsPageJoinPasswordPopup active={passwordPopup} setActive={setPasswordPopup} password={password} setPassword={setPassword} onConfirm={confirmPassword} />
            {
                popupError &&
                <ErrorPopup active={popupError} setActive={setPopupError} message={error} />
            }
        </div>
    )
}

export default RoomsPageJoinForm