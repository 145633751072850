import React from 'react'
import styles from './ThemeSlide.module.css'
import { TThemeData } from '../../../utils/library'
import { useTranslation } from 'react-i18next'
import TextButton from '../Buttons/TextButton'
import Theme from '../../../utils/theme'
import { useNavigate } from 'react-router-dom'

export interface IThemeSlide {
    data: TThemeData
}

const ThemeSlide: React.FunctionComponent<IThemeSlide> = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const themeHelper = new Theme()

    const imageSrc = themeHelper.GetImage('quiz', props.data.themeType)

    const playButtonOnClickHandler = () => {
        navigate('/create?selected=' + props.data._id)
    }

    return (
        <div className={styles.container}>
            <div className={styles.img}>
                <img src={imageSrc} alt='image' />
            </div>
            <div className={styles.content}>
                <div className={styles.title}>
                    <h4>{props.data.name}</h4>
                </div>
                <div className={styles.actions}>
                    <TextButton classes={styles.button} text={t('Buttons.play')} onClick={playButtonOnClickHandler} />
                </div>
            </div>
        </div>
    )
}

export default ThemeSlide