import React, { useContext, useEffect, useState } from 'react'
import SocketContext from '../../../../contexts/Socket/SocketContext'
import styles from './ThemeSelector.module.css'
import { Scrollbars } from 'react-custom-scrollbars-2'
import ThemeCard from '../../../UI/Cards/ThemeCard'
import { useTranslation } from 'react-i18next'
import TextButton from '../../../UI/Buttons/TextButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import DeleteConfirmPopup from '../../../UI/Popup/DeleteConfirmPopup'
import axios from 'axios'
import ThemeSelectorNameInputField from './ThemeSelectorFilter/ThemeSelectorNameInputField'
import ThemeSelectorWhoSelectField from './ThemeSelectorFilter/ThemeSelectorWhoSelectField'
import { TThemeData, TThemeWho, defaultThemeWho, themeListLimit } from '../../../../utils/library'
import LayoutContext from '../../../../contexts/Layout/LayoutContext'
import Loading from '../../../UI/Loading/Loading'

export interface IThemeSelector {
    selectedThemeData: TThemeData | undefined
    setSelectedThemeData: React.Dispatch<React.SetStateAction<TThemeData | undefined>>
    updateThemes: boolean
    setError: React.Dispatch<React.SetStateAction<string>>
    setPopupError: React.Dispatch<React.SetStateAction<boolean>>
}

const ThemeSelector: React.FunctionComponent<IThemeSelector> = (props) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()

    const { socket } = useContext(SocketContext).SocketState
    const layoutDispatch = useContext(LayoutContext).LayoutDispatch

    const [themesData, setThemesData] = useState<TThemeData[]>()

    const [themeIdToDelete, setThemeIdToDelete] = useState<string>()
    const [popupDelete, setPopupDelete] = useState<boolean>(false)

    const [refreshThemes, setRefreshThemes] = useState<boolean>(false)
    const [loadingThemes, setLoadingThemes] = useState<boolean>(true)

    // filter
    const [who, setWho] = useState<TThemeWho>(defaultThemeWho)
    const [searchThemeName, setSearchThemeName] = useState<string>('')
    const [name, setName] = useState<string>('')

    // load more themes
    const [paged, setPaged] = useState<number>(1)
    const [loadingMore, setLoadingMore] = useState<boolean>(true)
    const [noMore, setNoMore] = useState<boolean>(false)

    // first loaded
    const [preloaded, setPreloaded] = useState<boolean>(false)
    const [selected, setSelected] = useState<string>('')

    /**
     * Theme loading:
     * load theme if
     * 1. scroll to bottom
     * 2. param selected is set
     *   a. if selected is not found, popup error & show all themes
     *   b. if selected is found, filter name & set selected the first one & select it
     * 3. filter
     * 
     * FLOW:
     * get selected -> load themes -> set name for the selected -> load themes -> load more themes on scroll
     * filter changed -> load themes -> load more themes on scroll
     */

    // get selected
    useEffect(() => {
        if (!preloaded) {
            const selectedId = searchParams.get('selected')
            if (selectedId) {
                setSelected(selectedId)
                searchParams.delete('selected')
                setSearchParams(searchParams, { replace: true })
            }
            setPreloaded(true)
        }
    }, [searchParams, preloaded])

    // load themes
    useEffect(() => {
        const token = Cookies.get('login_token')
        const auth = token !== undefined ? `Bearer ${token}` : ''

        setLoadingThemes(() => true)

        if (preloaded) {
            axios({
                method: 'get',
                headers: {
                    Authorization: auth
                },
                url: `${process.env.REACT_APP_SERVER}/api/themes?type=${'quiz'}&lang=${i18n.resolvedLanguage}&who=${who}&name=${name}&limit=${themeListLimit}&selected=${selected}`
            }).then((res) => {
                // console.log('load themes', res.data)
                if (res.data) {
                    setThemesData(() => res.data)
                    setNoMore(() => res.data.length < themeListLimit)
                }
            }).catch((e) => {
                setThemesData(() => [])
            }).finally(() => {
                setLoadingThemes(() => false)
            })
            // console.log('load themes')
        }
    }, [socket, props.updateThemes, refreshThemes, name, who, i18n.resolvedLanguage, selected, preloaded])

    // set name for the selected
    const [loaded, setLoaded] = useState<boolean>(false)
    useEffect(() => {
        if (!loaded) {
            if (selected && themesData !== undefined) {
                const selectedData = themesData.find((t) => t._id === selected)
                if (selectedData !== undefined) {
                    props.setSelectedThemeData(() => selectedData)
                    setSearchThemeName(selectedData.name)
                    setName(selectedData.name)
                } else {
                    props.setError(t('Warnings.selected_theme_not_exist'))
                    props.setPopupError(true)
                }
                setLoaded(true)
            }
        }
    }, [selected, themesData, loaded])

    useEffect(() => {
        if (!loadingThemes) {
            setLoadingMore(false)
        }
    }, [loadingThemes])

    // load more themes on scroll
    const scrollbarHandleUpdate = async (values: any) => {
        // console.log(noMore, loadingMore)
        if (!noMore && !loadingMore) {
            const { scrollTop, scrollHeight, clientHeight } = values
            const bottomScrollTop = scrollHeight - clientHeight
            if (bottomScrollTop - 1 <= scrollTop) {
                setLoadingMore(() => true)
                try {
                    const token = Cookies.get('login_token')
                    const auth = token !== undefined ? `Bearer ${token}` : ''
                    const newPaged = paged + 1

                    const res = await axios({
                        method: 'get',
                        headers: {
                            Authorization: auth
                        },
                        url: `${process.env.REACT_APP_SERVER}/api/themes?type=${'quiz'}&lang=${i18n.resolvedLanguage}&who=${who}&name=${name}&limit=${themeListLimit}&paged=${newPaged}&selected=${selected}`
                    })

                    if (res.data && res.data.length !== 0) {
                        // console.log('load more', newPaged, res.data)
                        setThemesData((themeData) => {
                            if (themeData !== undefined) {
                                return [...themeData, ...res.data]
                            } else {
                                return res.data
                            }
                        })
                        setPaged(() => newPaged)
                    }

                    if (res.data.length < themeListLimit) {
                        setNoMore(() => true)
                    }
                } catch (e) {
                    // console.log(e)
                } finally {
                    setLoadingMore(() => false)
                }
            }
        }
    }

    // filter
    const themeWhoOnChangeHandler = () => {
        setSelected('')
        setPaged(1)
        setName(searchThemeName)
    }

    const themeNameOnSubmitHandler = (reset?: boolean) => {
        setSelected('')
        setPaged(1)
        if (reset) {
            setSearchThemeName('')
            setName('')
        } else {
            setName(searchThemeName)
        }
    }

    // form actions
    const themeCreateOnClickHandler = () => {
        navigate('/theme')
    }

    const loginToCreateThemeOnClickHandler = () => {
        navigate('/login')
    }

    // delete action
    const deleteThemeOnConfirmHandler = async () => {
        if (themeIdToDelete !== undefined) {
            const token = Cookies.get('login_token')
            if (token) {
                layoutDispatch({ type: 'update_loading', payload: true })
                try {
                    await axios({
                        method: 'delete',
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        url: `${process.env.REACT_APP_SERVER}/api/themes/${themeIdToDelete}`
                    })
                    if (props.selectedThemeData && props.selectedThemeData._id === themeIdToDelete) {
                        props.setSelectedThemeData(undefined)
                        setSelected('')
                    }
                    setRefreshThemes(() => !refreshThemes)
                } catch (e) {

                }
                setThemeIdToDelete(undefined)
                layoutDispatch({ type: 'update_loading', payload: false })
            }
        }
    }

    const deleteThemeOnCancelHandler = () => {
        setThemeIdToDelete(undefined)
    }

    return (
        <div className={styles.themes}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <span>{t('Labels.select_theme')}</span>
                    {
                        Cookies.get('login_token') &&
                        <TextButton text={t('Buttons.create_theme')} classes={styles.createThemeButton} onClick={themeCreateOnClickHandler} />
                    }
                    {
                        !Cookies.get('login_token') &&
                        <TextButton text={t('Buttons.login_to_create_theme')} classes={styles.createThemeButton} onClick={loginToCreateThemeOnClickHandler} />
                    }
                </div>
                <div className={styles.filter}>
                    <div className={styles.field}>
                        <ThemeSelectorWhoSelectField who={who} setWho={setWho} onChange={themeWhoOnChangeHandler} />
                    </div>
                    <div className={styles.field}>
                        <ThemeSelectorNameInputField setName={setName} searchThemeName={searchThemeName} setSearchThemeName={setSearchThemeName} onSubmit={themeNameOnSubmitHandler} submitted={name === searchThemeName} />
                    </div>
                </div>
                <div className={styles.scrollbarContainer}>
                    {
                        !loadingThemes &&
                        <Scrollbars
                            style={{ height: '100%' }}
                            onUpdate={scrollbarHandleUpdate}
                        >
                            <div className={styles.list}>
                                {
                                    themesData?.map((themeData) => {
                                        return (
                                            <ThemeCard key={themeData._id} isListItem={true} themeData={themeData} selectedThemeData={props.selectedThemeData} setSelectedThemeData={props.setSelectedThemeData} setThemeIdToDelete={setThemeIdToDelete} setPopupDelete={setPopupDelete} />
                                        )
                                    })
                                }
                            </div>
                            <Loading loading={loadingMore} classes={styles.loadingMore} />
                        </Scrollbars>
                    }
                    <Loading loading={loadingThemes} />
                </div>
            </div>
            <DeleteConfirmPopup active={popupDelete} setActive={setPopupDelete} message={t('Messages.confirm_delete_theme')} onConfirm={deleteThemeOnConfirmHandler} onCancel={deleteThemeOnCancelHandler} />
        </div>
    )
}

export default ThemeSelector