import React, { useEffect, useState } from 'react'
import styles from './AnswerButton.module.css'
import { TAnswerResData } from '../../../../../utils/library'

export interface IAnswerButtonProps {
    disabled: boolean
    text: string
    answerResData: TAnswerResData | undefined
    answer: string
    setAnswer: React.Dispatch<React.SetStateAction<string>>
    submitAnswer: (ans: string) => any
}

/**
 * Resize text to fit container
 * @url https://dev.to/jankapunkt/make-text-fit-it-s-parent-size-using-javascript-m40 
 */

const AnswerButton: React.FunctionComponent<IAnswerButtonProps> = (props) => {
    const [value, setValue] = useState<string>()
    const [buttonClass, setButtonClass] = useState<string>('')
    const [buttonSelectClass, setButtonSelectClass] = useState<string>('')

    useEffect(() => {
        setValue(props.text)
    }, [props.text])

    useEffect(() => {
        if (value !== undefined && props.answerResData !== undefined) {
            if (props.answerResData.res.correct) {
                if (props.answer === value) {
                    setButtonClass(styles.correct)
                } else {
                    setButtonClass(styles.hidden)
                }
            } else {
                if (props.answer === value) {
                    setButtonClass(styles.incorrect)
                } else if (props.answerResData.res.answer?.includes(value)) {
                    setButtonClass(styles.correct)
                } else {
                    setButtonClass(styles.hidden)
                }
            }
        }

        if (props.answerResData === undefined) {
            setButtonClass('')
            setButtonSelectClass('')
        }
    }, [value, props.answerResData])


    const answerButtonOnClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        setButtonSelectClass(styles.selected)
        if (value !== undefined) {
            props.setAnswer(() => value)
            props.submitAnswer(value)
        }
    }

    return (
        <div className={`${styles.buttonCell} ${buttonClass}`}>
            <button className={`${styles.button} ${buttonSelectClass}`} disabled={props.disabled} onClick={answerButtonOnClickHandler} value={props.text}>{props.text}</button>
        </div>
    )
}

export default AnswerButton