import React, { createContext } from 'react'
import { Socket } from 'socket.io-client'

export type TConnectStatus = 'connect' | 'connecting' | 'disconnect'

export interface ISocketContextState {
    socket: Socket | undefined,
    maintenanceTime: number | null
    connectStatus: TConnectStatus
}

export const defaultSocketContextState: ISocketContextState = {
    socket: undefined,
    maintenanceTime: null,
    connectStatus: 'connect'
}

export type TSocketContextActions = 'update_socket' | 'update_maintenance' | 'update_connect_status'

export type TSocketContextPayload = Socket | number | null | TConnectStatus

export interface ISocketContextActions {
    type: TSocketContextActions,
    payload: TSocketContextPayload
}

export const SocketReducer = (state: ISocketContextState, action: ISocketContextActions) => {
    // console.log(`Message received - Action: ${action} - Payload: ${action.payload}`)

    switch (action.type) {
        case 'update_socket':
            return { ...state, socket: action.payload as Socket }

        case 'update_maintenance':
            return { ...state, maintenanceTime: action.payload as number | null }

        case 'update_connect_status':
            return { ...state, connectStatus: action.payload as TConnectStatus }

        default:
            return { ...state }
    }
}

export interface ISocketContextProps {
    SocketState: ISocketContextState,
    SocketDispatch: React.Dispatch<ISocketContextActions>
}

const SocketContext = createContext<ISocketContextProps>({
    SocketState: defaultSocketContextState,
    SocketDispatch: () => { }
})

export default SocketContext