import React, { createContext } from 'react'
import { TGameMode, TGameType, TRoomStatus, TThemeData, TUsersInRoom } from '../../utils/library'
import axios from 'axios'
import Cookies from 'js-cookie'

export type TUserContext = {
    avatar?: string | ''
    username?: string | ''
    uid?: string | ''
}

export type TRoomContext = {
    roomId?: string | ''
    roomUId?: string | undefined
    roomName?: string | undefined
    gameType?: TGameType | undefined
    gameMode?: TGameMode | undefined
    themeData?: TThemeData | undefined
    themeSettings?: { [key: string]: any } | undefined
    users?: TUsersInRoom | undefined
    ready?: string[] | undefined
    roomStatus?: TRoomStatus | undefined
}

export interface IUserContextState {
    user: TUserContext
    room: TRoomContext
    kickedout: string
    volume: number
}

export const defaultUserContextState: IUserContextState = {
    user: {
        avatar: '',
        username: '',
        uid: ''
    },
    room: {
        roomId: '',
        roomUId: undefined,
        gameMode: undefined,
        themeData: undefined,
        users: undefined
    },
    kickedout: '',
    volume: 0.6
}

export type TUserContextActions =
    'init_user' |
    'update_username' |
    'update_avatar' |
    'enter_room' |
    'leave_room' |
    'update_ready' |
    'update_users_in_room' |
    'update_users_score' |
    'reset_users_score' |
    'update_room_status' |
    'update_kickedout' |
    'update_volume' |
    'update_theme_settings'

export type TUserContextPayload =
    string |
    string[] |
    number |
    TUserContext |
    TRoomContext |
    TUsersInRoom |
    {
        roomUId: string,
        score: number,
        records: { questionCount?: number, correctCount?: number, totalTimeSpent?: number }
    }

export interface IUserContextActions {
    type: TUserContextActions,
    payload: TUserContextPayload
}

export const UserReducer = (state: IUserContextState, action: IUserContextActions) => {
    // console.log(`User Context - Action: ${action} - Payload: ${action.payload}`)

    let user = {}
    const token = Cookies.get('login_token')

    switch (action.type) {
        case 'init_user':
            user = action.payload as TUserContext
            localStorage.setItem('user', JSON.stringify(user))
            return { ...state, user }

        case 'update_username':
            user = { ...state.user, username: action.payload as string }
            localStorage.setItem('user', JSON.stringify(user))

            if (token) {
                axios({
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    method: 'patch',
                    url: `${process.env.REACT_APP_SERVER}/api/users/me`,
                    data: {
                        name: action.payload as string
                    }
                }).catch((e) => {
                    console.log(e)
                })
            }

            return { ...state, user }

        case 'update_avatar':
            user = { ...state.user, avatar: action.payload as string }
            localStorage.setItem('user', JSON.stringify(user))

            if (token) {
                axios({
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    method: 'patch',
                    url: `${process.env.REACT_APP_SERVER}/api/users/me`,
                    data: {
                        avatar: action.payload as string
                    }
                }).catch((e) => {
                    console.log(e)
                })
            }

            return { ...state, user }

        case 'enter_room':
            return { ...state, room: action.payload as TRoomContext }

        case 'leave_room':
            return { ...state, room: defaultUserContextState.room }

        case 'update_ready':
            return { ...state, room: { ...state.room, ready: action.payload as string[] } }

        case 'update_users_in_room':
            return { ...state, room: { ...state.room, users: action.payload as TUsersInRoom } }

        case 'update_users_score':
            if (state.room.users !== undefined) {
                const data = action.payload as { roomUId: string, score: number, records: { questionCount?: number, correctCount?: number, totalTimeSpent?: number } }

                const uid = Object.keys(state.room.users).find((id) => {
                    return state.room.users !== undefined && state.room.users[id].roomUId === data.roomUId
                })
                if (uid !== undefined) {
                    const newUser = state.room.users[uid]
                    newUser.score = data.score
                    newUser.records = data.records
                    return { ...state, room: { ...state.room, users: { ...state.room.users, [uid]: newUser } } }
                }
            }

            return { ...state }

        case 'reset_users_score':
            if (state.room.users !== undefined) {
                const users = Object.keys(state.room.users)
                    .reduce((obj: TUsersInRoom, key) => {
                        if (state.room.users !== undefined) {
                            const userObj = state.room.users[key]
                            userObj.score = 0
                            userObj.records = {}
                            obj[key] = userObj
                            return obj
                        }
                        return {}
                    }, {})
                return { ...state, room: { ...state.room, users } }
            }
            return { ...state }

        case 'update_room_status':
            return { ...state, room: { ...state.room, roomStatus: action.payload as TRoomStatus } }

        case 'update_kickedout':
            return { ...state, kickedout: action.payload as string }

        case 'update_volume':
            return { ...state, volume: action.payload as number }

        case 'update_theme_settings':
            return { ...state, room: { ...state.room, themeSettings: action.payload as { [key: string]: any } } }

        default:
            return { ...state }
    }
}

export interface IUserContextProps {
    UserState: IUserContextState
    UserDispatch: React.Dispatch<IUserContextActions>
}

const UserContext = createContext<IUserContextProps>({
    UserState: defaultUserContextState,
    UserDispatch: () => { }
})

export default UserContext