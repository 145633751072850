import React, { useContext, useEffect } from 'react'
import InputField from '../../../UI/Fields/InputField'
import UserContext from '../../../../contexts/User/UserContext'
import { useTranslation } from 'react-i18next'
import { usernameMaxLength } from '../../../../utils/library'

export interface IUsernameInputFieldProps {
    username: string | undefined
    setUsername: React.Dispatch<React.SetStateAction<string | undefined>>
    inline?: boolean
}

const UsernameInputField: React.FunctionComponent<IUsernameInputFieldProps> = (props) => {
    const { t } = useTranslation()

    const { user } = useContext(UserContext).UserState

    const [username, setUsername] = [props.username, props.setUsername]

    useEffect(() => {
        setUsername(() => user.username)

        // console.log('update user name')
    }, [user.username])

    /** Update user name */
    const usernameInputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(() => e.target.value)
    }

    return (
        <InputField inline={props.inline} id='username' label={t('Labels.username')} value={username} onChange={usernameInputOnChangeHandler} maxLength={usernameMaxLength} />
    )
}

export default UsernameInputField