import React, { SyntheticEvent, useContext, useEffect, useState } from 'react'
import styles from './Quiz.module.css'
import QuestionView from './QuestionView'
import AnswerView from './AnswerView'
import StartCountdown from './StartCountdown'
import Timer from './Timer'
import SocketContext from '../../../../contexts/Socket/SocketContext'
import UserContext from '../../../../contexts/User/UserContext'
import { useTranslation } from 'react-i18next'
import { TAnswerResData, TQues, defaultQuizTimeLimit, timeLimitValues } from '../../../../utils/library'
import CorrectMessage from './CorrectMessage'
import worker_script from '../../../../utils/webworker1000'
import UserAnswerStatus from './UsersAnswerStatus/UserAnswerStatus'

const timerWorker = new Worker(worker_script)

export interface IQuiz {
    quesList: TQues[] | undefined
    isPhaseEnd: boolean
    setIsPhaseEnd: React.Dispatch<React.SetStateAction<boolean>>
}

const Quiz: React.FunctionComponent<IQuiz> = (props) => {
    const { t } = useTranslation()

    const quesList = props.quesList
    const [resetTimer, setResetTimer] = useState<boolean>(false)
    const [timeLeave, setTimeLeave] = useState<number>(timeLimitValues[defaultQuizTimeLimit])

    const { socket } = useContext(SocketContext).SocketState
    const [readyToStart, setReadyToStart] = useState<boolean>(false)
    const [quesIndex, setQuesIndex] = useState<number>(0)
    const [answer, setAnswer] = useState<string>('')
    const [canAnswer, setCanAnswer] = useState<boolean>(false)
    const [canAnswerCallback, setCanAnswerCallback] = useState<boolean>(true)
    const [answerResData, setAnswerResData] = useState<TAnswerResData>()
    const [scoreChange, setScoreChange] = useState<{ old: number, new: number }>({ old: 0, new: 0 })

    const [currentQues, setCurrentQues] = useState<TQues>()

    const { room } = useContext(UserContext).UserState
    const [totalUsers, setTotalUsers] = useState<number>(0)
    const [answeredUsers, setAnsweredUsers] = useState<{ roomUId: string | undefined, correct: boolean }[]>([])

    const [usersAnswerStatus, setUsersAnswerStatus] = useState<{ name: string, correct: boolean }[]>([])

    useEffect(() => {
        if (quesList !== undefined) {
            setCurrentQues(() => {
                const ques = quesList[quesIndex]
                return ques
            })
        }
    }, [])

    useEffect(() => {
        if (room.themeSettings !== undefined) {
            const timeLimit = room.themeSettings?.quizTimeLimit
            if (timeLimit) {
                setTimeLeave(() => timeLimitValues[timeLimit])
            }
        }
    }, [room.themeSettings])

    // useEffect(() => {
    //     console.log('-------Quiz--------')
    //     console.log(currentQues)
    // }, [currentQues])

    useEffect(() => {
        if (room.users !== undefined) {
            setTotalUsers(Object.keys(room.users).length)
        }
    }, [room.users])

    useEffect(() => {
        socket?.on('next_ques', () => {
            props.setIsPhaseEnd(true)
            timerWorker.postMessage({ turn: 'on' })
        })

        return () => {
            socket?.off('next_ques')
        }
    }, [socket])

    useEffect(() => {
        timerWorker.onmessage = ({ data: { time } }) => {
            if (time >= 4000) {
                props.setIsPhaseEnd(false)
                timerWorker.postMessage({ turn: 'off' })
                resetData()
                if (quesList !== undefined) {
                    setQuesIndex(() => quesIndex + 1)
                    setCurrentQues(() => quesList[quesIndex + 1])
                }
            }
        }
    }, [quesIndex, quesList])

    const resetData = () => {
        setResetTimer(() => !resetTimer)
        setAnswer('')
        setAnswerResData(undefined)
        setAnsweredUsers([])
        setUsersAnswerStatus([])
    }

    if (quesList === undefined) {
        return (
            <div>Error: no question found!</div>
        )
    }

    if (!readyToStart) {
        return (
            <div className={styles.container}>
                <StartCountdown
                    ready={readyToStart}
                    setReady={setReadyToStart} />
            </div>
        )
    }

    if (currentQues !== undefined) {
        return (
            <div className={styles.container}>
                <div className={styles.questionView}>
                    <QuestionView
                        quesIndex={quesIndex}
                        image={currentQues.image}
                        audio={currentQues.audio}
                        question={currentQues.question}
                        quesType={currentQues.type}
                        answerResData={answerResData}
                        setCanAnswer={setCanAnswer}
                        isPhaseEnd={props.isPhaseEnd}
                        afterQuestion={currentQues.afterQuestion}
                        afterImage={currentQues.afterImage} />
                </div>
                <div className={styles.timer}>
                    <Timer
                        resetTimer={resetTimer}
                        canAnswer={canAnswer}
                        setCanAnswer={setCanAnswer}
                        setCanAnswerCallback={setCanAnswerCallback}
                        timeLeave={timeLeave}
                        setTimeLeave={setTimeLeave}
                        setAnswerResData={setAnswerResData}
                        answeredUsers={answeredUsers}
                        setAnsweredUsers={setAnsweredUsers}
                        scoreChange={scoreChange}
                        setScoreChange={setScoreChange} />
                </div>
                <div className={styles.answerView}>
                    <div className={styles.showUserAnswerStatus}>
                        {
                            usersAnswerStatus.map((v, i) => {
                                return (
                                    <UserAnswerStatus name={v.name} correct={v.correct} key={'user_ans_sta_' + i} />
                                )
                            })
                        }
                    </div>
                    <div className={styles.answerInfo}>
                        {answeredUsers.length < totalUsers &&
                            <>
                                <i className={styles.waiting}></i><span className={styles.waitingText}>{t('Messages.waiting')}</span>
                            </>
                        }
                        {`${answeredUsers.length}/${totalUsers}`}
                    </div>
                    <AnswerView
                        ques={currentQues}
                        timeLeave={timeLeave}
                        answer={answer}
                        setAnswer={setAnswer}
                        canAnswer={canAnswer}
                        setCanAnswer={setCanAnswer}
                        canAnswerCallback={canAnswerCallback}
                        answerResData={answerResData}
                        setAnswerResData={setAnswerResData}
                        setAnsweredUsers={setAnsweredUsers}
                        scoreChange={scoreChange}
                        setScoreChange={setScoreChange}
                        setUsersAnswerStatus={setUsersAnswerStatus} />
                </div>
                {
                    answerResData !== undefined &&
                    <CorrectMessage
                        ques={currentQues}
                        answerResData={answerResData}
                        scoreChange={scoreChange} />
                }
            </div>
        )
    }

    return (
        <></>
    )
}

export default Quiz