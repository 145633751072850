import React, { useContext, useState } from 'react'
import TextButton from '../UI/Buttons/TextButton'
import UserContext from '../../contexts/User/UserContext'
import SocketContext from '../../contexts/Socket/SocketContext'
import styles from './EnterRoomForm.module.css'
import AvatarSelector from './Fields/UserFIelds/AvatarSelector'
import UsernameInputField from './Fields/UserFIelds/UsernameInputField'
import ErrorPopup from '../UI/Popup/ErrorPopup'
import { useTranslation } from 'react-i18next'
import RoomPasswordInputField from './Fields/RoomFields/RoomPasswordInputField'

export interface IEnterRoomProps {
    roomId: string
    setJoined: React.Dispatch<React.SetStateAction<boolean>>
    isPasswordProtected: boolean
}

const EnterRoomForm: React.FunctionComponent<IEnterRoomProps> = (props) => {
    const { t } = useTranslation()

    const userContext = useContext(UserContext)
    const socketContext = useContext(SocketContext)

    const { user } = userContext.UserState
    const { socket } = socketContext.SocketState

    const userDispatch = userContext.UserDispatch

    const [usernameInput, setUsernameInput] = useState<string>()
    const [password, setPassword] = useState<string>('')

    /** Error Popup */
    const [error, setError] = useState<string>('')
    const [popupError, setPopupError] = useState<boolean>(false)

    const joinButtonOnClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()

        if (usernameInput === undefined || usernameInput.trim().length <= 0) {
            setError(t('Warnings.require_username'))
            setPopupError(true)
            return
        } else {
            userDispatch({ type: 'update_username', payload: usernameInput.trim() })
        }

        socket?.emit('join_room', { user: { ...user, username: usernameInput }, roomId: props.roomId, password }, (res: any) => {
            if (res?.error === undefined) {
                userDispatch({ type: 'enter_room', payload: res })
                userDispatch({ type: 'update_room_status', payload: 'waiting' })

                props.setJoined(true)
            } else {
                setError(t(res.error))
                setPopupError(true)
            }
        })

        // console.log('button click handler, username: '+usernameInput)
    }

    return (
        <div className={styles.container}>
            <AvatarSelector />
            <UsernameInputField username={usernameInput} setUsername={setUsernameInput} />
            {
                props.isPasswordProtected &&
                <RoomPasswordInputField password={password} setPassword={setPassword} showLabel={true} />
            }
            <div className={styles.buttons}>
                <TextButton id='join-room' classes={styles.button} text={t('Buttons.join')} onClick={joinButtonOnClickHandler} />
            </div>
            {
                popupError &&
                <ErrorPopup active={popupError} setActive={setPopupError} message={error} />
            }
        </div>
    )
}

export default EnterRoomForm