import React from 'react'
import styles from './ArticleSlide.module.css'
import { IArticleListItemData } from '../../../utils/library'
import TextButton from '../Buttons/TextButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export interface IArticleSlide {
    data: IArticleListItemData
}

const ArticleSlide: React.FunctionComponent<IArticleSlide> = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const readButtonOnClickHandler = () => {
        navigate(`/articles/${props.data.slug}`)
    }

    return (
        <div className={styles.container}>
            <div className={styles.img}>
                <img src={props.data.featuredImg} alt='image' />
            </div>
            <div className={styles.content}>
                <div className={styles.title}>
                    <h4>{props.data.title}</h4>
                </div>
                <div className={styles.actions}>
                    <TextButton classes={styles.button} text={t('Buttons.read')} onClick={readButtonOnClickHandler} />
                </div>
            </div>
        </div>
    )
}

export default ArticleSlide