import React, { useState } from 'react'
import styles from './MainHeader.module.css'
import { useNavigate } from 'react-router-dom'
import HeaderMenu from './HeaderMenu'
import TextButton from '../../UI/Buttons/TextButton'
import { useTranslation } from 'react-i18next'
import LanguageSelectField from '../../Forms/Fields/LanguageSelectField'
import MobileMenu from './MobileMenu'

export interface IMainHeader { }

const MainHeader: React.FunctionComponent<IMainHeader> = (props) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const menu = [
        { text: t('Titles.articles'), link: '/articles' },
        { text: t('Titles.rooms'), link: '/rooms' },
        { text: t('Buttons.start_game'), link: '/create' }
    ]

    const [showMenu, setShowMenu] = useState<boolean>(false)

    const LogoOnClickHandler = () => {
        navigate('/')
    }

    const ButtonOnClickHandler = () => {
        navigate('/login')
    }

    const MenuButtonOnClickHandler = () => {
        setShowMenu(true)
    }

    return (
        <>
            <div className={`${styles.container}`}>
                <div className={styles.toolbar}>
                    <LanguageSelectField showLabel={false} short={true} inFilter={true} classes={styles.toolbarItem} />
                    <TextButton text={t('Buttons.login_or_settings')} onClick={ButtonOnClickHandler} classes={styles.toolbarButton} />
                </div>
                <div className={styles.inner}>
                    <div className={styles.left}>
                        <div className={styles.logo} onClick={LogoOnClickHandler}>
                            <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt='logo' />
                        </div>
                    </div>
                    <div className={styles.center}>
                        <HeaderMenu menu={menu} />
                    </div>
                    <div className={styles.right}>
                        <LanguageSelectField showLabel={false} short={true} inFilter={true} />
                        <TextButton text={t('Buttons.login_or_settings')} onClick={ButtonOnClickHandler} />
                    </div>
                    <div className={styles.mobile}>
                        <i className={styles.menuIcon} onClick={MenuButtonOnClickHandler}></i>
                    </div>
                </div>
            </div>
            <MobileMenu menu={menu} show={showMenu} setShow={setShowMenu} />
        </>
    )
}

export default MainHeader