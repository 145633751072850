import React from 'react'
import Popup from './Popup'
import styles from './LeaveRoomPopup.module.css'
import TextButton from '../Buttons/TextButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export interface ILeaveRoomPopupProps {
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
}

const LeaveRoomPopup: React.FunctionComponent<ILeaveRoomPopupProps> = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [active, setActive] = [props.active, props.setActive]

    const confirmButtonOnClickHandler = () => {
        navigate('/login')
        props.setActive(false)
    }

    const cancelButtonOnClickHandler = () => {
        props.setActive(false)
    }

    return (
        <Popup title={t('Titles.leave_room')} active={active} setActive={setActive}>
            <div className={styles.container}>
                <div className={styles.messageContainer}>
                    <p className={styles.message}>{t('Messages.leave_room_confirm')}</p>
                </div>
                <div className={styles.buttons}>
                    <TextButton text={t('Buttons.confirm')} onClick={confirmButtonOnClickHandler} />
                    <TextButton text={t('Buttons.cancel')} onClick={cancelButtonOnClickHandler} />
                </div>
            </div>
        </Popup>
    )
}

export default LeaveRoomPopup