import React, { useEffect, useState } from 'react'
import InputField from '../../../UI/Fields/InputField'
import { useTranslation } from 'react-i18next'

export interface IAdminFormInputFieldProps {
    name: string
    value: string
    label: string
    onChange?: (name: string, value: string) => any
    inline?: boolean
    disabled?: boolean
    tips?: string
}

const AdminFormInputField: React.FunctionComponent<IAdminFormInputFieldProps> = (props) => {
    const { t } = useTranslation()

    const [value, setValue] = useState<string>('')

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const inputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(() => e.target.value)
        if (props.onChange !== undefined) {
            props.onChange(props.name, e.target.value)
        }
    }

    return (
        <InputField inline={props.inline} id={`admin_input_${props.name}`} label={t(props.label)} value={value} onChange={inputOnChangeHandler} disabled={props.disabled} tips={props.tips} />
    )
}

export default AdminFormInputField