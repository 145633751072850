import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './QuestionView.module.css'
import UserContext from '../../../../contexts/User/UserContext'
import { useTranslation } from 'react-i18next'
import worker_script from '../../../../utils/webworker1000'
import { TAnswerResData, TAnswerType } from '../../../../utils/library'

const timerWorker = new Worker(worker_script)

export interface IQuestionView {
    quesIndex: number
    question: string
    quesType: TAnswerType
    image: string | undefined
    audio: string | undefined
    answerResData: TAnswerResData | undefined
    setCanAnswer: React.Dispatch<React.SetStateAction<boolean>>
    isPhaseEnd: boolean
    afterQuestion: string | undefined
    afterImage: string | undefined
}

const QuestionView: React.FunctionComponent<IQuestionView> = (props) => {
    const { t } = useTranslation()

    const userContext = useContext(UserContext)
    const { volume } = userContext.UserState

    // audio
    const audioRef = useRef<HTMLAudioElement>(null)

    const [waiting, setWaiting] = useState<boolean>(true)
    const [playing, setPlaying] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(true)

    const [changeAnime, setChangeAnime] = useState<boolean>(false)

    // after answer
    const [showAfter, setShowAfter] = useState<boolean>(false)
    const [correct, setCorrect] = useState<boolean>()

    useEffect(() => {
        if (audioRef.current) {
            if (playing) {
                audioRef.current.volume = volume
                audioRef.current.play()
            } else {
                audioRef.current.pause()
                audioRef.current.currentTime = 0
            }
        }
    }, [playing, audioRef, volume])

    useEffect(() => {
        if (props.answerResData !== undefined) {
            if (props.answerResData.tooLate === true) {
                // setPlaying(false)
                setDisabled(true)
                setShowAfter(true)
                setCorrect(false)
            } else {
                if (props.quesType === 'select') {
                    // setPlaying(false)
                    setDisabled(true)
                    setShowAfter(true)
                    if (props.answerResData.res.correct) {
                        setCorrect(true)
                    } else {
                        setCorrect(false)
                    }
                } else {
                    if (props.answerResData.timeout || (!props.answerResData.timeout && props.answerResData.res.correct)) {
                        // setPlaying(false)
                        setDisabled(true)
                        setShowAfter(true)
                        if (props.answerResData.res.correct) {
                            setCorrect(true)
                        } else {
                            setCorrect(false)
                        }
                    }
                }
            }
        } else {
            setCorrect(undefined)
            setShowAfter(false)
        }
    }, [props.quesType, props.answerResData])

    useEffect(() => {
        if (props.isPhaseEnd) {
            setPlaying(false)
        }
    }, [props.isPhaseEnd])

    useEffect(() => {
        setChangeAnime(() => !changeAnime)
        timerWorker.postMessage({ turn: 'on' })
    }, [props.quesIndex])

    useEffect(() => {
        timerWorker.onmessage = ({ data: { time } }) => {
            if (time >= 2000) {
                timerWorker.postMessage({ turn: 'off' })
                props.setCanAnswer(true)
                setPlaying(true)
                setWaiting(false)
            } else {
                setWaiting(true)
            }
        }
    }, [])

    const audioOnEndedHandler = () => {
        setPlaying(false)
        setDisabled(false)
    }

    const audioButtonOnClickHandler = () => {
        setPlaying(true)
        setDisabled(true)
    }

    return (
        <>
            {
                (!(props.afterQuestion || props.afterImage !== undefined) || !showAfter) &&
                <div className={`${styles.view} ${correct !== undefined ? (correct === true ? styles.correct : styles.incorrect) : ''}`}>
                    <div key={'questionView1_' + props.quesIndex} className={styles.wrap}>
                        {
                            props.image !== undefined &&
                            <img className={styles.image} src={props.image} alt='Image' />
                        }
                        {
                            props.audio && props.audio !== undefined &&
                            <div className={`${styles.audio} ${props.image !== undefined ? styles.withImage : ''}`}>
                                <audio src={props.audio} ref={audioRef} onEnded={audioOnEndedHandler} />
                                <button className={styles.audioButton} onClick={audioButtonOnClickHandler} disabled={disabled}>
                                    <i className={playing ? styles.playing : styles.replay}></i>{waiting ? t('Buttons.loading') : playing ? t('Buttons.playing') : t('Buttons.replay')}
                                </button>
                            </div>
                        }
                    </div>
                    <div key={'questionView2_' + props.quesIndex} className={styles.question}>
                        <p>{props.question}</p>
                    </div>
                </div>
            }
            {
                (props.afterQuestion || props.afterImage !== undefined) && showAfter &&
                <div className={`${styles.view} ${correct !== undefined ? (correct === true ? styles.correct : styles.incorrect) : ''}`}>
                    <div key={'afterQuestionView2_' + props.quesIndex} className={styles.afterQuestion}>
                        <p>{props.afterQuestion}</p>
                    </div>
                    <div key={'afterQuestionView1_' + props.quesIndex} className={styles.afterWrap}>
                        {
                            props.afterImage !== undefined &&
                            <img className={styles.image} src={props.afterImage} alt='Image' />
                        }
                    </div>
                </div>
            }
            <span key={'questionView3_' + props.quesIndex} className={styles.order}>
                Q{props.quesIndex + 1}
            </span>
        </>
    )
}

export default QuestionView