import React from 'react'
import styles from './ArticlesListPagination.module.css'
import IconButton from '../UI/Buttons/IconButton'
import SelectField from '../UI/Fields/SelectField'

export interface IArticlesListPagination {
    paged: number
    setPaged: React.Dispatch<React.SetStateAction<number>>
    pagesList: number[]
}

const ArticlesListPagination: React.FunctionComponent<IArticlesListPagination> = (props) => {
    const backButtonOnClickHandler = () => {
        props.setPaged(() => props.paged - 1)
    }

    const forwardButtonOnClickHandler = () => {
        props.setPaged(() => props.paged + 1)
    }

    const pagesOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const p = e.target.value
        props.setPaged(parseInt(p))
    }

    return (
        <div className={styles.pagination}>
            <IconButton type='back' disabled={props.paged === 1} onClick={backButtonOnClickHandler} />
            <SelectField id='article-paginations' classes={styles.pages} onChange={pagesOnChangeHandler} value={props.paged.toString()} options={props.pagesList.map((p) => { return { label: p.toString(), value: p.toString() } })} />
            <IconButton type='forward' disabled={props.paged === props.pagesList[props.pagesList.length - 1]} onClick={forwardButtonOnClickHandler} />
        </div>
    )
}

export default ArticlesListPagination