import React from 'react'
import styles from './LoginWithGoogleButton.module.css'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

export interface ILoginWithGoogleButton {
    setLoggingIn: React.Dispatch<React.SetStateAction<boolean>>
}

const LoginWithGoogleButton: React.FunctionComponent<ILoginWithGoogleButton> = (props) => {
    const { t } = useTranslation()

    const loginButtonOnClick = async () => {
        props.setLoggingIn(true)

        try {
            /** Auth step 1: click to get auth url from server */
            const res = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/auth/google/url`
            })

            /** Auth step 3: redirect to the auth url */
            if (res.data !== undefined && res.data.authUrl) {
                window.location.href = res.data.authUrl
            }
        } catch (e) {
            console.log(e)
        }

        props.setLoggingIn(false)
    }

    return (
        <button className={styles.button} onClick={loginButtonOnClick}><i className={styles.googleIcon}></i><span className={styles.text}>{t('Buttons.login_w_google')}</span></button>
    )
}

export default LoginWithGoogleButton