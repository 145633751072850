import React from 'react'
import styles from './Title.module.css'

export interface ITitle {
    title: string
}

const Title: React.FunctionComponent<ITitle> = (props) => {
    return (
        <div className={styles.title}>
            <h2>{props.title}</h2>
        </div>
    )
}

export default Title