import React, { useEffect, useState } from 'react'
import styles from './StartCountdown.module.css'
import worker_script from '../../../../utils/webworker1000'

const timerWorker = new Worker(worker_script)

export interface IStartCountdown {
    ready: boolean
    setReady: React.Dispatch<React.SetStateAction<boolean>>
}

const StartCountdown: React.FunctionComponent<IStartCountdown> = (props) => {
    const go = 'Go'
    const timelimitInSecond = 5
    const [countdown, setCountdown] = useState<number>(timelimitInSecond)
    const [pop, setPop] = useState<boolean>(false)

    useEffect(() => {
        if (!props.ready) {
            timerWorker.postMessage({ turn: 'on' })

            timerWorker.onmessage = ({ data: { time } }) => {
                if (time >= 1000) {
                    timerWorker.postMessage({ turn: 'off' })
                    setCountdown(() => countdown - 1)
                    setPop(() => !pop)
                }
            }
        }

        return () => {
            if (countdown <= 0) {
                props.setReady(true)
            }
        }
    }, [countdown, props.ready])

    return (
        <div className={styles.container}>
            <div className={styles.countdown}>
                <span className={`${styles.number} ${pop ? styles.pop1 : styles.pop2}`}>{countdown === 0 && go || countdown}</span>
            </div>
        </div>
    )
}

export default StartCountdown