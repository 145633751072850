import React, { useContext, useEffect, useState } from 'react'
import styles from './LoadScreen.module.css'
import { useTranslation } from 'react-i18next'
import { TQues } from '../../../utils/library'
import SocketContext from '../../../contexts/Socket/SocketContext'

export interface ILoadScreen {
    gameData: TQues[] | undefined
}

const LoadScreen: React.FunctionComponent<ILoadScreen> = (props) => {
    const { t } = useTranslation()

    const { socket } = useContext(SocketContext).SocketState

    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        // preload images
        const loadImage = (img: string) => {
            return new Promise((resolve, reject) => {
                const loadImg = new Image()
                loadImg.src = img
                loadImg.onload = () => resolve(img)
                loadImg.onerror = err => reject(err)
            })
        }

        if (props.gameData !== undefined) {
            let imgs: string[] = []
            props.gameData.forEach(e => {
                if (e.image !== undefined) {
                    imgs.push(e.image)
                }
                if (e.afterImage !== undefined) {
                    imgs.push(e.afterImage)
                }
            })
            Promise.all(imgs.map(img => loadImage(img)))
                .then(() => setLoaded(true))
                .catch(err => {
                    console.log("Failed to load images", err)
                    setLoaded(true)
                })
        }
    }, [props.gameData, socket])

    useEffect(() => {
        if (loaded === true) {
            socket?.emit('loaded_game')
        }
    }, [loaded, socket])


    return (
        <div className={styles.center}>
            <div className={styles.container}>
                <p>{t('Messages.loading')}</p>
            </div>
        </div>
    )
}

export default LoadScreen