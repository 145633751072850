import React, { useEffect, useState } from 'react'
import styles from './QuestionEditPopup.module.css'
import { useTranslation } from 'react-i18next'
import TextButton from '../../../UI/Buttons/TextButton'
import Popup from '../../../UI/Popup/Popup'
import { TAnswerType, TQuizQuestion, quizAnswerMin, quizOptionsMin, quizOptionsMax, quizQuestionMaxLength, quizQuestionMaxLengthEN, quizAnswerMax, TUseOptionsLib } from '../../../../utils/library'
import QuestionInputField from './QuizFields/QuestionInputField'
import AnswerTypeSelectField from './QuizFields/AnswerTypeSelectField'
import QuestionAnswerMultiInputField from './QuizFields/QuestionAnswerMultiInputField'
import QuestionOptionsMultiInputField from './QuizFields/QuestionOptionsMultiInputField'
import QuestionImageUploadField from './QuizFields/QuestionImageUploadField'
import QuestionAudioUploadField from './QuizFields/QuestionAudioUploadField'
import UseOptionsLibSelectField from './QuizFields/UseOptionsLibSelectField'
import OptionsLibSelectAndEditField from './QuizFields/OptionsLibSelectAndEditField'
import QuizlibEditPopup from './QuizlibEditPopup'
import AfterQuestionImageUploadField from './QuizFields/AfterQuestionImageUploadField'
import AfterQuestionInputField from './QuizFields/AfterQuestionInputField'

export interface IQuestionEditPopup {
    index: number | undefined
    questions: TQuizQuestion[] | undefined
    setQuestions: React.Dispatch<React.SetStateAction<TQuizQuestion[] | undefined>>
    setError: React.Dispatch<React.SetStateAction<string>>
    setPopupError: React.Dispatch<React.SetStateAction<boolean>>
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    lastAnswerType: TAnswerType
    setLastAnswerType: React.Dispatch<React.SetStateAction<TAnswerType>>
    lastUseOptionsLib: TUseOptionsLib
    setLastUseOptionsLib: React.Dispatch<React.SetStateAction<TUseOptionsLib>>
    lastOptionsLibId: string
    setLastOptionsLibId: React.Dispatch<React.SetStateAction<string>>
    optionsLibOptions: { label: string, value: string }[]
    setOptionsLibOptions: React.Dispatch<React.SetStateAction<{ label: string, value: string }[]>>
}

const QuestionEditPopup: React.FunctionComponent<IQuestionEditPopup> = (props) => {
    const { t, i18n } = useTranslation()

    const [id, setId] = useState<string>()
    const [question, setQuestion] = useState<string>()
    const [questionMax, setQuestionMax] = useState<number>(quizQuestionMaxLength)
    const [image, setImage] = useState<string>()
    const [audio, setAudio] = useState<string>()
    const [answerType, setAnswerType] = useState<TAnswerType>(props.lastAnswerType)
    const [answer, setAnswer] = useState<string[]>([])
    const [options, setOptions] = useState<string[]>([])
    const [useOptionsLib, setUseOptionsLib] = useState<TUseOptionsLib>(props.lastUseOptionsLib)
    const [optionsLibId, setOptionsLibId] = useState<string>(props.lastOptionsLibId)
    const [afterQuestion, setAfterQuestion] = useState<string>()
    const [afterImage, setAfterImage] = useState<string>()

    const [popupQuizlib, setPopupQuizlib] = useState<boolean>(false)

    useEffect(() => {
        if (props.questions !== undefined && props.index !== undefined && props.questions[props.index]) {
            const selectedQuestion = props.questions[props.index]
            setId(selectedQuestion._id)
            setQuestion(selectedQuestion.question)
            setImage(selectedQuestion.image)
            setAudio(selectedQuestion.audio)
            setAnswerType(selectedQuestion.type)
            setAnswer(selectedQuestion.answer)
            setOptions(() => selectedQuestion?.options !== undefined ? selectedQuestion.options : [])
            setUseOptionsLib(() => selectedQuestion?.useQuizlib === true ? 'yes' : 'no')
            setOptionsLibId(() => selectedQuestion?.quizlibId !== undefined ? selectedQuestion.quizlibId : '')
            setAfterQuestion(selectedQuestion.afterQuestion)
            setAfterImage(selectedQuestion.afterImage)
        }
    }, [props.index])

    useEffect(() => {
        if (i18n.resolvedLanguage === 'en') {
            setQuestionMax(quizQuestionMaxLengthEN)
        } else {
            setQuestionMax(quizQuestionMaxLength)
        }
    }, [i18n.resolvedLanguage])

    useEffect(() => {
        props.setLastAnswerType(() => answerType)
    }, [answerType])

    useEffect(() => {
        props.setLastUseOptionsLib(() => useOptionsLib)
    }, [useOptionsLib])

    useEffect(() => {
        props.setLastOptionsLibId(() => optionsLibId)
    }, [optionsLibId])

    const confirmButtonOnClickHandler = () => {
        if (!question) {
            props.setError(t('Warnings.require_question'))
            props.setPopupError(true)
            return
        }

        if (question.length > questionMax) {
            props.setError(t('Warnings.question_too_long', { length: question.length, max: questionMax }))
            props.setPopupError(true)
            return
        }

        if (answer.length > quizAnswerMax) {
            props.setError(t('Warnings.too_many_answers', { max: quizAnswerMax }))
            props.setPopupError(true)
            return
        }

        if (answer.length < quizAnswerMin) {
            props.setError(t('Warnings.require_answer'))
            props.setPopupError(true)
            return
        }

        if (answerType === 'select' && useOptionsLib === 'no' && options.length < quizAnswerMin) {
            props.setError(t('Warnings.require_options'))
            props.setPopupError(true)
            return
        }

        if (answerType === 'select' && useOptionsLib === 'no' && options.length > quizOptionsMax) {
            props.setError(t('Warnings.multiinput_invalid_add', { label: t('Labels.options'), max: quizOptionsMax }))
            props.setPopupError(true)
            return
        }

        if (answerType === 'select' && useOptionsLib === 'no' && options.length < quizOptionsMin) {
            props.setError(t('Warnings.multiinput_not_enough', { label: t('Labels.options'), min: quizOptionsMin }))
            props.setPopupError(true)
            return
        }

        if (answerType === 'select' && useOptionsLib === 'yes' && !optionsLibId) {
            props.setError(t('Warnings.require_options_lib'))
            props.setPopupError(true)
            return
        }

        if (props.index !== undefined) {
            /** Update questions */
            if (props.questions !== undefined && props.index !== undefined && props.questions[props.index]) {
                const questions = props.questions
                const index = props.index
                props.setQuestions(() => {
                    return [
                        ...questions.slice(0, index),
                        {
                            _id: id,
                            question,
                            image,
                            audio,
                            type: answerType,
                            answer,
                            options,
                            useQuizlib: useOptionsLib === 'yes' ? true : false,
                            quizlibId: optionsLibId === '' ? undefined : optionsLibId,
                            afterQuestion,
                            afterImage
                        },
                        ...questions.slice(index + 1)
                    ]
                })
            }
        } else {
            /** Append question */
            props.setQuestions(() => {
                let questions: TQuizQuestion[] = []
                if (props.questions !== undefined) {
                    questions = [...props.questions]
                }
                questions.push({
                    question,
                    image,
                    audio,
                    type: answerType,
                    answer,
                    options,
                    useQuizlib: useOptionsLib === 'yes' ? true : false,
                    quizlibId: optionsLibId === '' ? undefined : optionsLibId,
                    afterQuestion,
                    afterImage
                })
                return questions
            })
        }
        props.setActive(false)
    }

    const cancelButtonOnClickHandler = () => {
        props.setActive(false)
    }

    return (
        <Popup title={t('Titles.question')} active={props.active} setActive={props.setActive}>
            <div className={styles.container}>
                <div className={styles.form}>
                    <div className={styles.left}>
                        <QuestionInputField question={question} setQuestion={setQuestion} max={questionMax} inline={true} />
                        <QuestionImageUploadField image={image} setImage={setImage} inline={true} />
                        <QuestionAudioUploadField audio={audio} setAudio={setAudio} inline={true} />
                        <AfterQuestionInputField afterQuestion={afterQuestion} setAfterQuestion={setAfterQuestion} max={questionMax} inline={true} />
                        <AfterQuestionImageUploadField image={afterImage} setImage={setAfterImage} inline={true} />
                    </div>
                    <div className={styles.right}>
                        <AnswerTypeSelectField answerType={answerType} setAnswerType={setAnswerType} inline={true} showLabel={true} />
                        {
                            answerType === 'select' &&
                            <UseOptionsLibSelectField useOptionsLib={useOptionsLib} setUseOptionsLib={setUseOptionsLib} inline={true} showLabel={true} />
                        }
                        {
                            answerType === 'select' && useOptionsLib === 'yes' &&
                            <OptionsLibSelectAndEditField options={props.optionsLibOptions} optionsLibId={optionsLibId} setOptionsLibId={setOptionsLibId} inline={true} showLabel={true} setPopupQuizlib={setPopupQuizlib} />
                        }
                        <QuestionAnswerMultiInputField answer={answer} setAnswer={setAnswer} inline={true} setError={props.setError} setPopupError={props.setPopupError} />
                        {
                            answerType === 'select' && useOptionsLib === 'no' &&
                            <QuestionOptionsMultiInputField options={options} setOptions={setOptions} answer={answer} inline={true} setError={props.setError} setPopupError={props.setPopupError} />
                        }
                    </div>
                </div>
                <div className={styles.buttons}>
                    <TextButton id='question-edit-confirm' text={t('Buttons.confirm')} onClick={confirmButtonOnClickHandler} />
                    <TextButton id='question-edit-cancel' text={t('Buttons.cancel')} onClick={cancelButtonOnClickHandler} />
                </div>
            </div>
            <QuizlibEditPopup id={optionsLibId} setId={setOptionsLibId} setError={props.setError} setPopupError={props.setPopupError} active={popupQuizlib} setActive={setPopupQuizlib} setOptionsLibOptions={props.setOptionsLibOptions} questions={props.questions} />
        </Popup>
    )
}

export default QuestionEditPopup