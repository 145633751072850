import React from 'react'
import { useTranslation } from 'react-i18next'
import TextareaField from '../../../../UI/Fields/TextareaField'

export interface IQuestionInputFieldProps {
    question: string | undefined
    setQuestion: React.Dispatch<React.SetStateAction<string | undefined>>
    max: number
    inline?: boolean
    tips?: string
}

const QuestionInputField: React.FunctionComponent<IQuestionInputFieldProps> = (props) => {
    const { t } = useTranslation()

    const questionInputOnChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.setQuestion(() => e.target.value)
    }

    return (
        <TextareaField inline={props.inline} id='question-input' label={t('Labels.question')} value={props.question} onChange={questionInputOnChangeHandler} maxLength={props.max} rows={3} tips={props.tips} />
    )
}

export default QuestionInputField