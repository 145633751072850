import React, { Fragment } from 'react'
import styles from './QuizQuestionsMultiSelectItem.module.css'
import { TQuizQuestion } from '../../../../../../utils/library'

export interface IQuizQuestionsMultiSelectItem {
    index: number
    selected: boolean
    selectedQs: number[]
    setSelectedQs: React.Dispatch<React.SetStateAction<number[]>>
    question: TQuizQuestion
}

const QuizQuestionsMultiSelectItem: React.FunctionComponent<IQuizQuestionsMultiSelectItem> = (props) => {
    const questionOnClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!props.selectedQs.includes(props.index)) {
            props.setSelectedQs(() => [...props.selectedQs, props.index])
        } else {
            props.setSelectedQs(() => props.selectedQs.filter((s) => s !== props.index))
        }
    }

    return (
        <div className={styles.container} onClick={questionOnClickHandler}>
            <i className={`${styles.checkbox} ${props.selected ? styles.checked : ''}`}></i>
            <div className={styles.content}>
                <div className={styles.question}>
                    <span className={styles.text}>{props.question.question}</span>
                </div>
                <div className={styles.answer}>
                    <span className={styles.text}>{props.question.answer.map((a, i) => {
                        const answerLength = props.question.answer.length
                        return (
                            <Fragment key={'ans_' + i}>
                                {a}
                                {i < answerLength - 1 && <i className={styles.sep}> / </i>}
                            </Fragment>
                        )
                    })}</span>
                </div>
            </div>
        </div>
    )
}

export default QuizQuestionsMultiSelectItem