import React, { useContext, useState } from 'react'
import styles from './CreateRandomRoomPopup.module.css'
import Popup from '../UI/Popup/Popup'
import { useTranslation } from 'react-i18next'
import TextButton from '../UI/Buttons/TextButton'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../contexts/User/UserContext'
import SocketContext from '../../contexts/Socket/SocketContext'
import ErrorPopup from '../UI/Popup/ErrorPopup'

export interface ICreateRandomRoomPopup {
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    setError: React.Dispatch<React.SetStateAction<string>>
    setPopupError: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateRandomRoomPopup: React.FunctionComponent<ICreateRandomRoomPopup> = (props) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const userContext = useContext(UserContext)
    const { user } = userContext.UserState
    const userDispatch = userContext.UserDispatch
    const { socket } = useContext(SocketContext).SocketState

    const createRandomRoomButtonOnClickHandler = () => {
        const language = i18n.resolvedLanguage

        socket?.emit('create_random_room', { user, language }, (res: any) => {
            if (res?.error === undefined) {
                userDispatch({ type: 'enter_room', payload: res })
                userDispatch({ type: 'update_room_status', payload: 'waiting' })

                navigate(`/rooms/${res.roomId}`)
            } else {
                props.setError(t(res.error))
                props.setPopupError(true)
            }
        })
        props.setActive(false)
    }

    const createRoomButtonOnClickHandler = () => {
        navigate('/create')
        props.setActive(false)
    }

    return (
        <Popup title={t('Titles.warning')} active={props.active} setActive={props.setActive}>
            <div className={styles.container}>
                <div className={styles.messageContainer}>
                    <p className={styles.message}>{t('Warnings.no_room')}</p>
                </div>
                <div className={styles.buttons}>
                    <TextButton text={t('Buttons.create_random')} onClick={createRandomRoomButtonOnClickHandler} />
                    <TextButton text={t('Buttons.create')} onClick={createRoomButtonOnClickHandler} />
                </div>
            </div>
        </Popup>
    )
}

export default CreateRandomRoomPopup