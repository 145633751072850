import React from 'react'
import styles from './Section.module.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export interface ISection {
    children: React.ReactNode
    title?: string
    viewmoreLink?: string
    hide?: boolean
}

const Section: React.FunctionComponent<ISection> = (props) => {
    const { t } = useTranslation()

    return (
        <div className={`${styles.section} ${props.hide === true ? styles.hide : ''}`}>
            {
                props.title !== undefined &&
                <div className={styles.sectionTitle}>
                    <h3>{props.title}</h3>
                    {
                        props.viewmoreLink !== undefined &&
                        <Link className={styles.viewmore} to={props.viewmoreLink}>{t('Texts.view_more')}</Link>
                    }
                </div>
            }
            {
                props.children
            }
        </div>
    )
}

export default Section