import React from 'react'
import styles from './TextButtonField.module.css'
import TextButton from '../Buttons/TextButton'

export interface ITextButtonField {
    id: string
    buttonText: string
    label?: string
    inline?: boolean
    classes?: string
    disabled?: boolean
    onClick: () => any
    tips?: string
}

const TextButtonField: React.FunctionComponent<ITextButtonField> = (props) => {
    return (
        <div className={`${styles.field} ${props.inline === true ? styles.inline : ''} ${props.classes !== undefined ? props.classes : ''} ${props.disabled === true ? styles.disabled : ''}`}>
            {
                props.label !== undefined &&
                <label className={styles.label}>{props.label}</label>
            }
            <div className={styles.container}>
                <TextButton text={props.buttonText} onClick={props.onClick} disabled={props.disabled} />
                {
                    props.tips !== undefined &&
                    <p className={styles.tips}>{props.tips}</p>
                }
            </div>
        </div>
    )
}

export default TextButtonField