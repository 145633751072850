import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import RootLayout from './pages/RootLayout'
import RoomsPage from './pages/RoomsPage'
import CreatePage from './pages/CreatePage'
import SingleRoomPage from './pages/SingleRoomPage'
import ErrorPage from './pages/ErrorPage'
import './i18n/config'
import AuthGoogleCallbackPage from './pages/AuthGoogleCallbackPage'
import ThemePage from './pages/ThemePage'
import PrivacyPage from './pages/PrivacyPage'
import TermsOfServicePage from './pages/TermsOfServicePage'
import AdminPage from './pages/AdminPage'
import MaintenancePage from './pages/MaintenancePage'
import ArticleEditPage from './pages/ArticleEditPage'
import EmptyPage from './pages/EmptyPage'
import SingleArticlePage from './pages/SingleArticlePage'
import ArticlesPage from './pages/ArticlesPage'
import LandingPage from './pages/LandingPage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <EmptyPage />,
    children: [
      {
        index: true,
        element: <LandingPage />
      },
      {
        path: 'login',
        element: <LoginPage />
      },
      {
        path: 'rooms',
        element: <RoomsPage />,
      },
      {
        path: 'rooms/:roomId',
        element: <SingleRoomPage />
      },
      {
        path: 'create',
        element: <CreatePage />
      },
      {
        path: 'theme',
        element: <ThemePage />
      },
      {
        path: 'theme/:themeId',
        element: <ThemePage />
      },
      {
        path: 'article-edit',
        element: <ArticleEditPage />
      },
      {
        path: 'article-edit/:articleId',
        element: <ArticleEditPage />
      },
      {
        path: 'articles',
        element: <ArticlesPage />
      },
      {
        path: 'articles/:slug',
        element: <SingleArticlePage />
      },
      {
        path: 'authgooglecallback',
        element: <AuthGoogleCallbackPage />
      },
      {
        path: 'authtwittercallback'
      },
      {
        path: 'privacy',
        element: <PrivacyPage />
      },
      {
        path: 'terms-of-service',
        element: <TermsOfServicePage />
      },
      {
        path: 'admin',
        element: <AdminPage />
      },
      {
        path: 'maintenance',
        element: <MaintenancePage />
      },
      {
        path: 'error',
        element: <ErrorPage />
      }
    ]
  }
])

export interface IAppProps { }

const App: React.FunctionComponent<IAppProps> = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default App;
