import React from 'react'
import CreateForm from '../components/Forms/CreateForm'
import styles from './CreatePage.module.css'
import PageTitle from '../components/Widgets/Title/PageTitle'
import { useTranslation } from 'react-i18next'
import Header from '../components/Widgets/Header/Header'

const CreatePage: React.FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <>
            <Header size='medium' />
            <div className={styles.container}>
                <PageTitle title={t('Titles.create')} backPath='/rooms' />
                <CreateForm />
            </div>
        </>
    )
}

export default CreatePage