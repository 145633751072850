import React from 'react'
import styles from './ErrorPage.module.css'
import { useNavigate } from 'react-router-dom'
import TextButton from '../components/UI/Buttons/TextButton'
import { useTranslation } from 'react-i18next'
import PageTitle from '../components/Widgets/Title/PageTitle'

const ErrorPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const backButtonOnClickHandler = () => {
        navigate('/')
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <PageTitle title={t('Titles.404')} />
                <div className={styles.messageContainer}>
                    <p className={styles.message}>{t('Messages.404')}</p>
                </div>
                <div className={styles.buttons}>
                    <TextButton text={t('Buttons.back')} onClick={backButtonOnClickHandler} />
                </div>
            </div>
        </div>
    )
}

export default ErrorPage