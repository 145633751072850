import React from 'react'
import { useTranslation } from 'react-i18next'
import TextareaField from '../../../UI/Fields/TextareaField'

export interface IArticleExcerptTextAreaProps {
    articleExcerpt: string
    setArticleExcerpt: React.Dispatch<React.SetStateAction<string>>
    max?: number
    inline?: boolean
    tips?: string
}

const ArticleExcerptTextArea: React.FunctionComponent<IArticleExcerptTextAreaProps> = (props) => {
    const { t } = useTranslation()

    const textareaOnChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.setArticleExcerpt(() => e.target.value)
    }

    return (
        <TextareaField
            inline28={props.inline}
            leftAlign={true}
            id='article-excerpt'
            label={t('Labels.excerpt')}
            value={props.articleExcerpt}
            onChange={textareaOnChangeHandler}
            maxLength={props.max}
            rows={3}
            tips={props.tips} />
    )
}

export default ArticleExcerptTextArea