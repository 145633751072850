import React from 'react'
import FileUploadField from '../../../../UI/Fields/FileUploadField'
import { DropzoneOptions } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

export interface IQuestionImageUploadField {
    image: string | undefined
    setImage: React.Dispatch<React.SetStateAction<string | undefined>>
    inline?: boolean
}

const QuestionImageUploadField: React.FunctionComponent<IQuestionImageUploadField> = (props) => {
    const { t } = useTranslation()

    const options: DropzoneOptions = {
        accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg']
        },
        maxSize: 1000000 // = 1MB
    }

    return (
        <FileUploadField id={'question-image-upload'} options={options} file={props.image} setFile={props.setImage} fileType='image' label={t('Labels.image')} inline={props.inline} tips={t('Messages.image_tips')} />
    )
}

export default QuestionImageUploadField