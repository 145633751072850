import React from 'react'
import styles from './ArticlesPage.module.css'
import { useTranslation } from 'react-i18next'
import ArticlesList from '../components/Articles/ArticlesList'

const ArticlesPage: React.FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.wrap}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <h1>{t('Titles.articles')}</h1>
                </div>
                <div className={styles.content}>
                    <ArticlesList />
                </div>
            </div>
        </div>
    )
}

export default ArticlesPage