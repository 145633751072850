import React, { useEffect, useRef } from 'react'
import styles from './MultiInputField.module.css'
import { useTranslation } from 'react-i18next'

export interface IMultiInputField {
    label?: string
    values: string[]
    setValues: React.Dispatch<React.SetStateAction<string[]>>
    fixedValues?: string[]
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    id: string
    placeholder?: string
    inline?: boolean
    maxLength?: number
    classes?: string
    disabled?: boolean
    onInvalidAdd?: () => any
    max?: number
    gridStyles?: boolean
    showCount?: boolean
}

const MultiInputField: React.FunctionComponent<IMultiInputField> = (props) => {
    const { t } = useTranslation()

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (props.fixedValues) {
            const valuesToAdd = props.fixedValues.filter((v) => !props.values.includes(v))
            props.setValues(() => [...valuesToAdd, ...props.values])
        }
    }, [props.fixedValues])

    const addButtonOnClickHandler = () => {
        add()
    }

    const inputOnKeyDownHandler = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            add()
        }
    }

    const add = () => {
        if (props.onInvalidAdd && props.max) {
            const valid = props.values.length < props.max
            if (!valid) return props.onInvalidAdd()
        }

        if (inputRef.current) {
            const val = inputRef.current.value.trim()
            if (!val || props.values.includes(val)) return
            props.setValues(() => [...props.values, val])
            inputRef.current.value = ''
            inputRef.current.focus()
        }
    }

    const deleteIconOnClickHandler: React.MouseEventHandler<HTMLElement> = (e) => {
        const index = e.currentTarget.getAttribute('data-index')
        if (index && props.values[parseInt(index)]) {
            props.setValues(() => props.values.filter((v, i) => i !== parseInt(index)))
        }
    }

    return (
        <div className={`${styles.field} ${props.inline === true ? styles.inline : ''} ${props.classes !== undefined ? props.classes : ''}`}>
            <div className={styles.add}>
                {
                    props.label !== undefined &&
                    <label className={styles.label} htmlFor={props.id}>{props.label}</label>
                }
                <div className={styles.buttonInput}>
                    <input ref={inputRef} id={props.id} className={`${styles.input}`} type='text' placeholder={props.placeholder} onChange={props.onChange} maxLength={props.maxLength} onKeyDown={inputOnKeyDownHandler} disabled={props.disabled === true ? true : false} />
                    <button className={styles.addButton} onClick={addButtonOnClickHandler}>+</button>
                </div>
            </div>
            <div className={`${styles.list} ${props.gridStyles ? styles.gridStyles : ''}`}>
                {
                    props.values &&
                    props.values.map((v, i) => {
                        return (
                            <div className={styles.item} key={'multiinput_' + props.id + '_' + i}>
                                <span className={styles.text}>{v}</span>
                                {
                                    (!props.fixedValues || !props.fixedValues.includes(v)) &&
                                    <i className={styles.deleteIcon} onClick={deleteIconOnClickHandler} data-index={i}></i>
                                }
                            </div>
                        )
                    })
                }
                {
                    props.values.length === 0 &&
                    <div className={`${styles.item} ${styles.message}`} key={'multiinput_' + props.id + '_null'}>
                        <span className={styles.text}>{t('Messages.multiinput_add')}</span>
                    </div>
                }
            </div>
            {
                props.showCount === true &&
                <div className={styles.count}>
                    <span className={styles.number}>{`[${props.values.length}]`}</span>
                </div>
            }
        </div>
    )
}

export default MultiInputField