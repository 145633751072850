import React from 'react'
import styles from './Arrow.module.css'
import { CustomArrowProps } from 'react-slick'
import IconButton from '../Buttons/IconButton'

const NextArrow: React.FunctionComponent<CustomArrowProps> = (props) => {
    const { className, onClick } = props
    return (
        <div className={`${styles.arrowBG} ${styles.nextBG} ${className}`}>
            <IconButton type='forward' classes={`${styles.arrowIcon} ${styles.nextArrowIcon}`} onClick={onClick} />
        </div>
    )
}

export default NextArrow