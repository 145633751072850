import React from 'react'
import SelectField from '../../../../UI/Fields/SelectField'
import { useTranslation } from 'react-i18next'
import { TUseOptionsLib, useOptionsLibOptions, useOptionsLibs } from '../../../../../utils/library'

export interface IUseOptionsLibSelectField {
    useOptionsLib: TUseOptionsLib
    setUseOptionsLib: React.Dispatch<React.SetStateAction<TUseOptionsLib>>
    inline?: boolean
    showLabel: boolean
}

const UseOptionsLibSelectField: React.FunctionComponent<IUseOptionsLibSelectField> = (props) => {
    const { t } = useTranslation()

    const options = useOptionsLibOptions

    const useOptionsLibOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value as TUseOptionsLib
        if (useOptionsLibs.includes(val)) {
            props.setUseOptionsLib(val)
        }
    }

    return (
        <SelectField inline={props.inline} id='useoptionslib-select' options={options} onChange={useOptionsLibOnChangeHandler} value={props.useOptionsLib} label={props.showLabel ? t('Labels.use_options_lib') : undefined} translate={true} />
    )
}

export default UseOptionsLibSelectField