import React from 'react'
import styles from './HowToPlaySection.module.css'
import Section from './Section'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export interface IHowToPlaySection { }

const HowToPlaySection: React.FunctionComponent<IHowToPlaySection> = (props) => {
    const { t } = useTranslation()

    return (
        <Section title={t('Titles.how_to_play')}>
            <div className={styles.steps}>
                <div className={styles.step}>
                    <span>1</span>
                    <div className={styles.content}>
                        <div className={styles.text}>
                            <p>{<Trans i18nKey={'Messages.how_to_play_step1'} components={[<Link to='/rooms' />]} />}</p>
                            <p>{<Trans i18nKey={'Messages.how_to_play_step1_2'} components={[<Link to='/create' />]} />}</p>
                        </div>
                        <div className={styles.icon}>
                            <i className={styles.joinIcon}></i>
                        </div>
                    </div>
                </div>
                <div className={styles.step}>
                    <span>2</span>
                    <div className={styles.content}>
                        <div className={styles.text}>
                            <p>{t('Messages.how_to_play_step2')}</p>
                            <p>{t('Messages.how_to_play_step2_2')}</p>
                        </div>
                        <div className={styles.icon}>
                            <i className={styles.shareIcon}></i>
                        </div>
                    </div>
                </div>
                <div className={styles.step}>
                    <span>3</span>
                    <div className={styles.content}>
                        <div className={styles.text}>
                            <p>{t('Messages.how_to_play_step3')}</p>
                        </div>
                        <div className={styles.icon}>
                            <i className={styles.playIcon}></i>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default HowToPlaySection