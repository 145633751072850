import React from 'react'
import styles from './MessageSection.module.css'
import Section from './Section'
import { useTranslation } from 'react-i18next'

export interface IMessageSection { }

const MessageSection: React.FunctionComponent<IMessageSection> = (props) => {
    const { t } = useTranslation()

    return (
        <Section>
            <div className={styles.message}>
                <p>{t('Messages.more_games_are_coming')}</p>
            </div>
        </Section>
    )
}

export default MessageSection