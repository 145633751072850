import React from 'react'
import styles from './TextButton.module.css'

export interface ITextButtonProps {
    id?: string
    text: string
    classes?: string
    disabled?: boolean
    onClick?: (e: React.MouseEvent<HTMLElement>) => any
}

const TextButton: React.FunctionComponent<ITextButtonProps> = (props) => {
    let styleClass = props.classes !== undefined ? props.classes : ''

    return (
        <button id={props.id} className={`${styleClass}`} disabled={props.disabled} onClick={props.onClick}>{props.text}</button>
    )
}

export default TextButton