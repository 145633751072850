import React from 'react'
import SelectField from '../../../../UI/Fields/SelectField'
import { langOptions } from '../../../../../i18n/settings'
import { useTranslation } from 'react-i18next'

export interface IQuizLanguageSelectField {
    lang: string | undefined
    setLang: React.Dispatch<React.SetStateAction<string | undefined>>
    inline?: boolean
    showLabel: boolean
}

const QuizLanguageSelectField: React.FunctionComponent<IQuizLanguageSelectField> = (props) => {
    const { t } = useTranslation()

    const options = Object.keys(langOptions).map((k) => {
        const opt: { label: string, value: string } = {
            label: langOptions[k],
            value: k
        }
        return opt
    })

    const selectOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value
        props.setLang(val)
    }

    return (
        <SelectField inline={props.inline} id='quiz-lang-select' options={options} onChange={selectOnChangeHandler} value={props.lang} label={props.showLabel ? t('Labels.language') : undefined} />
    )
}

export default QuizLanguageSelectField