import React, { useContext } from 'react'
import styles from './GameDetails.module.css'
import UserContext from '../../../contexts/User/UserContext'
import ThemeCard from '../../UI/Cards/ThemeCard'
import { useTranslation } from 'react-i18next'
import { gameModesOptions } from '../../../utils/library'

export interface IGameDetails { }

const GameDetails: React.FunctionComponent<IGameDetails> = () => {
    const { t } = useTranslation()

    const { room } = useContext(UserContext).UserState

    const settingsToShow = ['quesNum', 'quizTimeLimit']
    const labels: { [key: string]: string } = { quesNum: t('Labels.questions_number'), quizTimeLimit: t('Labels.timelimit') }
    const trans: { [key: string]: string } = { quesNum: '', quizTimeLimit: 'TimeLimits' }

    if (room.themeData !== undefined && room.gameType !== undefined) {
        const gameModeOptions = gameModesOptions[room.gameType]
        const gameMode = gameModeOptions?.find((obj) => obj.value === room.gameMode)?.label
        const themeSettings = room.themeSettings
        const hasAudio = room.themeData.questionTypes.includes('audio')

        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.theme}>
                        <ThemeCard themeData={room.themeData} />
                    </div>
                    <div className={styles.settings}>
                        <h4>{gameMode !== undefined && t(gameMode)}</h4>
                        {
                            themeSettings !== undefined &&
                            Object.keys(themeSettings).filter((k) => settingsToShow.includes(k)).length > 0 &&
                            <div className={styles.metas}>
                                {
                                    Object.entries(themeSettings).map(([k, v]) => {
                                        return settingsToShow.includes(k) &&
                                            <div key={`meta_${k}`} className={styles.meta}>
                                                <span className={styles.label}>{labels[k] + ' :'}</span>
                                                <span className={styles.value}>{trans[k] === '' ? v : t(trans[k] + '.' + v)}</span>
                                            </div>
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
                {
                    hasAudio &&
                    <div className={styles.notes}>
                        <p className={styles.message}>{t('Messages.theme_unmute')}</p>
                    </div>
                }
            </div>
        )
    } else {
        return (
            <></>
        )
    }

}

export default GameDetails