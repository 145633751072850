import React from 'react'
import FileUploadField from '../../../../UI/Fields/FileUploadField'
import { DropzoneOptions } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

export interface IQuestionAudioUploadField {
    audio: string | undefined
    setAudio: React.Dispatch<React.SetStateAction<string | undefined>>
    inline?: boolean
}

const QuestionAudioUploadField: React.FunctionComponent<IQuestionAudioUploadField> = (props) => {
    const { t } = useTranslation()

    const options: DropzoneOptions = {
        accept: {
            'audio/mp3': ['.mp3']
        },
        maxSize: 1000000 // = 1MB
    }

    return (
        <FileUploadField id={'question-audio-upload'} options={options} file={props.audio} setFile={props.setAudio} fileType='audio' label={t('Labels.audio')} inline={props.inline} tips={t('Messages.audio_tips')} />
    )
}

export default QuestionAudioUploadField