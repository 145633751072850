import React, { useContext, useEffect, useState } from 'react'
import styles from './AuthGoogleCallbackPage.module.css'
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import UserContext from '../contexts/User/UserContext'
import Cookies from 'js-cookie'
import Loading from '../components/UI/Loading/Loading'

export interface IAuthGoogleCallbackPage { }

const AuthGoogleCallbackPage: React.FunctionComponent<IAuthGoogleCallbackPage> = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(true)

    const userContext = useContext(UserContext)
    const { user } = userContext.UserState
    const userDispatch = userContext.UserDispatch

    const [searchParams] = useSearchParams()

    const [fetched, setFetched] = useState<boolean>(false)
    useEffect(() => {
        if (searchParams && axios && user.username && user.avatar && !fetched) {
            setFetched(true)

            /** Auth step 4: get code and send back to server authorize */
            const code = searchParams.get('code')

            axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER}/api/auth/google/login`,
                data: { code, avatar: user.avatar }
            }).then((res) => {
                /** Auth step 6: save token to cookies */
                if (res.data.token && res.data.user) {
                    Cookies.set('login_token', res.data.token, { expires: 30 })

                    if (res.data.user.name && user.username !== res.data.user.name) {
                        userDispatch({ type: 'update_username', payload: res.data.user.name })
                    }

                    if (res.data.user.avatar && user.avatar !== res.data.user.avatar) {
                        userDispatch({ type: 'update_avatar', payload: res.data.user.avatar })
                    }

                    navigate('/login')
                } else {
                    navigate('/error')
                }
            }).catch((e) => {
                // console.log(e)
                navigate('/error')
            })
        }
    }, [searchParams, axios, user.username, user.avatar, fetched])

    return (
        <Loading loading={true} size='full' />
    )
}

export default AuthGoogleCallbackPage