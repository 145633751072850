import { useContext, useEffect, useReducer, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import SocketContext from '../contexts/Socket/SocketContext'
import { validate as uuidValidate } from 'uuid'
import styles from './RootLayout.module.css'
import UserContext from '../contexts/User/UserContext'
import LayoutContext, { LayoutReducer, defaultLayoutContextState } from '../contexts/Layout/LayoutContext'
import ErrorPopup from '../components/UI/Popup/ErrorPopup'
import { useTranslation } from 'react-i18next'
import worker_script from '../utils/webworker1000'
import MainHeader from '../components/Widgets/Header/MainHeader'
import MainFooter from '../components/Widgets/Footer/MainFooter'
import Loading from '../components/UI/Loading/Loading'

const RootLayout = () => {
    const navigate = useNavigate()

    const timerWorker = new Worker(worker_script)
    const [LayoutState, LayoutDispatch] = useReducer(LayoutReducer, defaultLayoutContextState)

    const { socket, maintenanceTime, connectStatus } = useContext(SocketContext).SocketState
    const userContext = useContext(UserContext)
    const { kickedout } = userContext.UserState
    const userDispatch = userContext.UserDispatch

    const [popupKickedout, setPopupKickedout] = useState<boolean>(false)
    const [kickedoutMessage, setKickedoutMessage] = useState<string>('')

    const location = useLocation()
    const { t } = useTranslation()

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ])

    const [maintenanceMsg, setMaintenanceMsg] = useState<string>()

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight])
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    const [showMainWidgets, setShowMainWidgets] = useState<boolean>(false)

    useEffect(() => {
        const showMainWidgetsPages = ['/', '/articles']
        const showMainPostType = ['articles']
        if (location.pathname) {
            const pathData = location.pathname.split('/')
            if (showMainWidgetsPages.includes(location.pathname) || (pathData.length === 3 && showMainPostType.includes(pathData[1]))) {
                setShowMainWidgets(true)
            } else {
                setShowMainWidgets(false)
            }
        }
    }, [location.pathname])

    /**
     * @param locationArray : [previous location, current location]
     */
    const [locationArray, setLocationArray] = useState(['', location.pathname])

    const isRoomUrl = (url: string) => {
        const pathArray = url.split('/')
        return (pathArray.length === 3 && pathArray[1] === 'rooms' && uuidValidate(pathArray[2]))
    }

    useEffect(() => {
        if (socket && locationArray && location.pathname) {
            if (location.pathname !== locationArray[1]) {
                let oldPath = locationArray[1]
                let newPath = location.pathname
                // console.log(locationArray)
                // console.log('location changed')
                switch (oldPath) {
                    case '/rooms':
                        // console.log('leave lobby')
                        socket?.emit('leave_lobby')
                        break

                    default:
                        /** Leave room actions */
                        if (isRoomUrl(oldPath)) {
                            // console.log('off update user')
                            // console.log([oldPath, newPath])

                            if (kickedout !== 'idle') {
                                socket?.emit('leave_room')
                            }
                            userDispatch({ type: 'leave_room', payload: '' })

                            if (kickedout === 'host') {
                                setKickedoutMessage(t('Warnings.host_kickedout'))
                                setPopupKickedout(true)
                                userDispatch({ type: 'update_kickedout', payload: '' })
                            } else if (kickedout === 'idle') {
                                setKickedoutMessage(t('Warnings.idle_kickedout'))
                                setPopupKickedout(true)
                                userDispatch({ type: 'update_kickedout', payload: '' })
                            }
                        }
                        break
                }

                setLocationArray(() => [oldPath, newPath])
            }
        }
    }, [location, locationArray, socket])

    useEffect(() => {
        const current = new Date().getTime()
        if (maintenanceTime !== null && maintenanceTime - current >= 0 && maintenanceTime - current <= 60 * 60 * 1000) {
            timerWorker.postMessage({ turn: 'on' })

            timerWorker.onmessage = ({ data: { time } }) => {
                const newTime = new Date().getTime()
                const m = new Date((maintenanceTime - newTime)).getMinutes() + 1
                setMaintenanceMsg(t('Messages.maintenance_time', { left: m }))
            }
        } else {
            setMaintenanceMsg(undefined)
            timerWorker.postMessage({ turn: 'off' })
        }
    }, [maintenanceTime])

    useEffect(() => {
        if (location.pathname !== '/maintenance' && connectStatus === 'disconnect') {
            navigate('/maintenance')
        }
    }, [location, connectStatus])

    return (
        <LayoutContext.Provider value={{ LayoutState, LayoutDispatch }}>
            <main>
                {
                    showMainWidgets &&
                    <MainHeader />
                }
                {
                    maintenanceMsg !== undefined &&
                    <div className={styles.maintenance}>
                        {maintenanceMsg}
                    </div>
                }
                <div className={styles.wrapper} >
                    <div className={styles.side} style={{ display: 'none' }}>
                        {
                            windowSize[0] > 710 &&
                            <div className={styles.ads_160x600}></div>
                        }
                        {
                            windowSize[0] <= 710 &&
                            <div className={styles.sideWrap}>
                                <div className={styles.ads_320x50}></div>
                            </div>
                        }
                    </div>
                    <div className={styles.content}>
                        <Outlet />
                    </div>
                    <Loading loading={(LayoutState.loading || (connectStatus === 'connecting' && location.pathname !== '/maintenance'))} size='full' />
                </div>
                {
                    showMainWidgets &&
                    <MainFooter />
                }
                <ErrorPopup active={popupKickedout} setActive={setPopupKickedout} message={kickedoutMessage} isNotice={true} />
            </main>
        </LayoutContext.Provider>
    )
}

export default RootLayout