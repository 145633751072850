import React from 'react'
import styles from './Popup.module.css'
import IconButton from '../Buttons/IconButton'
import PopupTitle from '../../Widgets/Title/PopupTitle'

export interface IPopup {
    title?: string
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    closeButton?: boolean
    children?: React.ReactNode
}

const Popup: React.FunctionComponent<IPopup> = (props) => {
    const closeButtonOnClickHandler = () => {
        props.setActive(false)
    }

    return (
        <>
            {
                props.active &&
                <div className={styles.popup}>
                    <div className={styles.inner}>
                        <div className={styles.container}>
                            {
                                props.closeButton &&
                                <IconButton type='close' classes={styles.close} onClick={closeButtonOnClickHandler} />
                            }
                            {
                                props.title !== undefined &&
                                <PopupTitle title={props.title} />
                            }
                            <div className={styles.content}>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Popup