import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './FinalScoresView.module.css'
import UserContext from '../../../contexts/User/UserContext'
import AvatarIcon from '../../UI/Avatar/AvatarIcon'
import Scrollbars from 'react-custom-scrollbars-2'
import { TUserObject } from '../../../utils/library'
import { useTranslation } from 'react-i18next'

export interface IFinalScoresView {
    usersRecord: TUserObject[]
}

const FinalScoresView: React.FunctionComponent<IFinalScoresView> = (props) => {
    const { t } = useTranslation()

    const { user } = useContext(UserContext).UserState

    const [rankRecord, setRankRecord] = useState<{ user: TUserObject, rank: number }[]>()

    const containerRef = useRef<HTMLDivElement>(null)
    const scrollListRef = useRef<HTMLDivElement>(null)
    const myCardRef = useRef<HTMLDivElement>(null)
    const scrollbarRef = useRef<Scrollbars>(null)
    const shadowTop = useRef<HTMLDivElement>(null)
    const shadowBottom = useRef<HTMLDivElement>(null)
    const [scrollbarRendered, setScrollbarRendered] = useState<boolean>(false)

    useEffect(() => {
        if (props.usersRecord !== undefined) {
            let rank = 1
            let record: { user: TUserObject, rank: number }[] = []
            let thisScore = props.usersRecord[0].score
            props.usersRecord.forEach((user, i) => {
                if (user.score !== thisScore) {
                    rank = i + 1
                    thisScore = user.score
                }
                record.push({ user, rank })
            })

            setRankRecord(record)
        }
    }, [props.usersRecord])

    const scrollbarHandleUpdate = (values: any) => {
        const { scrollTop, scrollHeight, clientHeight } = values

        if (!scrollbarRendered) {
            /** Only trigger once */
            // if (containerRef.current && scrollListRef.current) {
            //     const h = scrollListRef.current.offsetHeight
            //     containerRef.current.style.setProperty('max-height', h + 'px')
            // }

            if (myCardRef.current && scrollbarRef.current) {
                // console.log(myCardRef.current.offsetTop - ((clientHeight - myCardRef.current.offsetHeight) / 2))
                const scrollTo = Math.max(myCardRef.current.offsetTop - ((clientHeight - myCardRef.current.offsetHeight) / 2), 0)
                scrollbarRef.current.scrollTop(scrollTo)
            }

            setScrollbarRendered(true)
        }

        /** Make scrollbar top and bottom transparent */
        const shadowTopOpacity = 1 / 20 * Math.min(scrollTop, 20)
        const bottomScrollTop = scrollHeight - clientHeight
        const shadowBottomOpacity = 1 / 20 * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20))

        shadowTop.current?.style.setProperty('opacity', shadowTopOpacity.toString())
        shadowBottom.current?.style.setProperty('opacity', shadowBottomOpacity.toString())
    }

    return (
        <div ref={containerRef} className={styles.container}>
            <div className={styles.scrollbarContainer}>
                <Scrollbars
                    ref={scrollbarRef}
                    style={{ height: '100%' }}
                    onUpdate={scrollbarHandleUpdate}
                >
                    <div ref={scrollListRef} className={styles.scoresList}>
                        {
                            rankRecord !== undefined &&
                            rankRecord.map((record) => {
                                const u = record.user
                                const rank = record.rank
                                const rankStyle = rank > 3 ? '' : styles['rank' + rank]
                                return (
                                    <div key={`s_${u.uid}`} ref={u.uid === user.uid ? myCardRef : undefined} className={`${styles.user} ${u.uid === user.uid ? styles.myUser : ''}`}>
                                        <div className={`${styles.rank} ${rankStyle}`}>{rank}</div>
                                        <div className={styles.userColumn}>
                                            <AvatarIcon src={u.avatar} size='sm' myAvatar={u.uid === user.uid} />
                                            <div className={styles.userName}>
                                                <span className={styles.name}>{u.username}</span>
                                            </div>
                                        </div>
                                        <div className={styles.scoreColumn}>
                                            <span className={styles.score}>{u.score}</span>
                                            {
                                                u.records.questionCount !== undefined && u.records.correctCount !== undefined &&
                                                <span className={styles.record}>{t('FinalRecords.correct', { correct: u.records.correctCount, total: u.records.questionCount })}</span>
                                            }
                                            {
                                                u.records.questionCount !== undefined && u.records.totalTimeSpent !== undefined &&
                                                <span className={styles.record}>{t('FinalRecords.average_time', { time: ((u.records.totalTimeSpent / u.records.questionCount) / 1000).toFixed(2) })}</span>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Scrollbars>
                <div className={styles.scrollbarShadowTop} ref={shadowTop} />
                <div className={styles.scrollbarShadowBottom} ref={shadowBottom} />
            </div>
        </div>
    )
}

export default FinalScoresView