import React from 'react'
import styles from './SearchRoomInputField.module.css'
import { useTranslation } from 'react-i18next'
import InputField from '../../../UI/Fields/InputField'
import IconButton from '../../../UI/Buttons/IconButton'

export interface ISearchRoomInputField {
    searchRoomName: string
    setSearchRoomName: React.Dispatch<React.SetStateAction<string>>
    onSubmit?: () => void
    submitted?: boolean
}

const SearchRoomInputField: React.FunctionComponent<ISearchRoomInputField> = (props) => {
    const { t } = useTranslation()

    const searchInputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setSearchRoomName(e.target.value)
    }

    const searchIconOnClickHandler = () => {
        if (props.onSubmit !== undefined) {
            props.onSubmit()
        }
    }

    const searchInputOnKeyDownHandler = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            if (props.onSubmit !== undefined) {
                props.onSubmit()
            }
        }
    }

    return (
        <div className={`${styles.field} ${props.submitted ? styles.submitted : ''}`}>
            <InputField id='search-room-name' classes={styles.input} value={props.searchRoomName} placeholder={t('Placeholders.search_roomname')} onChange={searchInputOnChangeHandler} onKeyDown={searchInputOnKeyDownHandler} />
            <IconButton type='search' classes={styles.button} onClick={searchIconOnClickHandler} />
        </div>
    )
}

export default SearchRoomInputField