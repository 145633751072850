import React, { useEffect, useState } from 'react'
import MultiInputField from '../../../../UI/Fields/MultiInputField'
import { useTranslation } from 'react-i18next'
import { quizOptionMaxLength, quizOptionMaxLengthEN, quizlibOptionsMin } from '../../../../../utils/library'

export interface IQuizlibOptionsMultiInputField {
    options: string[]
    setOptions: React.Dispatch<React.SetStateAction<string[]>>
    inline?: boolean
    setError: React.Dispatch<React.SetStateAction<string>>
    setPopupError: React.Dispatch<React.SetStateAction<boolean>>
}

const QuizlibOptionsMultiInputField: React.FunctionComponent<IQuizlibOptionsMultiInputField> = (props) => {
    const { t, i18n } = useTranslation()

    const label = t('Labels.quizlib_options') + ` (>=${quizlibOptionsMin})`
    const [maxLength, setMaxLength] = useState<number>(quizOptionMaxLength)

    useEffect(() => {
        if (i18n.resolvedLanguage === 'en') {
            setMaxLength(quizOptionMaxLengthEN)
        } else {
            setMaxLength(quizOptionMaxLength)
        }
    }, [i18n.resolvedLanguage])


    return (
        <MultiInputField id='quizlib-options' label={label} values={props.options} setValues={props.setOptions} inline={props.inline} maxLength={maxLength} gridStyles={true} showCount={true} />
    )
}

export default QuizlibOptionsMultiInputField