import React from 'react'
import { useTranslation } from 'react-i18next'
import DatePickerField from '../../../UI/Fields/DatePickerField';

export interface IArticleDatePickerField {
    date: Date | null
    setDate: React.Dispatch<React.SetStateAction<Date | null>>
    inline?: boolean
}

const ArticleDatePickerField: React.FunctionComponent<IArticleDatePickerField> = (props) => {
    const { t } = useTranslation()

    return (
        <DatePickerField id='article-publish-date' label={t('Labels.publish_date')} inline28={props.inline} date={props.date} setDate={props.setDate} />
    )
}

export default ArticleDatePickerField