import React, { useEffect, useState } from 'react'
import styles from './ImportPopup.module.css'
import { useTranslation } from 'react-i18next'
import Popup from '../../../UI/Popup/Popup'
import TextButton from '../../../UI/Buttons/TextButton'
import axios from 'axios'
import Cookies from 'js-cookie'
import { TQuizQuestion, TThemeData } from '../../../../utils/library'
import QuizThemeSelectField from './QuizImportFields/QuizThemeSelectField'
import QuizLanguageSelectField from './QuizImportFields/QuizLanguageSelectField'
import QuizQuestionsMultiSelectField from './QuizImportFields/QuestionsMultiSelectField/QuizQuestionsMultiSelectField'
import Loading from '../../../UI/Loading/Loading'

export interface IImportPopup {
    setQuestions: React.Dispatch<React.SetStateAction<TQuizQuestion[] | undefined>>
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
}

const ImportPopup: React.FunctionComponent<IImportPopup> = (props) => {
    const { t, i18n } = useTranslation()

    const [loadingQs, setLoadingQs] = useState<boolean>(false)

    const [lang, setLang] = useState<string | undefined>(i18n.resolvedLanguage)
    const [themesData, setThemesData] = useState<TThemeData[]>()
    const [themeId, setThemeId] = useState<string>('')
    const [themeQuestions, setThemeQuestions] = useState<TQuizQuestion[]>()
    const [selectedQs, setSelectedQs] = useState<number[]>([])

    useEffect(() => {
        if (Cookies && props.active) {
            const token = Cookies.get('login_token')
            setThemeId('')
            if (token) {
                axios({
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/themes?type=quiz&who=my&lang=${lang}`
                }).then((res) => {
                    // console.log(res.data)
                    setThemesData(res.data)
                }).catch((e) => {
                    // console.log(e)
                })
            }
        }
    }, [props.active, lang])

    useEffect(() => {
        if (Cookies && props.active && themeId !== '') {
            const token = Cookies.get('login_token')
            if (token) {
                setLoadingQs(true)
                axios({
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/themes/${themeId}`
                }).then((res) => {
                    if (res.data && res.data.questions) {
                        setThemeQuestions(res.data.questions)
                    }
                }).finally(() => {
                    setLoadingQs(false)
                })
            }
        } else {
            setThemeQuestions(undefined)
        }
        setSelectedQs([])
    }, [props.active, themeId])

    const confirmButtonOnClickHandler = () => {
        if (selectedQs.length > 0 && themeQuestions !== undefined) {
            const newQuestions = themeQuestions.filter((v, i) => {
                return selectedQs.includes(i)
            }).map((q) => {
                delete q._id
                return q
            })
            console.log(newQuestions)
            props.setQuestions((q) => {
                if (q !== undefined) {
                    return [...q, ...newQuestions]
                }
                return newQuestions
            })
        }
        setThemeId('')
        props.setActive(false)
    }

    const cancelButtonOnClickHandler = () => {
        setThemeId('')
        props.setActive(false)
    }

    // useEffect(() => {
    //     console.log(selectedQs)
    // }, [selectedQs])

    return (
        <Popup title={t('Titles.import')} active={props.active} setActive={props.setActive}>
            <div className={styles.container}>
                <div className={styles.form}>
                    <QuizLanguageSelectField lang={lang} setLang={setLang} inline={true} showLabel={true} />
                    <QuizThemeSelectField themesData={themesData} id={themeId} setId={setThemeId} inline={true} showLabel={true} />
                    <Loading loading={loadingQs} classes={styles.loading} />
                    {
                        !loadingQs && themeQuestions !== undefined &&
                        <QuizQuestionsMultiSelectField questions={themeQuestions} selectedQs={selectedQs} setSelectedQs={setSelectedQs} />
                    }
                </div>
                <div className={styles.buttons}>
                    <TextButton id='import-confirm' text={t('Buttons.confirm')} onClick={confirmButtonOnClickHandler} />
                    <TextButton id='import-cancel' text={t('Buttons.cancel')} onClick={cancelButtonOnClickHandler} />
                </div>
            </div>
        </Popup>
    )
}

export default ImportPopup