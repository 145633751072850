import React, { useContext, useEffect, useState } from 'react'
import styles from './SingleArticlePage.module.css'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { IArticleData } from '../utils/library'
import '../assets/css/shortcodes.css'
import LayoutContext from '../contexts/Layout/LayoutContext'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const SingleArticlePage: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const layoutDispatch = useContext(LayoutContext).LayoutDispatch

    const params = useParams()
    const slug = params.slug

    const [data, setData] = useState<IArticleData>()

    useEffect(() => {
        if (slug !== undefined) {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_SERVER}/api/articles/content/${slug}`
            }).then((res) => {
                setData(res.data)
            }).catch((e) => {
                navigate('/error')
            })
        } else {
            navigate('/error')
        }
    }, [slug])

    useEffect(() => {
        layoutDispatch({ type: 'update_loading', payload: data === undefined })
    }, [data])

    const dateToText = (date: Date | string) => {
        let d = new Date(date)
        return moment(d).format('DD/MM/YYYY')
    }

    return (
        <>
            <div className={styles.container}>
                {
                    data !== undefined &&
                    <>
                        <div className={styles.section}>
                            <div className={`${styles.contentWrap} ql-snow`}>
                                <div className={styles.title}>
                                    <h1>{data.title}</h1>
                                </div>
                                <div className={styles.metas}>
                                    <div className={styles.meta}>
                                        <span className={styles.date}>{dateToText(data.publishDate)}</span>
                                    </div>
                                    {
                                        data.gameType !== undefined &&
                                        <div className={`${styles.meta} ${styles.gameType}`}>
                                            <i className={styles.playIcon}></i>
                                            <span>{t('GameTypes.' + data.gameType)}</span>
                                        </div>
                                    }
                                    {
                                        data.themeType !== undefined &&
                                        <div className={styles.meta}>
                                            <span>{t('ThemeTypes.' + data.themeType)}</span>
                                        </div>
                                    }
                                </div>
                                <div className={styles.featuredImg}>
                                    <img src={data.featuredImg} />
                                </div>
                                <div className={`${styles.content} ql-editor`} dangerouslySetInnerHTML={{ __html: data.content }}></div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default SingleArticlePage