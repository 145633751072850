import React, { useEffect, useState } from 'react'
import styles from './BulkEditPopup.module.css'
import Popup from '../../../UI/Popup/Popup'
import { useTranslation } from 'react-i18next'
import QuestionInputField from './QuizFields/QuestionInputField'
import { TQuizQuestion, quizQuestionMaxLength, quizQuestionMaxLengthEN } from '../../../../utils/library'
import QuizlibEditPopup from './QuizlibEditPopup'
import OptionsLibSelectAndEditField from './QuizFields/OptionsLibSelectAndEditField'
import TextButton from '../../../UI/Buttons/TextButton'
import QuizQuestionsMultiSelectField from './QuizImportFields/QuestionsMultiSelectField/QuizQuestionsMultiSelectField'

export interface IBulkEditPopup {
    questions: TQuizQuestion[] | undefined
    setQuestions: React.Dispatch<React.SetStateAction<TQuizQuestion[] | undefined>>
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    optionsLibOptions: { label: string, value: string }[]
    setOptionsLibOptions: React.Dispatch<React.SetStateAction<{ label: string, value: string }[]>>
    setError: React.Dispatch<React.SetStateAction<string>>
    setPopupError: React.Dispatch<React.SetStateAction<boolean>>
}

const BulkEditPopup: React.FunctionComponent<IBulkEditPopup> = (props) => {
    const { t, i18n } = useTranslation()

    const [questionMax, setQuestionMax] = useState<number>(quizQuestionMaxLength)
    const [question, setQuestion] = useState<string>()
    const [optionsLibId, setOptionsLibId] = useState<string>('')
    const [selectedQs, setSelectedQs] = useState<number[]>([])

    const [popupQuizlib, setPopupQuizlib] = useState<boolean>(false)

    useEffect(() => {
        if (i18n.resolvedLanguage === 'en') {
            setQuestionMax(quizQuestionMaxLengthEN)
        } else {
            setQuestionMax(quizQuestionMaxLength)
        }
    }, [i18n.resolvedLanguage])

    const confirmButtonOnClickHandler = () => {
        if (question && question.length > questionMax) {
            props.setError(t('Warnings.question_too_long', { length: question.length, max: questionMax }))
            props.setPopupError(true)
            return
        }

        if (selectedQs.length <= 0) {
            props.setError(t('Warnings.must_select_question'))
            props.setPopupError(true)
            return
        }

        props.setQuestions(() => {
            if (props.questions !== undefined) {
                return props.questions.map((v, i) => {
                    if (selectedQs.includes(i)) {
                        if (question) {
                            v.question = question
                        }
                        if (optionsLibId !== '') {
                            v.type = 'select'
                            v.useQuizlib = true
                            v.quizlibId = optionsLibId
                        }
                    }
                    return v
                })
            }
            return props.questions
        })
        reset()
        props.setActive(false)
    }

    const cancelButtonOnClickHandler = () => {
        reset()
        props.setActive(false)
    }

    const reset = () => {
        setSelectedQs([])
        setQuestion('')
        setOptionsLibId('')
    }

    return (
        <Popup title={t('Titles.bulk_edit')} active={props.active} setActive={props.setActive}>
            <div className={styles.container}>
                <div className={styles.form}>
                    <QuestionInputField question={question} setQuestion={setQuestion} max={questionMax} inline={true} tips={t('Messages.bulk_edit_question_tips')} />
                    <OptionsLibSelectAndEditField options={props.optionsLibOptions} optionsLibId={optionsLibId} setOptionsLibId={setOptionsLibId} inline={true} showLabel={true} setPopupQuizlib={setPopupQuizlib} tips={t('Messages.bulk_edit_optionslib_tips')} />
                    {
                        props.questions !== undefined &&
                        <QuizQuestionsMultiSelectField questions={props.questions} selectedQs={selectedQs} setSelectedQs={setSelectedQs} />
                    }
                </div>
                <div className={styles.buttons}>
                    <TextButton id='import-confirm' text={t('Buttons.confirm')} onClick={confirmButtonOnClickHandler} />
                    <TextButton id='import-cancel' text={t('Buttons.cancel')} onClick={cancelButtonOnClickHandler} />
                </div>
            </div>
            <QuizlibEditPopup id={optionsLibId} setId={setOptionsLibId} setError={props.setError} setPopupError={props.setPopupError} active={popupQuizlib} setActive={setPopupQuizlib} setOptionsLibOptions={props.setOptionsLibOptions} questions={props.questions} />
        </Popup>
    )
}

export default BulkEditPopup