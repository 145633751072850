import React from 'react'
import styles from './RoomCard.module.css'
import Theme from '../../utils/theme'
import { gameModesOptions, TRoomData } from '../../utils/library'
import { useTranslation } from 'react-i18next'

export interface IRoomCard {
    roomId: string
    parentCallback: any
    selected: boolean
    data: TRoomData
}

const RoomCard: React.FunctionComponent<IRoomCard> = (props) => {
    const { t } = useTranslation()

    const themeHelper = new Theme()
    const gameType = props.data.gameType
    const visibility = props.data.visibility
    const gameMode = gameModesOptions[gameType]?.find((d) => d.value === props.data.gameMode)?.label

    const imageSrc = themeHelper.GetImage(props.data.gameType, props.data.themeType)

    const roomCardOnClickHandler = (e: any) => {
        e.preventDefault()

        props.parentCallback({ id: props.roomId, visibility: props.data.visibility })
    }

    return (
        <div className={styles.card + ' ' + (props.selected ? styles.selected : '')} onClick={roomCardOnClickHandler} >
            <div className={styles.container}>
                <div className={styles.roomName}>
                    <span className={styles.lft}>
                        {visibility === 'password' && <i className={`${styles.lockIcon}`}></i>}
                    </span>
                    <h4>{props.data.roomName}</h4>
                    <span className={styles.rgt}></span>
                </div>
                <div className={styles.icon}>
                    <img src={imageSrc} alt='theme' />
                </div>
                <div className={styles.infos}>
                    <span className={styles.themeName}>
                        {props.data.themeName}
                    </span>
                    <span className={styles.gameMode}>
                        {gameMode !== undefined && t(gameMode)}
                    </span>
                    <div className={styles.metas}>
                        <div className={styles.meta}>
                            <i className={`${styles.metaIcon} ${styles.userIcon}`}></i>
                            <span className={`${styles.metaValue}`}>{props.data.usersCount}/8</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoomCard