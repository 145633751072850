import React from 'react'
import styles from './Arrow.module.css'
import { CustomArrowProps } from 'react-slick'
import IconButton from '../Buttons/IconButton'

const PrevArrow: React.FunctionComponent<CustomArrowProps> = (props) => {
    const { className, onClick } = props
    return (
        <div className={`${styles.arrowBG} ${styles.preBG} ${className}`}>
            <IconButton type='back' classes={`${styles.arrowIcon} ${styles.preArrowIcon}`} onClick={onClick} />
        </div>
    )
}

export default PrevArrow