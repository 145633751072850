import React, { useContext, useEffect, useState } from 'react'
import styles from './ArticleEditForm.module.css'
import RichEditorField from '../UI/Fields/RichEditorField'
import TextButton from '../UI/Buttons/TextButton'
import { useTranslation } from 'react-i18next'
import ErrorPopup from '../UI/Popup/ErrorPopup'
import { TArticleStatus, articleTitleMinLength, defaultArticleStatus } from '../../utils/library'
import ArticleStatusSelectField from './Fields/ArticleFields/ArticleStatusSelectField'
import ArticleTitleInputField from './Fields/ArticleFields/ArticleTitleInputField'
import Cookies from 'js-cookie'
import axios from 'axios'
import ArticleThemeSelectField from './Fields/ArticleFields/ArticleThemeSelectField'
import ArticleDatePickerField from './Fields/ArticleFields/ArticleDatePickerField'
import { useNavigate } from 'react-router-dom'
import ArticleSlugInputField from './Fields/ArticleFields/ArticleSlugInputField'
import SuccessPopup from '../UI/Popup/SuccessPopup'
import DeleteConfirmPopup from '../UI/Popup/DeleteConfirmPopup'
import LayoutContext from '../../contexts/Layout/LayoutContext'
import ArticleExcerptTextArea from './Fields/ArticleFields/ArticleExcerptTextareaField'
import ArticleFeaturedImgUploadField from './Fields/ArticleFields/ArticleFeaturedImgUploadField'

export interface IArticleEditForm {
    articleId: string | undefined
}

const ArticleEditForm: React.FunctionComponent<IArticleEditForm> = (props) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const layoutDispatch = useContext(LayoutContext).LayoutDispatch

    const [userRoles, setUserRoles] = useState<string[]>()

    const [themeOptions, setThemeOptions] = useState<{ label: string, value: string }[]>([{ label: '----', value: '' }])

    const [title, setTitle] = useState<string>('')
    const [slug, setSlug] = useState<string>('')
    const [status, setStatus] = useState<TArticleStatus>(defaultArticleStatus)
    const [themeId, setThemeId] = useState<string>('')
    const [publishDate, setPublishDate] = useState<Date | null>(() => {
        var d = new Date()
        d.setHours(0, 0, 0, 0)
        return d
    })
    const [featuredImg, setFeaturedImg] = useState<string>()
    const [content, setContent] = useState<string>('')
    const [excerpt, setExcerpt] = useState<string>('')

    const [sending, setSending] = useState<boolean>(false)

    const [createdOnce, setCreatedOnce] = useState<boolean>(false)
    const [newArticleId, setNewArticleId] = useState<string>()

    /** Popup */
    const [popupError, setPopupError] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [success, setSuccess] = useState<string>('')
    const [popupSuccessUpdate, setPopupSuccessUpdate] = useState<boolean>(false)
    const [popupSuccessCreate, setPopupSuccessCreate] = useState<boolean>(false)
    const [popupDelete, setPopupDelete] = useState<boolean>(false)

    useEffect(() => {
        if (Cookies) {
            const token = Cookies.get('login_token')
            if (token) {
                axios({
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/users/roles`
                }).then((res) => {
                    setUserRoles(res.data.roles)
                    if (!res.data.roles || !res.data.roles?.includes('administrator')) {
                        navigate('/error')
                    }
                }).catch((e) => {
                    navigate('/error')
                })
            }
        }
    }, [Cookies])

    useEffect(() => {
        if (userRoles?.includes('administrator')) {
            const token = Cookies.get('login_token')
            if (token) {
                if (props.articleId !== undefined) {
                    layoutDispatch({ type: 'update_loading', payload: true })
                    axios({
                        method: 'get',
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        url: `${process.env.REACT_APP_SERVER}/api/articles/${props.articleId}`
                    }).then((res) => {
                        setTitle(res.data.title)
                        setSlug(res.data.slug)
                        setStatus(res.data.status as TArticleStatus)
                        setThemeId(res.data.themeId)
                        setPublishDate(new Date(res.data.publishDate))
                        setFeaturedImg(res.data.featuredImg)
                        setExcerpt(res.data.excerpt)
                        setContent(res.data.content)
                        // console.log(res.data)
                    }).catch((e) => {
                        navigate('/error')
                    }).finally(() => {
                        layoutDispatch({ type: 'update_loading', payload: false })
                    })
                }
            } else {
                navigate('/error')
            }
        }
    }, [props.articleId, Cookies, userRoles])

    useEffect(() => {
        if (userRoles?.includes('administrator')) {
            const token = Cookies.get('login_token')
            if (token) {
                axios({
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/themes?who=my&lang=${i18n.resolvedLanguage}&type=${'quiz'}`
                }).then((res) => {
                    if (res.data) {
                        let arr = [{ label: '----', value: '' }]
                        res.data.forEach((v: any) => {
                            arr.push({ label: v.name, value: v._id })
                        });
                        setThemeOptions(arr)
                    }
                }).catch((e) => {

                })
            }
        }
    }, [Cookies, userRoles])

    useEffect(() => {
        var d = new Date()
        d.setHours(0, 0, 0, 0)
        setPublishDate(d)
    }, [])

    const confirmButtonOnClickHandler = async () => {
        if (title.length < articleTitleMinLength) {
            setError(t('Warnings.require_title', { min: articleTitleMinLength }))
            setPopupError(true)
            return
        }

        if (themeOptions.length <= 1) {
            setError(t('Warnings.no_theme'))
            setPopupError(true)
            return
        }

        const regexExp = /^[a-z0-9]+(?:(?:-|_)+[a-z0-9]+)*$/g
        if (slug === '' || !regexExp.test(slug)) {
            setError(t('Warnings.wrong_slug_format'))
            setPopupError(true)
            return
        }

        if (themeId === '') {
            setError(t('Warnings.require_theme'))
            setPopupError(true)
            return
        }

        if (publishDate === null) {
            setError(t('Warnings.require_publish_date'))
            setPopupError(true)
            return
        }

        if (featuredImg === undefined) {
            setError(t('Warnings.require_featured_img'))
            setPopupError(true)
            return
        }

        layoutDispatch({ type: 'update_loading', payload: true })
        setSending(true)
        if (userRoles?.includes('administrator')) {
            if (props.articleId !== undefined) {
                /** Update article */
                const token = Cookies.get('login_token')
                if (Cookies && props.articleId !== undefined) {
                    try {
                        const res = await axios({
                            method: 'patch',
                            headers: {
                                Authorization: `Bearer ${token}`
                            },
                            url: `${process.env.REACT_APP_SERVER}/api/articles/${props.articleId}`,
                            data: {
                                title,
                                slug,
                                status,
                                themeId,
                                publishDate,
                                featuredImg,
                                excerpt,
                                content
                            }
                        })

                        if (res.status === 200) {
                            setSlug(res.data.slug)
                            setFeaturedImg(res.data.featuredImg)
                            setSuccess(t('Messages.article_update_success'))
                            setPopupSuccessUpdate(true)
                        }
                    } catch (e) {
                        setError(t('Warnings.article_update_failed'))
                        setPopupError(true)
                    }
                    layoutDispatch({ type: 'update_loading', payload: false })
                    setSending(false)
                }
            } else {
                /** New article */
                const token = Cookies.get('login_token')
                if (!createdOnce) {
                    try {
                        const res = await axios({
                            method: 'post',
                            headers: {
                                Authorization: `Bearer ${token}`
                            },
                            url: `${process.env.REACT_APP_SERVER}/api/articles/new`,
                            data: {
                                title,
                                slug,
                                status,
                                themeId,
                                publishDate,
                                featuredImg,
                                excerpt,
                                content,
                                language: i18n.resolvedLanguage
                            }
                        })

                        if (res.status === 201) {
                            setSlug(res.data.slug)
                            setFeaturedImg(res.data.featuredImg)
                            setSuccess(t('Messages.article_create_success'))
                            setPopupSuccessCreate(true)
                            setCreatedOnce(true)
                            setNewArticleId(res.data._id as string)
                        }
                    } catch (e) {
                        setError(t('Warnings.article_create_failed'))
                        setPopupError(true)
                    }
                    layoutDispatch({ type: 'update_loading', payload: false })
                    setSending(false)
                }
            }
        }
    }

    const continueEditOnClickHandler = () => {
        navigate('/article-edit/' + newArticleId)
    }

    const deleteButtonOnClickHandler = () => {
        setPopupDelete(true)
    }

    const deleteArticleOnConfirmHandler = async () => {
        const token = Cookies.get('login_token')
        if (token && props.articleId !== undefined) {
            try {
                await axios({
                    method: 'delete',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/articles/${props.articleId}`
                })
                navigate('/articles')
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <div className={styles.wrap}>
                    <ArticleTitleInputField articleTitle={title} setArticleTitle={setTitle} inline={true} />
                    <ArticleSlugInputField articleSlug={slug} setArticleSlug={setSlug} inline={true} />
                    <ArticleStatusSelectField articleStatus={status} setArticleStatus={setStatus} showLabel={true} inline={true} />
                    <ArticleThemeSelectField themeId={themeId} setThemeId={setThemeId} options={themeOptions} showLabel={true} inline={true} />
                    <ArticleDatePickerField date={publishDate} setDate={setPublishDate} inline={true} />
                    <ArticleFeaturedImgUploadField image={featuredImg} setImage={setFeaturedImg} inline={true} />
                </div>
                <ArticleExcerptTextArea articleExcerpt={excerpt} setArticleExcerpt={setExcerpt} inline={false} />
                <RichEditorField label={t('Labels.content')} value={content} setValue={setContent} inline={true} shortcodes={['questions', 'startGameLink']} />
            </div>
            <div className={styles.buttons}>
                {
                    props.articleId !== undefined &&
                    <TextButton id='delete' classes={styles.deleteButton} text={t('Buttons.delete')} onClick={deleteButtonOnClickHandler} />
                }
                <TextButton id='confirm' text={t('Buttons.confirm')} onClick={confirmButtonOnClickHandler} disabled={sending} />
            </div>
            <DeleteConfirmPopup active={popupDelete} setActive={setPopupDelete} message={t('Messages.confirm_delete_article')} onConfirm={deleteArticleOnConfirmHandler} />
            <SuccessPopup active={popupSuccessUpdate} setActive={setPopupSuccessUpdate} message={success} redirect='/articles' redirectButtonText={t('Buttons.articles')} />
            <SuccessPopup active={popupSuccessCreate} setActive={setPopupSuccessCreate} message={success} redirect='/articles' redirectButtonText={t('Buttons.articles')} confirmButtonText={t('Buttons.continue_edit')} onConfirm={continueEditOnClickHandler} />
            <ErrorPopup key={'errorpopup'} active={popupError} setActive={setPopupError} message={error} />
        </div>
    )
}

export default ArticleEditForm