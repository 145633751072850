import React, { PropsWithChildren, useEffect, useReducer } from 'react'
import SocketContext, { SocketReducer, defaultSocketContextState } from './SocketContext'
import { useSocket } from '../../hooks/useSocket'
import axios from 'axios'

export interface ISocketContextComponentProps { }

const SocketContextComponent: React.FunctionComponent<ISocketContextComponentProps> = (props: PropsWithChildren) => {
  const [SocketState, SocketDispatch] = useReducer(SocketReducer, defaultSocketContextState)
  // const [loading, setLoading] = useState(true)

  const socket = useSocket(`${process.env.REACT_APP_SERVER}`, {
    reconnectionAttempts: 5,
    reconnectionDelay: 5000,
    autoConnect: false
  })

  useEffect(() => {
    /** Check server */
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/api/server`
    }).catch((err) => {
      if (err.code === 'ERR_NETWORK') {
        SocketDispatch({ type: 'update_connect_status', payload: 'disconnect' })
      }
    })

    /** Connect to the web socket */
    socket.connect()

    /** Save the socket in context */
    SocketDispatch({ type: 'update_socket', payload: socket })

    /** Start event listener */
    StartListeners()

    /** Handshake */
    StartHandshake()

    /** Before maintenance 1 hr */
    socket.on('maintenance_call', (res: { maintenanceTime: number }) => {
      SocketDispatch({ type: 'update_maintenance', payload: res.maintenanceTime })
    })

    return () => {
      socket.off('maintenance_call')
    }

  }, [])

  const StartListeners = () => {
    /** Reconnect event */
    socket.io.on('reconnect', (attempt) => {
      SocketDispatch({ type: 'update_connect_status', payload: 'connect' })
      console.log(`Reconnected on attempt: ${attempt}.`)
    })

    /** Reconnect attempt event */
    socket.io.on('reconnect_attempt', (attempt) => {
      SocketDispatch({ type: 'update_connect_status', payload: 'connecting' })
      console.log(`Reconnection attempt: ${attempt}.`)
    })

    /** Reconnect error event */
    socket.io.on('reconnect_error', (error) => {
      console.log(`Reconnection error: ${error}.`)
    })

    /** Reconnect failed event */
    socket.io.on('reconnect_failed', () => {
      console.log(`Reconnection failure.`)
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER}/api/server`
      }).catch((err) => {
        if (err.code === 'ERR_NETWORK') {
          SocketDispatch({ type: 'update_connect_status', payload: 'disconnect' })
        }
      })
    })
  }

  const StartHandshake = () => {
    // console.log('Sending hand shake...')

    socket.emit('handshake', (res: { maintenanceTime?: number }) => {
      // console.log('handshake received.')
      if (res.maintenanceTime) {
        SocketDispatch({ type: 'update_maintenance', payload: res.maintenanceTime })
      }
    })
  }

  // if (loading) return <p>Loading...</p>

  return (
    <SocketContext.Provider value={{ SocketState, SocketDispatch }}>
      {props.children}
    </SocketContext.Provider>
  )
}

export default SocketContextComponent