import React, { useEffect, useState } from 'react'
import styles from './QuizlibEditPopup.module.css'
import Popup from '../../../UI/Popup/Popup'
import { useTranslation } from 'react-i18next'
import TextButton from '../../../UI/Buttons/TextButton'
import Cookies from 'js-cookie'
import axios from 'axios'
import QuizlibNameInputField from './QuizlibFields/QuizlibNameInputField'
import QuizlibOptionsMultiInputField from './QuizlibFields/QuizlibOptionsMultiInputField'
import { TQuizQuestion, quizlibNameMaxLength, quizlibNameMinLength, quizlibOptionsMin } from '../../../../utils/library'
import QuizlibImportButtonField from './QuizlibFields/QuizlibImportButtonField'

export interface IQuizlibEditPopup {
    id: string
    setId: React.Dispatch<React.SetStateAction<string>>
    setError: React.Dispatch<React.SetStateAction<string>>
    setPopupError: React.Dispatch<React.SetStateAction<boolean>>
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    setOptionsLibOptions: React.Dispatch<React.SetStateAction<{ label: string, value: string }[]>>
    questions: TQuizQuestion[] | undefined
}

const quizlibMaxUploadSize = 80 * 1000 * 1000

const QuizlibEditPopup: React.FunctionComponent<IQuizlibEditPopup> = (props) => {
    const { t, i18n } = useTranslation()

    const [name, setName] = useState<string>()
    const [options, setOptions] = useState<string[]>([])

    useEffect(() => {
        // GET /api/quizlibs/:id
        if (Cookies && props.active && props.id !== '') {
            const token = Cookies.get('login_token')
            if (token) {
                axios({
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    url: `${process.env.REACT_APP_SERVER}/api/quizlibs/${props.id}`
                }).then((res) => {
                    setName(res.data.name)
                    setOptions(res.data.options)
                    // console.log('get single quizlib')
                })
            }
        }

        if (props.id === '') {
            setName('')
            setOptions([])
        }
    }, [props.id, props.active, Cookies])

    const importButtonOnClickHandler = () => {
        const answers = props.questions?.map((q) => q.answer[0])
        if (answers !== undefined) {
            setOptions(() => options.concat(answers.filter((a, i) => options.indexOf(a) < 0 && answers.indexOf(a) === i)))
        }
    }

    const confirmButtonOnClickHandler = async () => {
        // check
        if (name === undefined || name.length < quizlibNameMinLength || name.length > quizlibNameMaxLength) {
            props.setError(t('Warnings.require_quizlib_name', { min: quizlibNameMinLength, max: quizlibNameMaxLength }))
            props.setPopupError(true)
            return
        }

        if (options.length < quizlibOptionsMin) {
            props.setError(t('Warnings.multiinput_not_enough', { label: t('Labels.options'), min: quizlibOptionsMin }))
            props.setPopupError(true)
            return
        }

        if (Cookies) {
            const token = Cookies.get('login_token')
            if (props.id !== '') {
                // PATCH /api/quizlibs/:id
                try {
                    // console.log('patch single quizlib')
                    await axios({
                        method: 'patch',
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        maxContentLength: quizlibMaxUploadSize,
                        maxBodyLength: quizlibMaxUploadSize,
                        url: `${process.env.REACT_APP_SERVER}/api/quizlibs/${props.id}`,
                        data: {
                            name,
                            options
                        }
                    })
                } catch (e) {
                    props.setError(t('Warnings.quizlib_update_failed'))
                    props.setPopupError(true)
                }
            } else {
                // POST /api/quizlibs/new
                try {
                    // console.log('post new single quizlib')
                    const res = await axios({
                        method: 'post',
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        maxContentLength: quizlibMaxUploadSize,
                        maxBodyLength: quizlibMaxUploadSize,
                        url: `${process.env.REACT_APP_SERVER}/api/quizlibs/new`,
                        data: {
                            name,
                            languages: [i18n.resolvedLanguage],
                            options
                        }
                    })

                    if (res.status === 201) {
                        // console.log('get all quizlibs')
                        const opts = await axios({
                            method: 'get',
                            headers: {
                                Authorization: `Bearer ${token}`
                            },
                            url: `${process.env.REACT_APP_SERVER}/api/quizlibs?lang=${i18n.resolvedLanguage}`
                        })

                        if (opts.data) {
                            let arr = [{ label: '----', value: '' }]
                            opts.data.forEach((v: any) => {
                                arr.push({ label: v.name, value: v._id })
                            });
                            props.setOptionsLibOptions(arr)

                            props.setId(res.data._id)
                        }
                    }
                } catch (e) {
                    props.setError(t('Warnings.quizlib_create_failed'))
                    props.setPopupError(true)
                }
            }
        }
        props.setActive(false)
    }

    const cancelButtonOnClickHandler = () => {
        props.setActive(false)
    }

    return (
        <Popup title={t('Titles.quizlib')} active={props.active} setActive={props.setActive}>
            <div className={styles.container}>
                <div className={styles.form}>
                    <QuizlibNameInputField QuizlibName={name} setQuizlibName={setName} inline={true} />
                    <QuizlibImportButtonField onImport={importButtonOnClickHandler} />
                    <QuizlibOptionsMultiInputField options={options} setOptions={setOptions} setError={props.setError} setPopupError={props.setPopupError} inline={true} />
                </div>
                <div className={styles.buttons}>
                    <TextButton id='quizlib-edit-confirm' text={t('Buttons.confirm')} onClick={confirmButtonOnClickHandler} />
                    <TextButton id='quizlib-edit-cancel' text={t('Buttons.cancel')} onClick={cancelButtonOnClickHandler} />
                </div>
            </div>
        </Popup>
    )
}

export default QuizlibEditPopup