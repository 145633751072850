import React from 'react'
import Popup from './Popup'
import styles from './SuccessPopup.module.css'
import TextButton from '../Buttons/TextButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export interface ISuccessPopupProps {
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    message: string
    redirect?: string
    redirectButtonText?: string
    onConfirm?: () => any
    confirmButtonText?: string
}

const SuccessPopup: React.FunctionComponent<ISuccessPopupProps> = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [active, setActive] = [props.active, props.setActive]

    const confirmButtonOnClickHandler = () => {
        if (props.onConfirm !== undefined) {
            props.onConfirm()
        }
        setActive(false)
    }

    const redirectButtonOnClickHandler = () => {
        if (props.redirect !== undefined) {
            navigate(props.redirect)
        }
        setActive(false)
    }

    return (
        <Popup title={t('Titles.success')} active={active} setActive={setActive}>
            <div className={styles.container}>
                <div className={styles.messageContainer}>
                    <p className={styles.message}>{props.message}</p>
                </div>
                <div className={styles.buttons}>
                    <TextButton text={props.confirmButtonText !== undefined ? props.confirmButtonText : t('Buttons.ok')} onClick={confirmButtonOnClickHandler} />
                    {
                        props.redirect !== undefined && props.redirectButtonText !== undefined &&
                        <TextButton text={props.redirectButtonText} onClick={redirectButtonOnClickHandler} />
                    }
                </div>
            </div>
        </Popup>
    )
}

export default SuccessPopup