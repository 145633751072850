import React, { useEffect, useState } from 'react'
import TextButton from '../UI/Buttons/TextButton'
import styles from './CreateForm.module.css'
import InputField from '../UI/Fields/InputField'
import ThemeSelector from './Fields/RoomFields/ThemeSelector'
import ErrorPopup from '../UI/Popup/ErrorPopup'
import { useTranslation } from 'react-i18next'
import LanguageSelectField from './Fields/LanguageSelectField'
import CreateRoomSettingsFormPopup from './CreateRoomSettingsFormPopup'
import { TGameMode, TGameType, TRoomVisibility, TThemeData, defaultGameModes, defaultRoomVisibility, roomNameMaxLength } from '../../utils/library'
import RoomVisibilitySelectField from './Fields/RoomFields/RoomVisibilitySelectFIeld'
import GameModeSelectField from './Fields/RoomFields/GameModeSelectField'
import RoomPasswordInputField from './Fields/RoomFields/RoomPasswordInputField'

export interface ICreateForm { }

const CreateForm: React.FunctionComponent<ICreateForm> = () => {
    const { t } = useTranslation()

    /** Error popup */
    const [error, setError] = useState<string>('')
    const [popupError, setPopupError] = useState<boolean>(false)

    /** Room settings popup */
    const [popupSettings, setPopupSettings] = useState<boolean>(false)

    /** Settings */
    const gameType: TGameType = 'quiz'
    const [roomName, setRoomName] = useState<string>('Room0000')
    const [roomVisibility, setRoomVisibility] = useState<TRoomVisibility>(defaultRoomVisibility)
    const [disablePassword, setDisablePassword] = useState<boolean>(true)
    const [password, setPassword] = useState<string>('')
    const [gameMode, setGameMode] = useState<TGameMode>(defaultGameModes[gameType])
    const [selectedThemeData, setSelectedThemeData] = useState<TThemeData>()
    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState<boolean>(true)

    /** Update themes */
    const [updateThemes, setUpdateThemes] = useState<boolean>(false)

    useEffect(() => {
        const newRoomName = 'Room' + Math.floor(Math.random() * 9000 + 1000).toString()
        setRoomName(newRoomName)
    }, [])

    useEffect(() => {
        if (roomVisibility === 'password') {
            setDisablePassword(false)
        } else {
            setDisablePassword(true)
        }
    }, [roomVisibility])

    useEffect(() => {
        if (selectedThemeData !== undefined) {
            setConfirmButtonDisabled(false)
        } else {
            setConfirmButtonDisabled(true)
        }
    }, [selectedThemeData])


    const roomNameOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRoomName(() => e.target.value)
    }

    const confirmButtonOnClickHandler = () => {
        if (roomName.length <= 0) {
            setError(t('Warnings.require_roomname'))
            setPopupError(true)
            return
        }

        if (roomVisibility === 'password' && !password) {
            setError(t('Warnings.require_password'))
            setPopupError(true)
            return
        }

        if (selectedThemeData === undefined) {
            setError(t('Warnings.require_theme'))
            setPopupError(true)
            return
        }

        setPopupSettings(true)

        // console.log(roomVisibility)
    }

    const languageOnChangeHandler = () => {
        setUpdateThemes(() => !updateThemes)
        setSelectedThemeData(undefined)
    }

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <div className={styles.left}>
                    <div className={styles.basicSettings}>
                        <div className={styles.title}>
                            <span>{t('Titles.basic_settings')}</span>
                        </div>
                        <InputField inline={true} id='roomname' label={t('Labels.roomname')} value={roomName} onChange={roomNameOnChangeHandler} maxLength={roomNameMaxLength} />
                        <RoomVisibilitySelectField roomVisibility={roomVisibility} setRoomVisibility={setRoomVisibility} inline={true} showLabel={true} />
                        <RoomPasswordInputField password={password} setPassword={setPassword} disabled={disablePassword} inline={true} showLabel={true} />
                        <GameModeSelectField gameType={gameType} gameMode={gameMode} setGameMode={setGameMode} inline={true} showLabel={true} />
                        <LanguageSelectField inline={true} showLabel={true} onChange={languageOnChangeHandler} />
                    </div>
                </div>
                <div className={styles.right}>
                    <ThemeSelector selectedThemeData={selectedThemeData} setSelectedThemeData={setSelectedThemeData} updateThemes={updateThemes} setError={setError} setPopupError={setPopupError} />
                </div>
            </div>
            <div className={styles.buttons}>
                <TextButton id='confirm' text={t('Buttons.confirm')} onClick={confirmButtonOnClickHandler} disabled={confirmButtonDisabled} />
            </div>
            <CreateRoomSettingsFormPopup key={'settingspopup'} active={popupSettings} setActive={setPopupSettings} roomName={roomName} visibility={roomVisibility} password={password} gameMode={gameMode} selectedThemeData={selectedThemeData} setError={setError} setPopupError={setPopupError} />
            <ErrorPopup key={'errorpopup'} active={popupError} setActive={setPopupError} message={error} />
        </div>
    )
}

export default CreateForm