import React from 'react'
import InputField from '../../../UI/Fields/InputField'
import { useTranslation } from 'react-i18next'
import { passwordMaxLength } from '../../../../utils/library'

export interface IRoomPasswordInputFieldProps {
    password: string
    setPassword: React.Dispatch<React.SetStateAction<string>>
    showLabel: boolean
    inline?: boolean
    disabled?: boolean
    focus?: boolean
}

const RoomPasswordInputField: React.FunctionComponent<IRoomPasswordInputFieldProps> = (props) => {
    const { t } = useTranslation()

    const [password, setPassword] = [props.password, props.setPassword]

    /** Update user name */
    const passwordInputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(() => e.target.value)
    }

    return (
        <InputField focus={props.focus} inline={props.inline} id='password' label={props.showLabel ? t('Labels.password') : undefined} value={password} onChange={passwordInputOnChangeHandler} maxLength={passwordMaxLength} disabled={props.disabled} />
    )
}

export default RoomPasswordInputField