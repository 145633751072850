import React, { useContext, useEffect, useState } from 'react'
import styles from './GameSettingsPopup.module.css'
import Popup from '../../UI/Popup/Popup'
import { useTranslation } from 'react-i18next'
import UserContext from '../../../contexts/User/UserContext'
import TextButton from '../../UI/Buttons/TextButton'
import SocketContext from '../../../contexts/Socket/SocketContext'
import { TQuizTimeLimit, quizTimeLimitOptions, quizTimeLimits } from '../../../utils/library'
import NumberField from '../../UI/Fields/NumberField'
import SelectField from '../../UI/Fields/SelectField'
import ErrorPopup from '../../UI/Popup/ErrorPopup'

export interface IGameSettingsPopup {
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
}

const GameSettingsPopup: React.FunctionComponent<IGameSettingsPopup> = (props) => {
    const { t } = useTranslation()

    const userContext = useContext(UserContext)
    const { room } = userContext.UserState

    const socketContext = useContext(SocketContext)
    const { socket } = socketContext.SocketState

    const [totalQues, setTotalQues] = useState<number>()
    const [quesNum, setQuesNum] = useState<number>()
    const [quizTimeLimit, setQuizTimeLimit] = useState<TQuizTimeLimit>()

    const [error, setError] = useState<string>('')
    const [popupError, setPopupError] = useState<boolean>(false)

    useEffect(() => {
        if (room.themeData !== undefined) {
            const count = room.themeData.count
            setTotalQues(() => count)
        }
    }, [room.themeData])

    useEffect(() => {
        const settings = room.themeSettings
        if (settings) {
            if (settings.quesNum) {
                setQuesNum(settings.quesNum)
            }

            if (settings.quizTimeLimit) {
                setQuizTimeLimit(settings.quizTimeLimit)
            }
        }
    }, [room.themeSettings])

    const countOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuesNum(() => parseInt(e.target.value))
    }

    const quizTimeLimitOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value as TQuizTimeLimit
        if (quizTimeLimits.includes(val)) {
            setQuizTimeLimit(val)
        }
    }

    const confirmButtonOnClickHandler = () => {
        if (quesNum !== undefined && totalQues !== undefined && (quesNum < 1 || quesNum > totalQues)) {
            const msg = totalQues > 1 ? t('Warnings.question_number_range', { totalQues }) : t('Warnings.question_number_range_1')
            setError(msg)
            setPopupError(true)
            return
        }

        const newSettings = { quesNum, quizTimeLimit }
        if (
            room.themeSettings?.quesNum !== newSettings.quesNum ||
            room.themeSettings?.quizTimeLimit !== newSettings.quizTimeLimit
        ) {
            socket?.emit('update_theme_settings', { settings: newSettings })
        }
        props.setActive(false)
    }

    const cancelButtonOnClickHandler = () => {
        props.setActive(false)
    }

    return (
        <Popup title={t('Titles.theme_settings')} active={props.active} setActive={props.setActive}>
            <div className={styles.container}>
                <div className={styles.form}>
                    {
                        quesNum !== undefined && totalQues !== undefined &&
                        <NumberField inline={true} id='roomsettings-count' label={t('Labels.questions_number') + ' ' + t('Labels.questions_number_limit', { totalQues })} value={quesNum} min={1} max={totalQues} step={1} onChange={countOnChangeHandler} />
                    }
                    {
                        quizTimeLimit !== undefined &&
                        <SelectField inline={true} id='roomsettings-timelimit' label={t('Labels.timelimit')} options={quizTimeLimitOptions} value={quizTimeLimit} onChange={quizTimeLimitOnChangeHandler} translate={true} />
                    }
                </div>
                <div className={styles.buttons}>
                    <TextButton id='password-popup-confirm' text={t('Buttons.confirm')} onClick={confirmButtonOnClickHandler} />
                    <TextButton id='password-popup-cancel' text={t('Buttons.cancel')} onClick={cancelButtonOnClickHandler} />
                </div>
            </div>
            <ErrorPopup key={'errorpopup'} active={popupError} setActive={setPopupError} message={error} />
        </Popup>
    )
}

export default GameSettingsPopup