import React from 'react'
import InputField from '../../../../UI/Fields/InputField'
import { useTranslation } from 'react-i18next'
import { quizlibNameMaxLength } from '../../../../../utils/library'

export interface IQuizlibNameInputFieldProps {
    QuizlibName: string | undefined
    setQuizlibName: React.Dispatch<React.SetStateAction<string | undefined>>
    inline?: boolean
}

const QuizlibNameInputField: React.FunctionComponent<IQuizlibNameInputFieldProps> = (props) => {
    const { t } = useTranslation()

    /** Update theme name */
    const QuizlibNameInputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setQuizlibName(() => e.target.value)
    }

    return (
        <InputField inline={props.inline} id='quizlib-name' label={t('Labels.quizlib_name')} value={props.QuizlibName} onChange={QuizlibNameInputOnChangeHandler} maxLength={quizlibNameMaxLength} />
    )
}

export default QuizlibNameInputField