import React, { useContext, useEffect, useState } from 'react'
import styles from './GameScreenFrame.module.css'
import Toolbar from './Toolbar/Toolbar'
import GameScreen from './GameScreen'
import UserContext from '../../contexts/User/UserContext'

export interface IGameScreenFrame { }

const GameScreenFrame: React.FunctionComponent<IGameScreenFrame> = (props) => {
    const { room } = useContext(UserContext).UserState

    const [roomAdminId, setRoomAdminId] = useState<string>('')

    useEffect(() => {
        if (room.users !== undefined) {
            const adminId = Object.keys(room.users)[0]
            setRoomAdminId(adminId)
        }
    }, [room.users])

    return (
        <div className={styles.frame}>
            <div className={styles.tools}>
                <Toolbar />
            </div>
            <div className={styles.gameScreen}>
                <GameScreen roomAdminId={roomAdminId} />
            </div>
        </div>
    )
}

export default GameScreenFrame