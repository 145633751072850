import React from 'react'
import SelectField from '../../../UI/Fields/SelectField'
import { useTranslation } from 'react-i18next'
import { TGameType, themeTypes, themeTypesOptions } from '../../../../utils/library'
import { TThemeType } from '../../../../utils/library'

export interface IThemeTypeSelectField {
    gameType: TGameType
    themeType: TThemeType,
    setThemeType: React.Dispatch<React.SetStateAction<TThemeType>>
    inline?: boolean
    inFilter?: boolean
    showLabel: boolean
}

const ThemeTypeSelectField: React.FunctionComponent<IThemeTypeSelectField> = (props) => {
    const { t } = useTranslation()

    const options = themeTypesOptions[props.gameType]

    const themeTypeOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value as TThemeType
        // console.log(`selected theme type: ${val}`)
        if (themeTypes.includes(val)) {
            props.setThemeType(val)
        }
    }

    return (
        <SelectField inline={props.inline} id='theme-themetype-select' options={options} onChange={themeTypeOnChangeHandler} value={props.themeType} label={props.showLabel ? t('Labels.theme_type') : undefined} translate={true} />
    )
}

export default ThemeTypeSelectField