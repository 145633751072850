import React from 'react'
import styles from './SelectAndEditField.module.css'
import { useTranslation } from 'react-i18next'

export interface ISelectAndEditField {
    label?: string
    value?: string
    id: string
    onChange?: React.ChangeEventHandler<HTMLSelectElement>
    options: { label: string, value: string }[]
    inline?: boolean
    classes?: string
    translate?: boolean
    onAdd?: () => any
    onEdit?: () => any
    canEdit?: boolean
    tips?: string
}

const SelectAndEditField: React.FunctionComponent<ISelectAndEditField> = (props) => {
    const { t } = useTranslation()

    const addButtonOnClickHandler = () => {
        props.onAdd !== undefined && props.onAdd()
    }

    const editButtonOnClickHandler = () => {
        props.onEdit !== undefined && props.onEdit()
    }

    return (
        <div className={`${styles.field} ${props.inline === true ? styles.inline : ''} ${props.classes !== undefined ? props.classes : ''}`}>
            {
                props.label !== undefined &&
                <label className={styles.label} htmlFor={props.id}>{props.label}</label>
            }
            <div className={styles.container}>
                <div className={styles.selectedit}>
                    <div className={`select ${styles.select}`}>
                        <select id={props.id} className={styles.selected} value={props.value} onChange={props.onChange} >
                            {props.options.map((option) => {
                                return <option key={option.value} value={option.value}>{props.translate === true ? t(option.label) : option.label}</option>
                            })}
                        </select>
                    </div>
                    <button className={styles.addButton} onClick={editButtonOnClickHandler} disabled={props.canEdit === false ? true : false}><i className={styles.editIcon}></i></button>
                    <button className={styles.addButton} onClick={addButtonOnClickHandler}>+</button>
                </div>
                {
                    props.tips !== undefined &&
                    <p className={styles.tips}>{props.tips}</p>
                }
            </div>
        </div>
    )
}

export default SelectAndEditField