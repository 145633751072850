import React, { useContext } from 'react'
import styles from './EndScreen.module.css'
import FinalScoresView from './FinalScoresView'
import TextButton from '../../UI/Buttons/TextButton'
import { useTranslation } from 'react-i18next'
import { TUserObject } from '../../../utils/library'
import UserContext from '../../../contexts/User/UserContext'

export interface IEndScreen {
    usersRecord: TUserObject[]
}

const EndScreen: React.FunctionComponent<IEndScreen> = (props) => {
    const { t } = useTranslation()

    const userDispatch = useContext(UserContext).UserDispatch

    const backButtonOnClickHandler = () => {
        userDispatch({ type: 'update_room_status', payload: 'waiting' })
    }

    return (
        <div className={styles.container}>
            <div className={styles.finalScoresTitle}>
                <h4>{t('Titles.scores')}</h4>
            </div>
            <div className={styles.finalScoresView}>
                <FinalScoresView usersRecord={props.usersRecord} />
            </div>
            <div className={styles.buttons}>
                <TextButton text={t('Buttons.back')} onClick={backButtonOnClickHandler} />
            </div>
        </div>
    )
}

export default EndScreen