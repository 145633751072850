import React from 'react'
import styles from './MainFooter.module.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const MainFooter: React.FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <Link to={'/privacy'} >{t('Texts.privacy_policy')}</Link>
                <span className={styles.divider}>|</span>
                <Link to={'/terms-of-service'} >{t('Texts.terms_of_service')}</Link>
            </div>
        </div>
    )
}

export default MainFooter