import React from 'react'
import AdminForm from '../components/Forms/AdminForm'
import styles from './AdminPage.module.css'
import PageTitle from '../components/Widgets/Title/PageTitle'
import { useTranslation } from 'react-i18next'
import Header from '../components/Widgets/Header/Header'

const AdminPage: React.FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <>
            <Header size='medium' />
            <div className={styles.container}>
                <PageTitle title={t('Titles.admin_settings')} backPath='/' />
                <AdminForm />
            </div>
        </>
    )
}

export default AdminPage