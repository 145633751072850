import React from 'react'
import styles from './SelectField.module.css'
import { useTranslation } from 'react-i18next'

export interface ISelectField {
    label?: string
    value?: string
    id?: string
    onChange?: React.ChangeEventHandler<HTMLSelectElement>
    options: { label: string, value: string }[]
    inline?: boolean
    inline28?: boolean
    classes?: string
    translate?: boolean
    tips?: string
}

const SelectField: React.FunctionComponent<ISelectField> = (props) => {
    const { t } = useTranslation()

    return (
        <div className={`${styles.field} ${props.inline === true ? styles.inline : ''} ${props.inline28 === true ? styles.inline28 : ''} ${props.classes !== undefined ? props.classes : ''}`}>
            {
                props.label !== undefined &&
                <label className={styles.label} htmlFor={props.id}>{props.label}</label>
            }
            <div className={styles.container}>
                <div className={`select ${styles.select}`}>
                    <select id={props.id} className={styles.selected} value={props.value} onChange={props.onChange} >
                        {props.options.map((option) => {
                            return <option key={option.value} value={option.value}>{props.translate === true ? t(option.label) : option.label}</option>
                        })}
                    </select>
                </div>
                {
                    props.tips !== undefined &&
                    <p className={styles.tips}>{props.tips}</p>
                }
            </div>
        </div>
    )
}

export default SelectField