import React, { useContext, useEffect, useState } from 'react'
import styles from './GameScreen.module.css'
import UserContext from '../../contexts/User/UserContext'
import AdminWaitingScreen from './Waiting/AdminWaitingScreen'
import GuestWaitingScreen from './Waiting/GuestWaitingScreen'
import SocketContext from '../../contexts/Socket/SocketContext'
import Quiz from './Playing/Quiz/Quiz'
import EndScreen from './End/EndScreen'
import { TGameMode, TQues, TUserObject } from '../../utils/library'
import worker_script from '../../utils/webworker1000'
import LoadScreen from './Loading/LoadScreen'

const timerWorker = new Worker(worker_script)

export interface IGameScreen {
    roomAdminId: string
}

const LoadGameScreen = (gameMode: TGameMode | undefined, gameData: TQues[] | undefined, isPhaseEnd: boolean, setIsPhaseEnd: React.Dispatch<React.SetStateAction<boolean>>) => {
    switch (gameMode) {
        case 'quiz_race':
            return <Quiz quesList={gameData} isPhaseEnd={isPhaseEnd} setIsPhaseEnd={setIsPhaseEnd} />

        case 'quiz_normal':
            return <Quiz quesList={gameData} isPhaseEnd={isPhaseEnd} setIsPhaseEnd={setIsPhaseEnd} />

        case 'quiz_first':
            return <Quiz quesList={gameData} isPhaseEnd={isPhaseEnd} setIsPhaseEnd={setIsPhaseEnd} />

        default:
            return <></>
    }
}

const GameScreen: React.FunctionComponent<IGameScreen> = (props) => {
    const userContext = useContext(UserContext)
    const { user, room } = userContext.UserState
    const userDispatch = userContext.UserDispatch
    const { socket } = useContext(SocketContext).SocketState

    const [updatingRecord, setUpdatingRecord] = useState<boolean>(false)
    const [usersRecord, setUsersRecord] = useState<TUserObject[]>([])
    const [isPhaseEnd, setIsPhaseEnd] = useState<boolean>(false)

    const [gameData, setGameData] = useState<TQues[]>()

    useEffect(() => {
        socket?.on('load_game', (quesList: TQues[]) => {
            if (room.gameType === 'quiz') {
                setGameData(quesList)
            }

            userDispatch({ type: 'update_room_status', payload: 'loading' })
        })

        return () => {
            socket?.off('load_game')
        }
    }, [socket])

    useEffect(() => {
        socket?.on('start_game', () => {
            userDispatch({ type: 'update_room_status', payload: 'playing' })
        })

        return () => {
            socket?.off('start_game')
        }
    }, [socket])

    useEffect(() => {
        socket?.on('end_game', () => {
            setUpdatingRecord(true)
            setIsPhaseEnd(true)
            timerWorker.postMessage({ turn: 'on' })
        })

        return () => {
            socket?.off('end_game')
        }
    }, [socket])

    useEffect(() => {
        timerWorker.onmessage = ({ data: { time } }) => {
            if (time >= 4000) {
                setIsPhaseEnd(false)
                timerWorker.postMessage({ turn: 'off' })
                userDispatch({ type: 'update_room_status', payload: 'ended' })
            }
        }
    }, [])

    useEffect(() => {
        if (room.roomStatus === 'loading') {
            // console.log('state playing')
            resetUsersData()
        }
    }, [room.roomStatus])

    useEffect(() => {
        if (updatingRecord) {
            confirmUsersRecord()
            setUpdatingRecord(false)
        }
    }, [updatingRecord])

    const resetUsersData = () => {
        if (room.users !== undefined) {
            // console.log('reset users data')
            userDispatch({ type: 'reset_users_score', payload: '' })
            userDispatch({ type: 'update_ready', payload: [] })
        }
    }

    const confirmUsersRecord = () => {
        if (room.users !== undefined) {
            const usersList: TUserObject[] = []
            const usersCount = Object.keys(room.users).length

            if (usersCount > 0) {
                Object.entries(room.users).map((entry) => {
                    const [uid, data] = entry
                    usersList.push({ uid, ...data })
                })
            }

            usersList.sort((a, b) => (a.score > b.score) ? -1 : ((a.score < b.score) ? 1 : 0))

            setUsersRecord(usersList)
        }
    }

    switch (room.roomStatus) {
        case 'loading':
            return (
                <div className={styles.screen}>
                    <LoadScreen gameData={gameData} />
                </div>
            )

        case 'playing':
            return (
                <div className={styles.screen}>
                    {LoadGameScreen(room.gameMode, gameData, isPhaseEnd, setIsPhaseEnd)}
                </div>
            )

        case 'ended':
            return (
                <div className={styles.screen}>
                    <EndScreen usersRecord={usersRecord} />
                </div>
            )

        default:
            if (user.uid === props.roomAdminId) {
                return (
                    <div className={styles.screen}>
                        <AdminWaitingScreen />
                    </div>
                )
            }

            return (
                <div className={styles.screen}>
                    <GuestWaitingScreen />
                </div>
            )
    }
}

export default GameScreen