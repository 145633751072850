import React from 'react'
import styles from './LoginPage.module.css'
import JoiningForm from '../components/Forms/JoiningForm'
import PageTitle from '../components/Widgets/Title/PageTitle'
import { useTranslation } from 'react-i18next'
import Header from '../components/Widgets/Header/Header'
import Footer from '../components/Widgets/Footer/Footer'

const LoginPage: React.FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <>
            <Header />
            <div className={styles.container}>
                <PageTitle title={t('Titles.play')} />
                <JoiningForm />
            </div>
            <Footer />
        </>
    )
}

export default LoginPage