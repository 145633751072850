import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import styles from './FileUploadField.module.css'
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone'
import IconButton from '../Buttons/IconButton'
import { useTranslation } from 'react-i18next'
import UserContext from '../../../contexts/User/UserContext'
// import sharp from 'sharp'

export interface IFileUploadField {
    id: string
    options?: DropzoneOptions
    file: string | undefined
    setFile: React.Dispatch<React.SetStateAction<string | undefined>>
    fileType: 'image' | 'audio'
    label?: string
    inline?: boolean
    inline28?: boolean
    tips?: string
}

const FileUploadField: React.FunctionComponent<IFileUploadField> = (props) => {
    const { t } = useTranslation()

    const userContext = useContext(UserContext)
    const { volume } = userContext.UserState

    const [previewing, setPreviewing] = useState<boolean>(false)

    const audioRef = useRef<HTMLAudioElement>(null)
    const [playing, setPlaying] = useState<boolean>(false)

    useEffect(() => {
        if (props.file) {
            setPreviewing(true)
        } else {
            setPreviewing(false)
        }
    }, [props.file])

    useEffect(() => {
        if (audioRef.current) {
            if (playing) {
                audioRef.current.volume = volume
                audioRef.current.play()
            } else {
                audioRef.current.pause()
                audioRef.current.currentTime = 0
            }
        }
    }, [playing, audioRef, volume])

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const fr = new FileReader()
            fr.readAsDataURL(acceptedFiles[0])
            fr.onload = () => {
                props.setFile(fr.result as string)
            }
        }
    }, [])

    const onDropAccepted = useCallback((files: File[]) => {
        // console.log(files)
    }, [])

    const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
        // console.log(fileRejections)
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        ...props.options,
        multiple: false,
        onDrop,
        onDropAccepted,
        onDropRejected
    })

    const closeButtonOnClickHandler = () => {
        props.setFile(undefined)
    }

    const audioButtonOnClickHandler = () => {
        setPlaying(() => !playing)
    }

    const audioOnEndedHandler = () => {
        setPlaying(false)
    }

    return (
        <div className={`${styles.field} ${props.inline ? styles.inline : ''} ${props.inline28 ? styles.inline28 : ''}`}>
            {
                props.label !== undefined &&
                <label className={styles.label} htmlFor={props.id}>{props.label}</label>
            }
            <div className={styles.container}>
                <div {...getRootProps({ className: `${styles.dropField} ${previewing ? styles.hide : ''}` })}>
                    <input {...getInputProps()} id={props.id} />
                    {
                        isDragActive ?
                            <p>{t('Messages.drop_file')}</p> :
                            <p>{t('Messages.drag_file')}</p>
                    }
                </div>
                {
                    previewing && props.file &&
                    <div className={styles.preview}>
                        <div className={styles.file}>
                            {
                                props.fileType === 'image' &&
                                <img className={styles.image} src={props.file as string} alt='image' />
                            }
                            {
                                props.fileType === 'audio' &&
                                <div className={styles.audio}>
                                    <audio src={props.file} ref={audioRef} onEnded={audioOnEndedHandler} />
                                    <i className={`${styles.audioButton} ${playing ? styles.stop : styles.play}`} onClick={audioButtonOnClickHandler}></i>
                                </div>
                            }
                            <IconButton classes={styles.closeButton} type='close' onClick={closeButtonOnClickHandler} />
                        </div>
                    </div>
                }
                {
                    props.tips !== undefined &&
                    <p className={styles.tips}>{props.tips}</p>
                }
            </div>
        </div>
    )
}

export default FileUploadField