import React from 'react'
import styles from './LandingPage.module.css'
import HowToPlaySection from '../components/Widgets/Sections/HowToPlaySection'
import LatestArticlesSliderSection from '../components/Widgets/Sections/LatestArticlesSliderSection'
import MessageSection from '../components/Widgets/Sections/MessageSection'
import ThemesSliderSection from '../components/Widgets/Sections/ThemesSliderSection'
import { useTranslation } from 'react-i18next'

export interface ILandingPage { }

const LandingPage: React.FunctionComponent<ILandingPage> = (props) => {
    const { t } = useTranslation()

    return (
        <div className={styles.wrap}>
            <div className={styles.container}>
                <HowToPlaySection />
                <LatestArticlesSliderSection />
                <ThemesSliderSection title={t('Titles.latest_quiz')} viewmoreLink='/create' type='quiz' who='official' />
                <MessageSection />
            </div>
        </div>
    )
}

export default LandingPage