import React from 'react'
import styles from './RoomVisibilitySelectField.module.css'
import SelectField from '../../../UI/Fields/SelectField'
import { useTranslation } from 'react-i18next'
import { TRoomVisibility, roomVisibilitiesOptions } from '../../../../utils/library'

export interface IRoomVisibilitySelectField {
    roomVisibility: TRoomVisibility,
    setRoomVisibility: React.Dispatch<React.SetStateAction<TRoomVisibility>>
    inline?: boolean
    inFilter?: boolean
    showLabel: boolean
}

const RoomVisibilitySelectField: React.FunctionComponent<IRoomVisibilitySelectField> = (props) => {
    const { t } = useTranslation()

    const options = roomVisibilitiesOptions

    const roomVisibilityOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        props.setRoomVisibility(() => e.target.value as TRoomVisibility)
    }

    return (
        <SelectField inline={props.inline} id='room-vis-select' options={options} onChange={roomVisibilityOnChangeHandler} value={props.roomVisibility} label={props.showLabel ? t('Labels.room_visibility') : undefined} classes={props.inFilter ? styles.filter : ''} translate={true} />
    )
}

export default RoomVisibilitySelectField