import React from 'react'
import styles from './IconButton.module.css'

export interface IIconButtonProps {
    id?: string
    type?: 'back' | 'forward' | 'close' | 'share' | 'search' | 'volume-high' | 'volume-low' | 'volume-mute' | 'menu-dots-vertical'
    classes?: string
    onClick?: (e: React.MouseEvent<HTMLElement>) => any
    disabled?: boolean
}

const IconButton: React.FunctionComponent<IIconButtonProps> = (props) => {
    let styleClass = props.classes !== undefined ? props.classes : ''
    let styleType = ''

    switch (props.type) {
        case 'back':
            styleType = styles.back
            break

        case 'forward':
            styleType = styles.forward
            break

        case 'close':
            styleType = styles.close
            break

        case 'share':
            styleType = styles.share
            break

        case 'search':
            styleType = styles.search
            break

        case 'volume-high':
            styleType = styles.volumeHigh
            break

        case 'volume-low':
            styleType = styles.volumeLow
            break

        case 'volume-mute':
            styleType = styles.volumeMute
            break

        case 'menu-dots-vertical':
            styleType = styles.menuDotsVertical
            break

        default:
            break
    }

    return (
        <button id={props.id} className={`${styles.button} ${styleType} ${styleClass}`} onClick={props.onClick} disabled={props.disabled}></button>
    )
}

export default IconButton