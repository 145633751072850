import React, { useContext, useEffect, useState } from 'react'
import styles from './Timer.module.css'
import SocketContext from '../../../../contexts/Socket/SocketContext'
import UserContext from '../../../../contexts/User/UserContext'
import { TAnswerRes, TAnswerResData, TQuizTimeLimit, defaultQuizTimeLimit, timeLimitValues } from '../../../../utils/library'
import worker_script from '../../../../utils/webworker50'

const timerWorker = new Worker(worker_script)

export interface ITimer {
    timeLeave: number
    resetTimer: boolean
    setTimeLeave: React.Dispatch<React.SetStateAction<number>>
    canAnswer: boolean
    setCanAnswer: React.Dispatch<React.SetStateAction<boolean>>
    setCanAnswerCallback: React.Dispatch<React.SetStateAction<boolean>>
    setAnswerResData: React.Dispatch<React.SetStateAction<TAnswerResData | undefined>>
    answeredUsers: { roomUId: string | undefined, correct: boolean }[]
    setAnsweredUsers: React.Dispatch<React.SetStateAction<{ roomUId: string | undefined, correct: boolean }[]>>
    scoreChange: { old: number, new: number }
    setScoreChange: React.Dispatch<React.SetStateAction<{ old: number, new: number }>>
}

const Timer: React.FunctionComponent<ITimer> = (props) => {
    const { socket } = useContext(SocketContext).SocketState
    const userContext = useContext(UserContext)
    const { user, room } = userContext.UserState
    const userDispatch = userContext.UserDispatch

    const timeInterval = 50

    const [quizOneTurnTimeLimit, setQuizOneTurnTimeLimit] = useState<number>(timeLimitValues[defaultQuizTimeLimit])
    const [barSections, setBarSections] = useState<number>(1000 / timeInterval * quizOneTurnTimeLimit)
    const [barEachSection, setBarEachSection] = useState<number>(Math.round(100 / barSections * 10000) / 10000)
    const [barLength, setBarLength] = useState<number>(100)
    const [barColor, setBarColor] = useState<string>('#2ed159')

    useEffect(() => {
        if (room.themeSettings !== undefined) {
            // console.log(room.themeSettings)
            const timeLimit = room.themeSettings?.quizTimeLimit
            if (timeLimit) {
                setQuizOneTurnTimeLimit(() => timeLimitValues[timeLimit])
            }
        }
    }, [room.themeSettings])

    useEffect(() => {
        const newSects = 1000 / timeInterval * quizOneTurnTimeLimit
        setBarSections(() => newSects)
        setBarEachSection(() => Math.round(100 / newSects * 10000) / 10000)
    }, [quizOneTurnTimeLimit])

    useEffect(() => {
        // console.log('reset timer')
        timerWorker.postMessage({ turn: 'on' })
        setBarLength(() => 100)
        setBarColor('#2ed159')
        props.setTimeLeave(() => quizOneTurnTimeLimit)
    }, [props.resetTimer, quizOneTurnTimeLimit])

    useEffect(() => {
        timerWorker.onmessage = ({ data: { time } }) => {
            // console.log(time)
            if (props.canAnswer) {
                const newBarLength = barLength - barEachSection < 0 ? 0 : barLength - barEachSection
                const newTimeLeave = props.timeLeave * 1000 - timeInterval < 0 ? 0 : Math.round(props.timeLeave * 1000 - timeInterval) / 1000

                if (newBarLength <= 25) {
                    setBarColor('#f52c2c')
                } else if (newBarLength <= 50 && newBarLength > 25) {
                    setBarColor('#f79123')
                } else {
                    setBarColor('#2ed159')
                }

                setBarLength(() => newBarLength)
                props.setTimeLeave(() => newTimeLeave)

                if (newBarLength <= 0) {
                    props.setCanAnswer(false)

                    socket?.emit('submit_answer', { answer: '', timeSpent: -1 }, (res: TAnswerRes) => {
                        // console.log('timeout, res:')
                        // console.log(res)

                        props.setAnswerResData({ res, timeout: true })
                        props.setAnsweredUsers((answeredUsers) => [...answeredUsers, { roomUId: room.roomUId, correct: res.correct }])
                        props.setScoreChange(() => {
                            return { old: props.scoreChange.new, new: res.newScore }
                        })

                        if (room.users !== undefined && user.uid !== undefined && room.users[user.uid] !== undefined) {
                            const records = room.users[user.uid].records
                            const questionCount = records.questionCount !== undefined ? records.questionCount + 1 : 1
                            const correctCount = records.correctCount !== undefined ? (res.correct ? records.correctCount + 1 : records.correctCount) : (res.correct ? 1 : 0)
                            // console.log(room.roomUId + ' : ' + res.timeSpent)
                            const timeLimit = room.themeSettings?.quizTimeLimit as TQuizTimeLimit | undefined
                            const newTimeSpent = timeLimit !== undefined ? timeLimitValues[timeLimit] * 1000 : timeLimitValues[defaultQuizTimeLimit] * 1000
                            const totalTimeSpent = records.totalTimeSpent !== undefined ? records.totalTimeSpent + newTimeSpent : newTimeSpent
                            userDispatch({ type: 'update_users_score', payload: { roomUId: room.roomUId, score: res.newScore, records: { questionCount, correctCount, totalTimeSpent } } })
                        }
                        // userDispatch({ type: 'update_users_score', payload: { roomUId: room.roomUId, score: res.newScore } })
                    })
                }

                props.setCanAnswerCallback(true)
            } else {
                props.setCanAnswerCallback(false)
            }
        }
    }, [barLength, props.canAnswer, room.users, user.uid, room.themeSettings])

    useEffect(() => {
        if (room.roomUId !== undefined) {
            const list = props.answeredUsers.map((u) => u.roomUId)
            if (list.includes(room.roomUId)) {
                timerWorker.postMessage({ turn: 'off' })
            }
        }
    }, [room.roomUId, props.answeredUsers])


    const timeLeaveToText = (timeLeave: number) => {
        let text = (Math.ceil(timeLeave * 100) / 100).toFixed(2)
        if (timeLeave < 10) {
            text = '0' + text
        }
        return text
    }

    return (
        <div className={styles.container}>
            <div className={styles.timeLeave}>{timeLeaveToText(props.timeLeave)}</div>
            <div className={styles.barContainer}>
                <div className={styles.bar} style={{ width: `${barLength}%`, background: `${barColor}` }}></div>
            </div>
        </div>
    )
}

export default Timer