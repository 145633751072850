import React, { useState } from 'react'
import styles from './ThemesSliderSection.module.css'
import Section from './Section'
import ThemeSlider from '../../UI/Sliders/ThemeSlider'
import { TGameType, TThemeWho } from '../../../utils/library'

export interface IThemesSliderSection {
    title: string
    viewmoreLink: string
    type: TGameType
    who: TThemeWho
}

const ThemesSliderSection: React.FunctionComponent<IThemesSliderSection> = (props) => {
    const [isEmpty, setIsEmpty] = useState<boolean>(false)

    return (
        <Section hide={isEmpty} title={props.title} viewmoreLink={props.viewmoreLink}>
            <ThemeSlider type={props.type} who={props.who} setIsEmpty={setIsEmpty} />
        </Section>
    )
}

export default ThemesSliderSection