import React, { PropsWithChildren, useEffect, useReducer } from 'react'
import UserContext, { UserReducer, defaultUserContextState } from './UserContext'
import { v4 } from 'uuid'
import Avatar from '../../utils/avatar'
import axios from 'axios'
import Cookies from 'js-cookie'

export interface IUserContextComponentProps { }

const UserContextComponent: React.FunctionComponent<IUserContextComponentProps> = (props: PropsWithChildren) => {
    const [UserState, UserDispatch] = useReducer(UserReducer, defaultUserContextState)

    const avatarHandler = new Avatar()

    useEffect(() => {
        /** Get data from local storage */
        let user = {}
        let userStr = localStorage.getItem('user')

        if (userStr !== null) {
            user = JSON.parse(userStr)
        } else {
            const newUsername = 'User' + Math.floor(Math.random() * 9000 + 1000).toString()
            user = {
                avatar: avatarHandler.GetAvatarPath('avatar1'),
                username: newUsername,
                uid: v4()
            }
        }

        /** Update UserContext */
        UserDispatch({ type: 'init_user', payload: user })

        /** Test login token, remove if not authorized */
        const token = Cookies.get('login_token')
        if (token) {
            axios({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                url: `${process.env.REACT_APP_SERVER}/api/users/auth`
            }).then((res) => {
                if (!res.data || !res.data.result) Cookies.remove('login_token')
            }).catch((e) => {
                // console.log(e)
                if (e.code !== "ERR_NETWORK") Cookies.remove('login_token')
            })
        }

    }, [])

    return (
        <UserContext.Provider value={{ UserState, UserDispatch }}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContextComponent