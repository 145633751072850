import React, { useContext, useEffect, useState } from 'react'
import UserCard from './UserCard'
import styles from './UserSidebar.module.css'
import Scrollbars from 'react-custom-scrollbars-2'
import Avatar from '../../../utils/avatar'
import UserContext from '../../../contexts/User/UserContext'
import { TUserObject, TUsersInRoom } from '../../../utils/library'

export interface IUserSidebar { }

const UsersSidebar: React.FunctionComponent<IUserSidebar> = () => {
    const { room } = useContext(UserContext).UserState

    const maxUsers: number = 8
    const avatarHandler = new Avatar()
    const defaultUserInRoom = {
        uid: undefined,
        socketId: undefined,
        username: '----',
        avatar: avatarHandler.GetDefaultAvatarPath(),
        roomId: undefined,
        roomUId: undefined,
        score: 0
    }

    const getUsersList = (usersInRoom: TUsersInRoom = {}) => {
        const usersList: any = []
        const usersCount = Object.keys(usersInRoom).length

        if (usersCount > 0) {
            Object.entries(usersInRoom).forEach((entry) => {
                const [uid, data] = entry
                usersList.push({ uid, ...data })
            })
        }

        for (let i = 0; i < maxUsers - usersCount; i++) {
            usersList.push({ ...defaultUserInRoom, uid: `default_user_${i}`, score: undefined })
        }

        return usersList
    }

    const [users, setUsers] = useState<TUserObject[]>(getUsersList())
    const [roomAdminId, setRoomAdminId] = useState<string>('')

    useEffect(() => {
        if (room.users !== undefined) {
            const usersList = getUsersList(room.users)
            setUsers(usersList)

            const adminId = Object.keys(room.users)[0]
            setRoomAdminId(adminId)
        }
    }, [room.users])

    return (

        <div className={styles.container}>
            <Scrollbars
                style={{ height: '100%' }}
            >
                {users.map((data) => {
                    return <UserCard key={data.uid} uid={data.uid} username={data.username} avatar={data.avatar} roomAdminId={roomAdminId} score={data.score} />
                })}
            </Scrollbars>
        </div>
    )
}

export default UsersSidebar