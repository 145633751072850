import React from 'react'
import { useTranslation } from 'react-i18next'
import InputField from '../../../UI/Fields/InputField'

export interface IArticleSlugInputField {
    articleSlug: string
    setArticleSlug: React.Dispatch<React.SetStateAction<string>>
    inline?: boolean
}

const ArticleSlugInputField: React.FunctionComponent<IArticleSlugInputField> = (props) => {
    const { t } = useTranslation()

    const articleSlugInputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setArticleSlug(e.target.value)
    }

    return (
        <InputField id='article-slug' label={t('Labels.slug')} inline28={props.inline} value={props.articleSlug} onChange={articleSlugInputOnChangeHandler} />
    )
}

export default ArticleSlugInputField