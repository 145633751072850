import React from 'react'
import Popup from './Popup'
import styles from './ErrorPopup.module.css'
import TextButton from '../Buttons/TextButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export interface IErrorPopupProps {
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    message: string
    redirect?: string
    redirectButtonText?: string
    isNotice?: boolean
}

const ErrorPopup: React.FunctionComponent<IErrorPopupProps> = (props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [active, setActive] = [props.active, props.setActive]

    const confirmButtonOnClickHandler = () => {
        setActive(false)
    }

    const redirectButtonOnClickHandler = () => {
        if (props.redirect !== undefined) {
            navigate(props.redirect)
        }
        setActive(false)
    }

    return (
        <Popup title={props.isNotice !== true ? t('Titles.warning') : t('Titles.notice')} active={active} setActive={setActive}>
            <div className={styles.container}>
                <div className={styles.messageContainer}>
                    <p className={styles.message}>{props.message}</p>
                </div>
                <div className={styles.buttons}>
                    <TextButton text={t('Buttons.ok')} onClick={confirmButtonOnClickHandler} />
                    {
                        props.redirect !== undefined && props.redirectButtonText !== undefined &&
                        <TextButton text={props.redirectButtonText} onClick={redirectButtonOnClickHandler} />
                    }
                </div>
            </div>
        </Popup>
    )
}

export default ErrorPopup