import React from 'react'
import styles from './HeaderMenu.module.css'
import { Link } from 'react-router-dom'

export interface IHeaderMenu {
    menu: { text: string, link: string }[]
}

const HeaderMenu: React.FunctionComponent<IHeaderMenu> = (props) => {
    return (
        <div className={styles.container}>
            {
                props.menu.map((m, i) => {
                    return (
                        <Link key={'menu-item-' + i} className={styles.menuItem} to={m.link} >{m.text}</Link>
                    )
                })
            }
        </div>
    )
}

export default HeaderMenu