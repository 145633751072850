import React from 'react'
import styles from './Footer.module.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer: React.FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <Link to={'/privacy'} >{t('Texts.privacy_policy')}</Link>
            <span className={styles.divider}>|</span>
            <Link to={'/terms-of-service'} >{t('Texts.terms_of_service')}</Link>
        </div>
    )
}

export default Footer