import React, { useContext } from 'react'
import styles from './MessageCard.module.css'
import moment from 'moment'
import UserContext from '../../../contexts/User/UserContext'

export interface IMessageCard {
    roomUId: string
    username: string
    message: string
    createdAt: number
}

const MessageCard: React.FunctionComponent<IMessageCard> = (props) => {
    const { user, room } = useContext(UserContext).UserState
    const displayTime = moment(props.createdAt).format('h:mm a')

    /** My message */
    if (room.roomUId === props.roomUId) {
        return (
            <div className={`${styles.card} ${styles.myCard}`}>
                <div className={styles.messageWrapper}>
                    <span className={styles.message}>{props.message}</span>
                    <span className={styles.time}>{displayTime}</span>
                </div>
            </div>
        )
    }

    /** Other message */
    return (
        <div className={`${styles.card}`}>
            <span className={styles.username}>{props.username}</span>
            <div className={styles.messageWrapper}>
                <span className={styles.message}>{props.message}</span>
                <span className={styles.time}>{displayTime}</span>
            </div>
        </div>
    )
}

export default MessageCard