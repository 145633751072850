import React from 'react'
import styles from './RoomsPage.module.css'
import PageTitle from '../components/Widgets/Title/PageTitle'
import RoomsPageJoinForm from '../components/Forms/RoomsPageJoinForm'
import { useTranslation } from 'react-i18next'
import Header from '../components/Widgets/Header/Header'

const RoomsPage: React.FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <>
            <Header size='medium' />
            <div className={styles.container}>
                <PageTitle title={t('Titles.rooms')} backPath='/login' />
                <div className={styles.content}>
                    <RoomsPageJoinForm />
                </div>
            </div>
        </>
    )
}

export default RoomsPage