import React, { useState } from 'react'
import styles from './LatestArticlesSliderSection.module.css'
import { useTranslation } from 'react-i18next'
import Section from './Section'
import ArticleSlider from '../../UI/Sliders/ArticleSlider'

export interface ILatestArticlesSliderSection { }

const LatestArticlesSliderSection: React.FunctionComponent<ILatestArticlesSliderSection> = (props) => {
    const { t } = useTranslation()

    const [isEmpty, setIsEmpty] = useState<boolean>(false)

    return (
        <Section hide={isEmpty} title={t('Titles.latest_articles')} viewmoreLink='/articles'>
            <ArticleSlider setIsEmpty={setIsEmpty} />
        </Section>
    )

}

export default LatestArticlesSliderSection